import { Component, Input } from '@angular/core';

@Component({
  selector: 'wdm-checklist-report',
  templateUrl: 'checklist-report.component.html',
  styleUrls: ['checklist-report.component.scss']
})

export class ChecklistReportComponent {

  @Input() stepinfo: any;

  constructor() { }

  info: any;
  mandatory: boolean;

  ngOnInit() {
    this.info = JSON.parse(this.stepinfo.result);
    console.log('info checklist', this.info);
    this.mandatory = this.checkMandatory();
  }

  checkMandatory(): boolean {
    let response = false;

    this.info.forEach(function (check) {
      if (check.IsMandatory) {response = true; }
    });
    
    return response;
  }
}
