import { FileDto } from "./FileDto";
import { StepType } from "./StepTypes";

export class StepDto {
  id: number;
  stepTypeId: number;
  state: string;
  title: string;
  description: string;
  content: any;
  fileList: FileDto[];
  parents: Array<number>;
  links: Array<Link>;
  stepId: number;
  skippable: boolean;
  isNew: boolean;

  constructor() {
    this.id = 0;
    this.stepTypeId = StepType.New;
    this.description = '';
    this.title = '';
    this.content = '';
    this.fileList = [];
    this.state = 'NEW';
    this.links = [];
    this.parents = [];
    this.stepId = 0;
    this.isNew = true;
    this.skippable = true;
  }
}

export class Link {
  id: number;
  text: any;
  stepTitle: string;
  order: number;
  isFinal: boolean;

  constructor(linkId: number, linkText: string = null) {
    this.id = linkId;
    this.text = linkText;
    this.isFinal = false;
  }
}


export class NumericContent {
  accuracy: number;
  unitOfMeasure: string;
}

export class ValidationContent {
  operation: string;
  validationValue: string;
}
