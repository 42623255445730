<wdm-header-root></wdm-header-root>

<div class="form-workflow-area" *ngIf="showHeader">

  <div class="container-wd" id="form_workflow">

    <form class="workflow-form" #workflowForm="ngForm">

      <mat-form-field appearance="outline">
        <mat-label>{{ 'newworkflow_workflow_name' | translate }}</mat-label>
        <input matInput #name="ngModel" name="name" [(ngModel)]="workflowDTO.name" placeholder="{{ 'newworkflow_workflow_name_placeholder' | translate }}"
               maxlength="50" minlength="5" required whitespace (blur)="saveWorkflowForm(workflowForm)">
        <mat-error *ngIf="name.invalid && name.errors.required">{{ 'error_field_required' | translate }}</mat-error>
        <mat-error *ngIf="name.invalid && name.errors.minlength">{{ 'error_min_5_chars' | translate }}</mat-error>
        <mat-error *ngIf="name.invalid && name.errors.maxlength">{{ 'error_max_50_chars' | translate }}</mat-error>
        <mat-error *ngIf="name.invalid && name.errors.whitespace">{{ 'not_only_whitespaces' | translate }}</mat-error>
      </mat-form-field>
     

      <mat-form-field appearance="outline">
        <mat-label>{{ 'newworkflow_description' | translate }}</mat-label>
        <input matInput #desciption="ngModel" name="description" [(ngModel)]="workflowDTO.description" placeholder="{{ 'newworkflow_description_placeholder' | translate }}"
               maxlength="250" (blur)="saveWorkflowForm(workflowForm)">
        <!--<mat-error *ngIf="description.errors.maxlength">{{ 'error_max_250_chars' | translate }}</mat-error> -->
      </mat-form-field>

      <div class="form-workflow-buttons">
        <button class="icon-btn inline hvr-grow" (click)="openMetadata(workflowDTO, $event)" mat-button>
          <i class="fa fa-tag" aria-hidden="true"></i>
        </button>
        <button *ngIf="workflowId > 0" (click)="workflowService.openBottomSheet(workflowId, $event)" type="button" class="icon-btn inline hvr-grow" mat-button>
          <i class="fa fa-qrcode" aria-hidden="true"></i>
        </button>
        <button *ngIf="isManager && workflowId > 0" (click)="workflowService.openAllowedUsers(workflowId, $event)" type="button" class="icon-btn inline hvr-grow" mat-button>
          <i class="fa fa-users" aria-hidden="true"></i>
        </button>
        <button *ngIf='!name.invalid && allowSaveWf' class="icon-btn inline save-ok" mat-button id="btnSaveWorkflow"><!-- (click)="saveWorkflow()" -->
          <i class="fa fa-save" aria-hidden="true"></i>
        </button>
        <button *ngIf='name.invalid || !allowSaveWf' class="icon-btn inline disabled" (click)="checkLastError(workflowForm)" mat-button>
          <i class="fa fa-save" aria-hidden="true"></i>
        </button>
      </div>

    </form>
  </div>




  <div *ngIf="showStepList" class="container-wd">
    <wdm-list-step-table [(workflowDTO)]="workflowDTO" [wFlowId]="workflowId" (okWF)="wfReadyToSave($event)" (unfinishWF)="wfUnfinish($event)"></wdm-list-step-table>
  </div>

</div>

<loading-component [loading]="loading"></loading-component>
<wdm-alert #alertComponent></wdm-alert>
