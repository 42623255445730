import { Component, Inject, ViewChildren, QueryList } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupTypeEnum } from '../../../models/Group';
import { UsersService } from '../../../services/users.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { SentenceGroup, SentenceRequest } from '../../../models/Requests/SentenceRequest';
import { AllowedUsersComponent } from '../allowed-users/allowed-users.component';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.scss'],
  providers: [TranslatePipe]
})

export class AssignmentsComponent {

  @ViewChildren(AllowedUsersComponent) allowedUsersComponent: QueryList<AllowedUsersComponent>;
  workflowId: number;
  Error: string = "";
  results: string[] = [];
  groupTypeEnum: typeof GroupTypeEnum = GroupTypeEnum;
  selectedTab: number;
  isTechManager: boolean;
  isDesignerManager: boolean;

  constructor(public dialogRef: MatDialogRef<AssignmentsComponent>, @Inject(MAT_DIALOG_DATA) public data: { baseUrl: string, workflowId: number },
    private usersService: UsersService, private translateService: TranslatePipe, private auth: AuthService, private router: Router) {
    this.isTechManager = this.auth.IsTechManager();
    this.isDesignerManager = this.auth.IsDesignerManager();
  }

  ngOnInit() {
    this.workflowId = this.data.workflowId;
    this.selectedTab = 0;
  }

  saveSentence(save: boolean) {
    this.results = [];
    this.Error = "";

    var sentence: SentenceRequest = new SentenceRequest();
    sentence.workflowId = this.workflowId;
    sentence.save = save;

    this.allowedUsersComponent.forEach(component => {
      sentence.sentenceGroup.push(new SentenceGroup(component.getSentenceList(), component.getGroupType()));
    });

    this.usersService.saveSentence(sentence).subscribe({
      next: (results) => {

        var errors = [];
        results.forEach(result => {
          if (result.error != null) {
            var title = "";
            if (result.groupTypeId == this.groupTypeEnum.Technician) title = this.translateService.transform("tech_groups_title");
            else title = this.translateService.transform("designer_groups_title");
            errors.push(title + " " + this.translateService.transform(result.error));
          }
        });

        if (errors.length > 0) this.Error = errors.join(", ");
        else {
          this.dialogRef.close();
          if (this.isDesignerManager && this.auth.IsCreator() && this.router.url == '/dashboard') location.reload(); 
        }
      },
      error: (error) => {
        this.Error = this.translateService.transform(error.error);
      }
    });

  }

  onTabChanged($event) {
    this.selectedTab = $event.index;
  }
}
