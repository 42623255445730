import { Component, OnInit, DoCheck, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { StepDto } from '../../../../models/StepDTO';
import { NgForm } from '@angular/forms';
import { FileDto } from '../../../../models/FileDto';
import { FileInputComponent } from '../../../file-input/file-input.component';

@Component({
  selector: 'wdm-image-form',
  styleUrls: ['../../../../styles/components/steps.scss', 'image-form.component.scss'],
  templateUrl: 'image-form.component.html',
})

export class ImageFormComponent implements OnInit, DoCheck {

  @ViewChild('imageForm', { static: true }) private imageForm: NgForm;
  @Input() stepInfo: StepDto;
  @Output() errors: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  isRequired = false;
  isMaxSize = false;
  imageFiles: FileDto[] = [];
  fileInputErrors: boolean;
  @ViewChild(FileInputComponent) fileInputComponent: FileInputComponent;


  constructor() {
  }

  ngOnInit() {
    this.imageFiles = this.stepInfo.fileList;
  }

  ngDoCheck(): void {
    if (this.imageForm.status === 'INVALID' || this.fileInputErrors == true) {
      this.errors.emit(true);
    } else {
      this.stepInfo.state = "OK";
      this.errors.emit(false);
    }
  }

  forceValidation() {
    this.imageForm.controls.title.markAsTouched();
    this.imageForm.controls.description.markAsTouched();
    if (this.stepInfo.fileList.length == 0) {
      this.fileInputComponent.showError();
    }
  }

  onFileChanges(inputFiles) {
    this.stepInfo.fileList = inputFiles;
  }

  hasErrors(hasErrors: boolean): void {
    this.fileInputErrors = hasErrors;
  }

  
}
