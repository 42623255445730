export class Group {
  name: string;
  id: number;
  active: string;
  groupTypeId: GroupTypeEnum;
}

export enum GroupTypeEnum {
  Technician = 1,
  Designer = 2
}
