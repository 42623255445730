import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { Directive, forwardRef } from '@angular/core';


export function whitespace(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isWhitespace = (control.value || '').trim().length === 0 && (control.value != null && control.value.length > 0);
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}

@Directive({
  selector: '[whitespace]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => WhiteSpaceValidator),
    multi: true
  }]
})


export class WhiteSpaceValidator implements Validator {

  validate(control: AbstractControl): { [key: string]: any } | null {
    return whitespace()(control);
  }
}
