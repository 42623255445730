import { Component, OnInit, Injectable, AfterContentInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { WorkFlowService } from '../../../services/workflow.service';
import { WorkflowDTO } from '../../../models/WorkflowDTO';
import { StepDto } from '../../../models/StepDTO';
import { LoadingComponent } from '../../loader/loading.component';

import { AlertComponent } from '../../alert/alert.component';
import { ListStepComponent } from '../../step/list-step/list.step.component';
import { AlertDialogComponent } from '../../dialogs/alert/alert.component';
import { MatDialog } from '@angular/material/dialog';

import { TranslatePipe } from '../../../pipes/translate.pipe';
import { MetadataComponent } from '../metadata/metadata.component';
import { NgForm } from '@angular/forms';

import { AuthService } from '../../../services/auth.service';
import { cloneDeep } from 'lodash-es';


@Component({
  selector: 'wdm-form-workflow-component',
  styleUrls: ['../../../styles/components/steps.scss'],
  templateUrl: './form-workflow.component.html',
  providers: [TranslatePipe]
})

@Injectable({ providedIn: 'root' })
export class FormWorkFlowComponent implements OnInit, AfterContentInit {

  submitted = false;
  parameters: any;
  workflowId = 0;
  steps: StepDto[] = [];
  showStepList = true;
  loading = false;
  allowSaveWf = true;
  workflowDTO: WorkflowDTO = new WorkflowDTO();
  copyWorkflowDto: WorkflowDTO;
  lastError = "";
  isManager: Boolean;
  showHeader: Boolean = true;
  workflowName: string = '';
  defaultWorkflowName: string = 'unnamed_workflow';
  @ViewChild('alertComponent', { static: true }) private alertComponent: AlertComponent;
  @ViewChild('workflowForm', { static: true }) private workflowForm: NgForm;
  @ViewChild(ListStepComponent) private listStepTable: ListStepComponent;


  constructor(public dialog: MatDialog, public workflowService: WorkFlowService, private routeAct: ActivatedRoute, 
    public loader: LoadingComponent, private auth: AuthService, private translateService: TranslatePipe) {
    this.isManager = this.auth.IsTechManager() || this.auth.IsDesignerManager();
  }


  ngOnInit() {
    this.showHeader = true;
    this.parameters = this.routeAct.params.subscribe(params => {
      if (+params.id > 0) {
        this.workflowId = + params.id; // (+) converts string 'id' to a number
      }
    });
  }

  ngAfterContentInit() {
    if (this.workflowId > 0) {
      console.log("load workflow: " + this.workflowId);
      this.loadWorkflow();
      this.lastError = "";
    } else {
      this.allowSaveWf = false;
      this.lastError = "workflow_cant_save_undefined_steps";
      this.workflowDTO.name = this.translateService.transform(this.defaultWorkflowName);
    }
  }

  checkLastError(form: NgForm) {
    var message = "";

    if (form != undefined && form.controls.name.errors != null) message = "workflow_cant_save_no_valid_name";
    else if (this.lastError.length > 0) message = this.lastError;

    if (message != "") {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: 'auto',
        data: {
          message: this.translateService.transform(message),
          title: 'workflow_cant_save',
          type: 'ALERT',
          buttons: 'OK'
        }
      });
    }
  }

  openMetadata(workflowDto: WorkflowDTO, event: Event): void {
    event.stopPropagation();
    this.copyWorkflowDto = cloneDeep(workflowDto);

    const dialogRef = this.dialog.open(MetadataComponent, {
      data: {
        workflowDto: workflowDto
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result instanceof WorkflowDTO) {
        console.log("result metadata: " + result.name);
        this.dataToWorkflowDTO(result);
      } else {
        console.log("result metadata CANCEL");
        this.workflowDTO = this.copyWorkflowDto;
        return;
      }
    });
  }

  wfReadyToSave(e) {
    console.log("wfReadyToSave", e);
    if ((e as string).length == 0) {
      this.allowSaveWf = true;
      console.log("SAVE WORKWLOW");
      this.saveWorkflow();
      return;
    } else {
      this.lastError = e;
    }
  }

  wfUnfinish(e) {
    console.log("wfUnfinish", e);
    if ((e as string).length > 0) {
      this.allowSaveWf = false;
      this.lastError = e;
    }
  }

  loadWorkflow() {
    this.loading = true;
    this.workflowService.getWorkFlow(this.workflowId).subscribe({
      next: (data: any[]) => {
        if (data != null) {
          this.dataToWorkflowDTO(data);
          this.enableControls();
        }
        this.loading = false;
      },
      error: (err) => {
        console.log(err);
        this.loading = false;
        this.alertComponent.showMessage(err.error, "Error", 5000);
        this.disableControls();
      },
      complete: () => console.log('workflowId loaded ok')
    });
  }

  disableControls() {
    this.showHeader = false;
    this.setShowStepList(false);
  }

  enableControls() {
    this.showHeader = true;
    this.setShowStepList(true);
  }

  dataToWorkflowDTO(data: any) {
    this.workflowDTO.id = this.workflowId;
    this.workflowDTO.name = data.name;
    this.workflowDTO.description = data.description;
    this.workflowDTO.company = data.company;
    this.workflowDTO.location = data.location;
    this.workflowDTO.plant = data.plant;
    this.workflowDTO.team = data.team;
    this.workflowDTO.freeText = data.freeText;
    this.workflowDTO.logo = data.logo;
  }

  setShowStepList(option: boolean) {
    this.showStepList = option;
  }

  saveWorkflowForm(form: NgForm) {
    if (form != undefined && !form.valid) return;
    if (!this.allowSaveWf) return;
    this.saveWorkflow();
  }


  saveWorkflow() {
    this.loading = true; 
    this.saveWorkflowDTO();
  }

  saveWorkflowDTO() {
    //MGE:  No borrar, a estudiar la posibilidad de enviar el Json del diagrama completo.
    //var stringJson = this.listStepTable.diagram.saveDiagram()
    //let diagram: Diagram = JSON.parse(this.listStepTable.diagram.saveDiagram());

    this.workflowService.saveWorkflow(this.workflowDTO).subscribe({
      next: (res) => {
        if (res === -1) {
          this.alertComponent.showMessage('newworkflow_error', 'error', 5000);
          this.loading = false;
        } else if (res > 0) {
          //if (this.workflowId > 0) {
          //  this.alertComponent.showMessage('newworkflow_success_update', 'success', 5000);
          //} else {
          //  this.alertComponent.showMessage('newworkflow_success_add', 'success', 5000);
          //}
          this.workflowId = res;
          this.cleanWorkflowDto();
          this.loadWorkflow();
          this.loading = false;
          this.listStepTable.getStepDataList(this.workflowId);

        }
      },
      error: (err) => {
        console.log('Error', err);
        this.alertComponent.showMessage('newworkflow_error', 'error', 5000);
        this.loading = false;
      }
    });
  }

  cleanWorkflowDto() {
    this.workflowDTO.stepItemsToAdd = [];
    this.workflowDTO.stepItemsToDelete = [];
    this.workflowDTO.stepItemsToEdit = [];
  }
}
