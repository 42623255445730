import { Component, Injectable, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FileDto } from '../../models/FileDto';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { ReportIssueService } from '../../services/reportIssue.service';

@Injectable({ providedIn: 'root' })
@Component({
  selector: 'wdm-report-issue',
  templateUrl: 'report-issue.component.html',
  styleUrls: ['report-issue.component.scss', '../../styles/components/dialog.scss'],
  providers: [TranslatePipe]
})

export class ReportIssueComponent {
  @ViewChild('reportIssueForm', { static: true }) private reportIssueForm: NgForm;
  issue = new ReportIssue();
  isMaxSize = false;
  issuePreviewFiles: FileDto[] = [];
  baseUrl: string = '';
  sendError: string = '';

  constructor(public dialogRef: MatDialogRef<ReportIssueComponent>,  public reportIssueService: ReportIssueService,
    private translateService: TranslatePipe) {

  }

  onSubmit() {
    if (this.reportIssueForm.valid) {
      this.sendError = '';
      this.sendForm();
    } 
  }

  sendForm() {


    this.reportIssueService.sendForm(this.issue, this.issuePreviewFiles).subscribe({
      next: (res) => {
        if (res == null)
          this.dialogRef.close();
        else {
          this.sendError = this.translateService.transform("send_support_email_error");
        }
      },
      error: (err) => {
        console.log('Error', err);
        this.sendError = this.translateService.transform("send_support_email_error");
      }
    });
  }
}

export class ReportIssue {
  title: string;
  email: string;
  description: string;
  operatingSystem: string;
  browser: string;

  constructor() {
    this.title = '';
    this.email = '';
    this.description = '';
    this.operatingSystem = '';
    this.browser = '';
  }
  
}
