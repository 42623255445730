import { Component, OnDestroy, DoCheck, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { StepDto } from '../../../../models/StepDTO';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'wdm-text-form',
  styleUrls: ['../../../../styles/components/steps.scss', 'text-form.component.scss'],
  templateUrl: 'text-form.component.html',
})

export class TextFormComponent  implements DoCheck {

  @ViewChild('textForm', {static: true}) private textForm: NgForm;
  @Input() stepInfo: StepDto;
  @Output() errors: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  constructor() {
  }

  ngOnInit() {
    //this.stepInfo.state = "NEW";
  }

  ngDoCheck(): void {
    if (this.textForm.status === 'INVALID') {
      this.errors.emit(true);
    } else {
      this.stepInfo.state = "OK"
      this.errors.emit(false);
    }
  }

  forceValidation() {
    this.textForm.controls.title.markAsTouched();
    this.textForm.controls.description.markAsTouched();
  }

}


