import { Component, DoCheck, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { NgForm } from '@angular/forms';
import { StepDto } from '../../../../models/StepDTO';

@Component({
  selector: 'wdm-recordVideo-form',
  styleUrls: ['../../../../styles/components/steps.scss', 'recordVideo-form.component.scss'],
  templateUrl: 'recordVideo-form.component.html',
})

export class RecordVideoFormComponent implements DoCheck {

  @ViewChild('recordVideoForm', { static: true }) private recordVideoForm: NgForm;
  @Input() stepInfo: StepDto;
  @Output() errors: EventEmitter<boolean> = new EventEmitter<boolean>(true);


  constructor() {
  }

  ngOnInit() {
  }

  ngDoCheck(): void {
   
    if (this.recordVideoForm.status === 'INVALID') {
      this.errors.emit(true);
    } else {
      this.stepInfo.state = "OK";
      this.errors.emit(false);

    }
  }

  forceValidation() {
    this.recordVideoForm.controls.title.markAsTouched();
    this.recordVideoForm.controls.description.markAsTouched();
  }

}
