import { Component, DoCheck, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { NgForm } from '@angular/forms';
import { StepDto } from '../../../../models/StepDTO';

@Component({
  selector: 'wdm-capture-form',
  styleUrls: ['../../../../styles/components/steps.scss', 'capture-form.component.scss'],
  templateUrl: 'capture-form.component.html',
})

export class CaptureFormComponent implements DoCheck {

  @ViewChild('captureForm', { static: true }) private captureForm: NgForm;
  @Input() stepInfo: StepDto;
  @Output() errors: EventEmitter<boolean> = new EventEmitter<boolean>(true);


  constructor() {
  }

  ngOnInit() {
  }

  ngDoCheck(): void {
   
    if (this.captureForm.status === 'INVALID') {
      this.errors.emit(true);
    } else {
      this.errors.emit(false);
      this.stepInfo.state = "OK";
    }
  }

  forceValidation() {
    this.captureForm.controls.title.markAsTouched();
    this.captureForm.controls.description.markAsTouched();
  }

}
