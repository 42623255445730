import { Component, OnInit, Injectable, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
@Component({
  selector: 'wdm-image-report',
  templateUrl: 'dialog-image.component.html',
  styleUrls: ['dialog-image.component.scss', '../../../styles/components/dialog.scss']
})

export class DialogImageComponent implements OnInit {
  urlImage = '';
  zoomnumber = 100;
  imgWidth: string;
  imgHeight: string;
  horizontal: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ngOnInit() {
    console.log('image data', this.data);
    this.imgWidth = 'auto';
    this.imgHeight = '100%';
    this.urlImage = this.data.img;
    const img = new Image();

    fromEvent(window, 'mousewheel').pipe(debounceTime(0)).subscribe(($event: WheelEvent) => {
      if ($event.deltaY < 0) {
        if (this.zoomnumber <= 400) {
          this.zoomnumber += 5;
        }

      } else {
        if (this.zoomnumber > 100) {
          this.zoomnumber-=5;
        } 
      }
      this.imgHeight = this.zoomnumber + '%'; 
    });
     }

}



export interface DialogData {
  img: any;
  }
