import { Component, Input } from '@angular/core';

@Component({
  selector: 'wdm-question-yesno-report',
  templateUrl: 'question-yesno-report.component.html',
  styleUrls: ['question-yesno-report.component.scss']
})

export class QuestionYesNoReportComponent {

  @Input() stepinfo: any;

  constructor() { }

  info: any;

  ngOnInit() {
    this.info = JSON.parse(this.stepinfo.result);
    console.log('info yes no', this.info);
  }

}
