import { Component, DoCheck, Input, Output, EventEmitter } from '@angular/core'
import { StepDto } from '../../../../models/StepDTO';
import { StepService } from '../../../../services/step.service';


@Component({
  selector: 'wdm-changeStepType-form',
  styleUrls: ['../../../../styles/components/steps.scss', 'changeStepType-form.component.scss'],
  templateUrl: 'changeStepType-form.component.html',
})

export class ChangeStepTypeFormComponent implements DoCheck {

  @Input() stepInfo: StepDto;
  @Input() allSteps: StepDto[];
  @Output() errors: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  stepTypes = [];

  constructor(private stepService: StepService) {
  }

  ngOnInit() {
    this.stepTypes = this.stepService.getVisualStepTypes();
  }

  ngDoCheck(): void {
      this.errors.emit(false);
  }

  setStepType(stepTypeId): void {
    this.stepInfo.stepTypeId = stepTypeId;
    this.stepInfo.state = 'OK';
  }

}
