import { Component, OnInit, DoCheck, ViewChild, Input, Output, EventEmitter, ElementRef, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { StepDto } from '../../../../models/StepDTO';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LinkFormComponent } from '../link-form/link-form.component';
import { FileDto } from '../../../../models/FileDto';
import { FileInputComponent } from '../../../file-input/file-input.component';


@Component({
  selector: 'wdm-multi-answer-form',
  styleUrls: ['../../../../styles/components/steps.scss', 'multi-answer-form.component.scss'],
  templateUrl: 'multi-answer-form.component.html'
})

export class MultiAnswerFormComponent implements OnInit, DoCheck {

  @ViewChild('questionForm', { static: true }) private questionForm: NgForm;
  @ViewChild('stepImage') img: ElementRef;
  @ViewChild(LinkFormComponent) linkComponent: LinkFormComponent;
  @Input() stepInfo: StepDto;
  @Input() allSteps: StepDto[];
  @Output() errors: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  imageFiles: FileDto[] = [];
  fileInputErrors: boolean;
  @ViewChild(FileInputComponent) fileInputComponent: FileInputComponent;
  private sub: Subscription = new Subscription();
  isMaxSize = false;

  constructor(private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.imageFiles = this.stepInfo.fileList;
  }

  ngDoCheck(): void {
    if (this.questionForm.status === 'INVALID' || (this.linkComponent != undefined && this.linkComponent.answersForm.status === 'INVALID') || this.fileInputErrors == true) { 
      this.errors.emit(true);
    } else {
      this.errors.emit(false);
      this.stepInfo.state = "OK";
    }
  }

  ngAfterViewInit() {
    this.sub = this.linkComponent.answerInput.changes.subscribe(resp => {
      if (this.linkComponent.answerInput.length > 1) {
        this.linkComponent.answerInput.last.nativeElement.focus();
        this.cd.detectChanges();
      }
    });
  }

  onFileChanges(inputFiles) {
    this.stepInfo.fileList = this.imageFiles;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updateLinks(deletedStep: StepDto) {
    this.linkComponent.updateLinks(deletedStep);
  }

  forceValidation() {
    this.questionForm.controls.title.markAsTouched();
    this.questionForm.controls.description.markAsTouched();
    this.linkComponent.forceValidation();
  }

  hasErrors(hasErrors: boolean): void {
    this.fileInputErrors = hasErrors;
  }

}

