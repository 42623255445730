
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';

import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { WorkFlowService } from '../../../services/workflow.service';
import { DashBoardComponent } from '../../dashboard/dashboard.component';
import { AlertDialogComponent } from '../../dialogs/alert/alert.component';
import { MatDialog } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AlertComponent } from '../../alert/alert.component';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { MatPaginatorIntlCustom } from '../../../common/MatPaginatorCustom';
import { TranslateService } from '../../../services/translate.service';
import { FilterService } from '../../../services/filter.service';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';


@Component({
  selector: 'wdm-list-workflow-table',
  styleUrls: ['list.workflow.component.scss'],
  templateUrl: './list.workflow.component.html',
  providers: [TranslatePipe]
})

export class ListWorkFlowComponent {

  displayedColumns: string[];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild('alertComponentWorkflows', { static: true }) private alertComponent: AlertComponent;
  @ViewChild(MatSort) sort: MatSort;
  @Output()
  dateChange: EventEmitter<MatDatepickerInputEvent<any>> = new EventEmitter();
  isManager: boolean;
  isCreator: boolean;

  constructor(public workflowService: WorkFlowService, private dashBoard: DashBoardComponent, private router: Router, public dialog: MatDialog,
    private translateService: TranslateService, private translatePipe: TranslatePipe, private filterService: FilterService, private auth: AuthService) {
    this.loadWorkflowList();
    translateService.LanguageChanged.subscribe((data: string) => {
      this.setPaginator();
    });
    this.displayedColumns = ['name', 'desc', 'creationDate', 'designerName', 'modifiedDate', 'modifierDesignerName', 'qrCode', 'allowedUsers', 'clone', 'active'];
    this.isManager = this.auth.IsTechManager() || this.auth.IsDesignerManager();
    this.isCreator = this.auth.IsCreator();

    if (!this.isManager) {
      const index = this.displayedColumns.indexOf('allowedUsers', 0);
      if (index > -1) {
        this.displayedColumns.splice(index, 1);
      }
    }

    if (!this.isCreator) {
      let index = this.displayedColumns.indexOf('clone', 0);
      if (index > -1) {
        this.displayedColumns.splice(index, 1);
      }
      index = this.displayedColumns.indexOf('active', 0);
      if (index > -1) {
        this.displayedColumns.splice(index, 1);
      }
    }
  }

  filterForm = new FormGroup({
    startDate_creationDate: new FormControl(),
    endDate_creationDate: new FormControl(),
    startDate_modifiedDate: new FormControl(),
    endDate_modifiedDate: new FormControl()
  });

  applyFilter(filterValue: string) {
    this.dataSource.filter = this.filterService.applyFilter(filterValue);
    this.setPage();
  }

  applyDateFilter(field: string) {
    this.dataSource.filter = this.filterService.applyDateFilter(this.filterForm, field);
    this.setPage();
  }

  private setPage() {
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onDateChange(): void {
    this.dateChange.emit();
  }

  editWorkflow(workFlowId, event: any) {
    event.stopPropagation();
    this.dashBoard.viewListWorkFlow = false;
    this.router.navigate(['/dashboard/workflow/', workFlowId], { queryParamsHandling: "preserve" });
  }

  addStep(workFlowId) {
    this.router.navigate(['/dashboard/workflow/' + workFlowId + '/step'], { queryParamsHandling: "preserve" });
  }

  deleteWorkflow(workflowId: number, event: Event): void {
    event.stopPropagation();

    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: 'auto',
      data: {
        type: 'QUESTION',
        title: 'AreYouSure',
        message: 'dialog_delete_workflow_header',
        buttons: 'YESNO'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.workflowService.deleteWorkflow(workflowId).subscribe({
          next: (res) => {
            if (res.result == -1)
              this.alertComponent.showMessage('newworkflow_error', 'error', 5000);
            if (res.result > 0) {
              this.loadWorkflowList();
              this.alertComponent.showMessage('newworkflow_success_delete', 'success', 5000);
            }
          },
          error: (err) => {
            console.log('Error', err);
            this.alertComponent.showMessage('newworkflow_error', 'error', 5000);
          }
        });
    });
  }


  cloneWorkflow(workflowId: number, event: Event): void {
    event.stopPropagation();

    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: 'auto',
      data: {
        type: 'QUESTION',
        title: 'AreYouSure',
        message: 'dialog_clone_workflow_header',
        buttons: 'YESNO'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.workflowService.cloneWorkflow(workflowId).subscribe({
          next: (res) => {
            if (res == null)
              this.alertComponent.showMessage('newworkflow_error', 'error', 5000);
            else {
              this.loadWorkflowList();
              this.alertComponent.showMessage('newworkflow_success_clone', 'success', 5000);
            }
          },
          error: (err) => {
            console.log('Error', err);
            this.alertComponent.showMessage('newworkflow_error', 'error', 5000);
          }
        });
    });
  }

  loadWorkflowList() {
    this.workflowService.getWorkFlowList().subscribe({
      next: (data: any[]) => {
        if (data != null) {
          this.dataSource = new MatTableDataSource(data);

          // force order by
          this.sort.direction = 'desc';
          this.sort.active = 'modifiedDate';

          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'CreationDate': return new Date(item.dateCreated);
              case 'ModifiedDate': return new Date(item.modifiedDate);
              default: return item[property];
            }
          };

          this.dataSource.filterPredicate = this.filterService.createFilter(this.dataSource.filterPredicate);
          this.setPaginator();
        }
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => console.log('workflowlist loaded ok')
    });
  }

  private setPaginator() {
    this.paginator._intl = new MatPaginatorIntlCustom(this.translatePipe);
    this.paginator.pageSize = 10;

    if (this.dataSource != undefined) this.dataSource.paginator = this.paginator;
  }

}
