<wdm-header-root></wdm-header-root>

<!--<h3 class="groups-title">{{ 'groups_title' | translate }}</h3>-->

<div class="groups-tabs">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="{{ 'tech_groups_title' | translate }}" *ngIf="isTechManager"> <user-groups-component [groupTypeId]="this.groupTypeEnum.Technician"></user-groups-component> </mat-tab>
    <mat-tab label="{{ 'designer_groups_title' | translate }}" *ngIf="isDesignerManager"> <user-groups-component [groupTypeId]="this.groupTypeEnum.Designer"></user-groups-component> </mat-tab>
  </mat-tab-group>
</div>
