
<div class="with-close-button">
  <h2 mat-dialog-title><img [src]="getShape(this.stepInfo.stepTypeId)" class="step-icon" title="{{ getName(this.stepInfo.stepTypeId) | translate}}" /> {{ 'step_edit_info' | translate }}{{ this.stepInfo.stepId }}</h2>
  <div class="cancel-button">
    <button type="button" class="icon-btn deselected cancel-button" mat-button [mat-dialog-close]="'cancel'">
      <i class="fa fa-times"
         aria-hidden="true"></i>
    </button>
  </div>
</div>

<mat-dialog-content class="step-form-content">
  <div class="container-wd">
    <div *ngIf="stepInfo.stepTypeId === stepType.New || stepInfo.state === 'CHANGE'">
      <wdm-changeStepType-form [stepInfo]='stepInfo' [allSteps]="data.allSteps" (errors)="errors($event)"></wdm-changeStepType-form>
    </div>
    <div *ngIf="stepInfo.stepTypeId === stepType.Text && stepInfo.state != 'CHANGE'">
      <wdm-text-form [stepInfo]='stepInfo' (errors)="errors($event)"></wdm-text-form>
    </div>
    <div *ngIf="stepInfo.stepTypeId === stepType.Image && stepInfo.state != 'CHANGE'">
      <wdm-image-form [stepInfo]='stepInfo' (errors)="errors($event)"></wdm-image-form>
    </div>
    <div *ngIf="stepInfo.stepTypeId === stepType.Capture && stepInfo.state != 'CHANGE'">
      <wdm-capture-form [stepInfo]='stepInfo' (errors)="errors($event)"></wdm-capture-form>
    </div>
    <div *ngIf="stepInfo.stepTypeId === stepType.QrCode && stepInfo.state != 'CHANGE'">
      <wdm-qrCode-form [stepInfo]='stepInfo' (errors)="errors($event)"></wdm-qrCode-form>
    </div>
    <div *ngIf="stepInfo.stepTypeId === stepType.Numeric && stepInfo.state != 'CHANGE'">
      <wdm-numeric-form [stepInfo]='stepInfo' [allSteps]="data.allSteps" (errors)="errors($event)"></wdm-numeric-form>
    </div>
    <div *ngIf="stepInfo.stepTypeId === stepType.Meter && stepInfo.state != 'CHANGE'">
      <wdm-meter-form [stepInfo]='stepInfo' (errors)="errors($event)"></wdm-meter-form>
    </div>
    <div *ngIf="this.stepInfo.stepTypeId === stepType.FreeAnswer && stepInfo.state != 'CHANGE'">
      <wdm-question-form [stepInfo]='stepInfo' (errors)="errors($event)"></wdm-question-form>
    </div>
    <div *ngIf="(this.stepInfo.stepTypeId === stepType.MultiAnswer || this.stepInfo.stepTypeId === stepType.BinaryQuestion) && stepInfo.state != 'CHANGE'">
      <wdm-multi-answer-form [stepInfo]='stepInfo' [allSteps]="data.allSteps" (errors)="errors($event)"></wdm-multi-answer-form>
    </div>
    <div *ngIf="stepInfo.stepTypeId === stepType.Checklist && stepInfo.state != 'CHANGE'">
      <wdm-checklist-form [stepInfo]='stepInfo' (errors)="errors($event)"></wdm-checklist-form>
    </div>
    <div *ngIf="stepInfo.stepTypeId === stepType.RecordVideo && stepInfo.state != 'CHANGE'">
      <wdm-recordVideo-form [stepInfo]='stepInfo' (errors)="errors($event)"></wdm-recordVideo-form>
    </div>
    <div *ngIf="stepInfo.stepTypeId === stepType.Video && stepInfo.state != 'CHANGE'">
      <wdm-video-form [stepInfo]='stepInfo' (errors)="errors($event)"></wdm-video-form>
    </div>
    <div *ngIf="stepInfo.stepTypeId === stepType.Date && stepInfo.state != 'CHANGE'">
      <wdm-date-form [stepInfo]='stepInfo' (errors)="errors($event)"></wdm-date-form>
    </div>
    <div *ngIf="stepInfo.stepTypeId === stepType.PDF && stepInfo.state != 'CHANGE'">
      <wdm-pdf-form [stepInfo]='stepInfo' (errors)="errors($event)"></wdm-pdf-form>
    </div>
    <div *ngIf="stepInfo.stepTypeId === stepType.Tag && stepInfo.state != 'CHANGE'">
      <wdm-tag-form [stepInfo]='stepInfo' (errors)="errors($event)"></wdm-tag-form>
    </div>
  </div>
</mat-dialog-content>

<div class="dialog-wf-footer">
  <mat-dialog-actions align="start" class="step-wf-buttons">
    <mat-checkbox *ngIf="this.stepInfo.stepTypeId != stepType.MultiAnswer && this.stepInfo.stepTypeId !== stepType.BinaryQuestion && this.stepInfo.stepTypeId !== stepType.New" [color]="comboColor" [checked]="stepInfo.skippable" (change)="stepInfo.skippable = !stepInfo.skippable" class="skip-step">
      {{ 'step_skip' | translate }}
    </mat-checkbox>

  </mat-dialog-actions>
  <mat-dialog-actions align="end" class="step-wf-buttons">

    <button type="button" *ngIf='this.data.showDeleteButton || stepInfo.stepTypeId === stepType.New' class="icon-btn hvr-grow" mat-button [mat-dialog-close]="'delete'" title="{{ 'common_tooltips_delete_step' | translate}}">
      <i class="fa fa-trash" aria-hidden="true"></i>
    </button>

    <button type="button" *ngIf='!this.data.showDeleteButton && stepInfo.stepTypeId != stepType.New' class="icon-btn hvr-grow disabled" mat-button title="{{ this.data.reasonNotToDelete | translate}}">
      <i class="fa fa-trash" aria-hidden="true"></i>
    </button>

    <button *ngIf='!hasErrors && stepInfo.state === "OK" && isFirstStep()' class="icon-btn hvr-grow" mat-button (click)='saveChanges()' [mat-dialog-close]="'saveAndPrev'" title="{{ 'save_and_prev' | translate}}">
      <div class="save-button save-and-prev"></div>
    </button>

    <button *ngIf='!hasErrors && stepInfo.state === "OK" && stepInfo.links.length === 0 && (!this.stepService.isMultipathStep(this.stepInfo))' class="icon-btn hvr-grow" mat-button (click)='saveChanges()' [mat-dialog-close]="'saveAndNew'" title="{{ 'save_and_next' | translate}}">
      <div class="save-button save-and-next"></div>
    </button>

    <button *ngIf='!hasErrors && stepInfo.state === "OK" && stepInfo.links.length != 0 && (!this.stepService.isMultipathStep(this.stepInfo))' class="icon-btn hvr-grow" mat-button (click)='saveChanges()' [mat-dialog-close]="'saveAndBetween'" title="{{ 'save_and_next' | translate}}">
      <div class="save-button save-and-next"></div>
    </button>

    <wdm-link-form *ngIf="!this.stepService.isMultipathStep(this.stepInfo) && this.stepInfo.stepTypeId !== stepType.New" [stepInfo]='stepInfo' [allSteps]="data.allSteps"></wdm-link-form>

    <button *ngIf='!hasErrors && stepInfo.state === "OK"' class="icon-btn btn-primary hvr-grow" mat-button (click)='saveChanges()' [mat-dialog-close]="'saveAndEnd'" title="{{ 'save_step' | translate}}">
      <div class="save-button save-step"></div>
    </button>

    <button type="button" *ngIf='hasErrors' class="icon-btn hvr-grow disabled" mat-button (click)='validate()' title="Some field is missing, please check the form">
      <div class="save-button save-disabled"></div>
    </button>

  </mat-dialog-actions>
</div>
