import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from '../../../services/users.service';
import { Group } from '../../../models/Group';
import { User } from '../../../models/User';

@Component({
  selector: 'wdm-dialog-user-groups',
  templateUrl: 'dialog-user-groups.component.html',
  styleUrls: ['dialog-user-groups.component.scss', '../../../styles/components/dialog.scss']
})

export class DialogUsersGroupsComponent implements OnInit {

  userGroupsList: Group[];
  deletedGroups: number[];
  user: User;
  error: string;

  constructor(public dialogRef: MatDialogRef<DialogUsersGroupsComponent>, @Inject(MAT_DIALOG_DATA) public data: { user: User }, private userService: UsersService) {
    this.user = data.user;
  }

  ngOnInit() {
    console.log('data', this.user.id);
    this.userGroupsList = [];
    this.deletedGroups = [];
    this.loadGroups();
  }

  loadGroups(): void {
    this.userService.getUserGroups(this.user.id, this.user.groupTypeId).subscribe({
      next: (data: any) => {
        if (data != null) {
          this.userGroupsList = data;
        }
      },
      error: (err) => console.log(err)
    });
  
  }

  remove(group: Group): void {
    const index = this.userGroupsList.indexOf(group);

    if (index >= 0) {
      this.userGroupsList.splice(index, 1);
      this.deletedGroups.push(group.id);      
    }
  }


  saveUserGroups() {
    this.userService.deleteUserGroups(this.user.id, this.user.groupTypeId, this.deletedGroups).subscribe({
      next: (data: any) => {
        if (data != null) {
          this.userGroupsList = data;
        }
        this.dialogRef.close(this.deletedGroups);
      },
      error: (err) => console.log(err)
    });
    
  }

}

