<mat-nav-list>
    <div class="container">
        <div class="row justify-content-md-center">
          <div class="col col-auto">

            <div class="qr-area">
              <div class="img-thumbnail-box">

                <a download="workflow{{ data.workflowId }}.png" href="{{data.baseUrl}}/WorkFlow/GetQR?id={{data.workflowId}}&token={{data.imgToken}}" class="qr-download">

                  <img class="qr-code img-thumbnail squarethumbnail" src="{{data.baseUrl}}/WorkFlow/GetQR?id={{data.workflowId}}&token={{data.imgToken}}" />

                  <span class="qr-download-hover">
                    <div class="donwload-button">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.28 41.41">
                        <defs>
                          <style>
                            .cls-1 {
                              fill: #fff;
                            }
                          </style>
                        </defs>
                        <g id="ART">
                          <rect class="cls-1" x="6.67" y="6" width="15" height="3" rx="0.94" />
                          <path class="cls-1" d="M16.5,6.56V4.17A1.17,1.17,0,0,1,17.67,3H30.33A1.17,1.17,0,0,1,31.5,4.17h0V6.56a.94.94,0,0,1-.94.94H17.44a.94.94,0,0,1-.94-.94Z" transform="translate(-9.83 -3)" />
                          <path class="cls-1" d="M23.11,44,10.18,29a1.17,1.17,0,0,1,.89-2H16.5V14.44a.94.94,0,0,1,.94-.94H30.56a.94.94,0,0,1,.94.94h0V27h5.43a1.17,1.17,0,0,1,1.18,1.16,1.18,1.18,0,0,1-.29.78L24.89,44a1.17,1.17,0,0,1-1.65.13Z" transform="translate(-9.83 -3)" />
                        </g>
                      </svg>
                    </div>
                  </span>
                  
                </a>

              </div>
             
            </div>
          </div>

        </div>
    </div>
</mat-nav-list>
