import { Component, Inject } from '@angular/core'
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';

@Component({
    selector: 'wdm-bottom-sheet-qr',
    styleUrls: ['bottom-sheet-qr.component.scss'],
    templateUrl: 'bottom-sheet-qr.component.html',
  })
  export class BottomSheetQR {
    constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetQR>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
        ) {}

    openLink(event: MouseEvent): void {
      this._bottomSheetRef.dismiss();
      event.preventDefault();
    }
  }