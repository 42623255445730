import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class WorkFlowReportService {

  constructor(private http: HttpClient) {

  }

  getMultimediaFromReportId(reportId: number) {
    const headers = { 'content-type': 'application/json' };
    var request = { 'Id': reportId };

    return this.http.post<any>(environment.apiBaseUrl + '/Report/GetMultimediaList', JSON.stringify(request), {'headers': headers });
  }

  getWorkflowReportList() {
    const headers = { 'content-type': 'application/json' };
    return this.http.post<any>(environment.apiBaseUrl + '/Report/GetList', null, { 'headers': headers });
  }

  getReport(reportId: number, lang: string) {
    const headers = { 'content-type': 'application/json' };
    var request = { 'reportId': reportId, 'lang': lang };

    return this.http.post<any>(environment.apiBaseUrl + '/Report/GetReport', JSON.stringify(request), { 'headers': headers });
  }

  generateCsv(reportId: number, lang: string) {
    const headers = { 'content-type': 'application/json' };
    var request = { 'reportId': reportId, 'lang': lang };
    
    return this.http.post<any>(environment.apiBaseUrl + '/Report/GenerateCsv', JSON.stringify(request), { headers: headers, responseType: 'blob' as 'json' });
  }

  generateTranscriptionFile(reportId: number) {
    const headers = { 'content-type': 'application/json' };
    var request = { 'reportId': reportId };
    
    return this.http.post<any>(environment.apiBaseUrl + '/Report/GenerateTranscriptionFile', JSON.stringify(request), { headers: headers, responseType: 'blob' as 'json' });
  }

  getImage(reportId: number, imageName: string) {
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const params = new HttpParams({
      fromObject: {
        ReportId: reportId.toString(),
        FileName: imageName
      }
    });
    return this.http.post<any>(environment.apiBaseUrl + '/Report/GetImage', params, { 'headers': headers });
  }

  getVideo(reportId: number, videoName: string) {
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const params = new HttpParams({
      fromObject: {
        ReportId: reportId.toString(),
        FileName: videoName
      }
    });
    return this.http.post<any>(environment.apiBaseUrl + '/Report/GetVideo', params, { 'headers': headers });
  }

  getAudio(reportId: number, audioName: string) {
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const params = new HttpParams({
      fromObject: {
        ReportId: reportId.toString(),
        FileName: audioName
      }
    });
    return this.http.post<any>(environment.apiBaseUrl + '/Report/GetAudio', params, { 'headers': headers });
  }

}
