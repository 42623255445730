import { Component, OnDestroy, DoCheck, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { StepDto, ValidationContent } from '../../../../models/StepDTO';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'wdm-meter-form',
  styleUrls: ['../../../../styles/components/steps.scss'],
  templateUrl: 'meter-form.component.html',
})

export class MeterFormComponent  implements DoCheck {

  @ViewChild('meterForm', {static: true}) private meterForm: NgForm;
  @Input() stepInfo: StepDto;
  @Output() errors: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  numberOperations: string[] = [null, '=', '<', '>', '<=', '>='];
  content: ValidationContent = new ValidationContent();

  constructor() {
  }

  ngOnInit() {
    if (this.stepInfo.content != null) {
      this.content = JSON.parse(this.stepInfo.content);
    }
  }

  ngDoCheck(): void {
    
    if (this.meterForm.status === 'INVALID') {
      this.errors.emit(true);
    } else {
      this.stepInfo.state = "OK";
      this.errors.emit(false);
      if (this.stepInfo?.content !== null) {
        this.stepInfo.content = JSON.stringify(this.content);
      }
    }
  }

  forceValidation() {
    this.meterForm.controls.title.markAsTouched();
    this.meterForm.controls.description.markAsTouched();
    this.meterForm.controls.operation.markAllAsTouched();
    this.meterForm.controls.validationValue.markAllAsTouched();
  }

  preventComas(event) {
    if (event.key === ",") event.preventDefault();
  }

}
