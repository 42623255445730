
export class ColorService {
  constructor() { }

  subtractLight(color, amount: number) {
    let cc = parseInt(color, 16) - amount;
    let c: any = (cc < 0) ? 0 : (cc);
    c = (c.toString(16).length > 1) ? c.toString(16) : `0${c.toString(16)}`;
    return c;
  }

  darken(color, amount: number) {
    color = (color.indexOf("#") >= 0) ? color.substring(1, color.length) : color;
    amount = (255 * amount) / 100;
    return color = '#' + this.subtractLight(color.substring(0, 2), amount).toString() + this.subtractLight(color.substring(2, 4), amount).toString() + this.subtractLight(color.substring(4, 6), amount).toString();
  }

  getRandomColor() {
    return '#' + (0x1000000 + Math.random() * 0xFFFFFF).toString(16).substr(1, 6);
  }
}

