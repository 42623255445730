import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Constants } from '../Constants/Constants';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private jwtHelper = new JwtHelperService();

  constructor() { }

  public getToken(): string {

    return localStorage.getItem(Constants.Token);
  }

  public getLogExpirationDate() {
    console.log('TokenExpDate: ' + this.jwtHelper.getTokenExpirationDate(this.getToken()));
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    // Check whether the token is expired and return
    if (token == null || token === '') {
      return false;
    } else {
      const isValid = !this.jwtHelper.isTokenExpired(token);

      if (isValid) {
        // console.log("TokenExpDate: " + this.jwtHelper.getTokenExpirationDate(token))
      } else {
        console.log('Token is expired');
      }
      return isValid;
    }
  }
  public IsCreator():boolean {
    return localStorage.getItem(Constants.IsCreator) == Constants.True;
  }

  public IsReviewer(): boolean {
    return localStorage.getItem(Constants.IsReviewer) == Constants.True;
  }

  public IsTechManager(): boolean {
    return localStorage.getItem(Constants.IsTechManager) == Constants.True;
  }

  public IsDesignerManager(): boolean {
    return localStorage.getItem(Constants.IsDesignerManager) == Constants.True;
  }

}
