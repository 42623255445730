<div class="type-items-grid">
  <div *ngFor="let type of stepTypes">
    <div (click)='setStepType(type.id)' class="type-item" title="{{type.name | translate}}">
      <img [class.selected]="type.id == stepInfo.stepTypeId" src="/assets/img/IconStepType/{{type.image}}" width="80" style="cursor:pointer" class="step-item" />
      <p>
        <b>{{type.name | translate}}</b>
        <br />{{type.description | translate}}
      </p>
    </div>
  </div>
</div>
