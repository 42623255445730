<div class="text-info">
  <p><b>{{info.Question}}</b></p>
  <div *ngFor="let response of info.OriginalResponses">
    <div class="check-area">
      <mat-checkbox class="response" [checked]="response.Answer == info.Response" [disabled]="true"></mat-checkbox>
      <p *ngIf="response.Answer != info.Response">{{response.Answer}}</p>
      <p *ngIf="response.Answer == info.Response"><b>{{response.Answer}}</b></p>
    </div>
  </div>
</div>
