import { Component, OnInit, Injectable, ViewChild } from '@angular/core';
import { StepType } from '../../../models/StepTypes';
import { ActivatedRoute } from '@angular/router';
import { WorkFlowReportService } from '../../../services/workflowreport.service';
import { TranslateService } from '../../../services/translate.service';
import { StepService } from '../../../services/step.service';
import { AlertComponent } from '../../alert/alert.component';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { WhitelabelService } from '../../../services/whitelabel.service';

@Injectable({ providedIn: 'root' })

@Component({
    selector: 'wdm-HTML-report',
    templateUrl: 'report-html.component.html',
  styleUrls: ['report-html.component.scss'],
  providers: [TranslatePipe]
})

export class ReportHTMLComponent implements OnInit {

  parameters: any;
  idReport = 0;
  report: any;
  loading = false;
  hasErrors = true;
  appName = 'Process eye';
  stepType: typeof StepType = StepType;
  @ViewChild('alertComponentReports', { static: true }) private alertComponent: AlertComponent;
  
  constructor(public workFlowReportService: WorkFlowReportService, private routeAct: ActivatedRoute, private translateService: TranslateService,
    private translatePipe: TranslatePipe, private stepService: StepService, private whitelabelService: WhitelabelService) {
  }

  ngOnInit() {
    this.appName = this.whitelabelService.getCurretWhitelabelName() != '' ? this.whitelabelService.getCurretWhitelabelName() : this.appName;

    this.parameters = this.routeAct.params.subscribe(params => {
      if (params.id > 0) {
        this.idReport = params.id;
      }
    });
  }

  ngAfterContentInit() {
    if (this.idReport > 0) {
      this.loadReport();
    }
  }

  loadReport() {
    this.loading = true;
    this.hasErrors = false;
    this.workFlowReportService.getReport(this.idReport, this.translateService.getLang()).subscribe({
      next: (data: any[]) => {
        if (data != null) {
          this.report = data;
        }
        this.loading = false;
      },
      error: (err) => {
        console.log(err);
        this.hasErrors = true;
        this.loading = false;
        this.alertComponent.showMessage(err.error, 'error', 5000);
      },
      complete: () => console.log('report loaded ok', this.report)

    });
  }

  getShape(stepTypeId): string {
    return this.stepService.getStepShapeRoute(stepTypeId);
  }

  getName(stepTypeId): string {
    return this.stepService.getStepTypeName(stepTypeId);
  }

  getDuration(duration): any {
    const date = new Date(null);
    date.setSeconds(duration/1000); // specify value for SECONDS
    const result = date.toISOString().slice(11, 19);
    return result;
  }

  printReport() {
    document.title = this.report.workflowName + " report";
    window.print();
  }

}

