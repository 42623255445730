//these are the supported languages in RemoteEye, but we remove most for ease of maintenance
export class Languages {
  //static DE: string = "de";
  static EN: string = "en";
  static ES: string = "es";
  //static FR: string = "fr";
  //static IT: string = "it";
  //static JA: string = "ja";
  //static ZH: string = "zh";
}
