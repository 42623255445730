import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Group, GroupTypeEnum } from '../../../models/Group';
import { User } from '../../../models/User';
import { AuthService } from '../../../services/auth.service';
import { UsersService } from '../../../services/users.service';
import { DialogGroupEditComponent } from './../dialog-edit/dialog-edit.component';
import { DialogUsersGroupsComponent } from '../dialog-user-groups/dialog-user-groups.component';
import { TranslatePipe } from '../../../pipes/translate.pipe';


@Component({
  selector: 'user-groups-component',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.scss'],
  providers: [TranslatePipe]
})
export class UserGroupsComponent {

  groupsList:any[];
  usersList: any[];
  groupsListAll: any[] = [];
  usersListAll: any[];
  groupSelected: Group;
  searchUserValue: string;
  searchGroupValue: string;
  groupTypeEnum: typeof GroupTypeEnum = GroupTypeEnum;
  @Input() groupTypeId: GroupTypeEnum;

  constructor(private auth: AuthService, private usersService: UsersService, public dialog: MatDialog, private translateService: TranslatePipe) {
    this.groupSelected = new Group();
  }

  ngOnInit() {
    this.loadGroups(-1);
  }

  loadGroups(groupId: number) {
    return this.usersService.getGroupList(this.groupTypeId).subscribe({
      next: (data: any[]) => {
        if (data != null) {
          this.groupsList = data;
          this.changeCurrentGroup(groupId);
          if (this.groupsListAll == undefined || this.groupsListAll.length != this.groupsList.length) this.groupsListAll = this.groupsList;
        }

        this.groupsList.find(item => item.id == this.usersService.ALL_GROUP_ID).name = this.translateService.transform('See_All');
      },
      error: (err) => console.log(err),
      complete: () => console.log('users groups loaded ok')
    });
  }

  loadUsers() {
    this.usersService.getUsersList(this.groupTypeId).subscribe({
      next: (data: any[]) => {
        if (data != null) {
          this.usersList = data;
          if (this.usersListAll == undefined) this.usersListAll = this.usersList;
        }
      },
      error: (err) => console.log(err),
      complete: () => console.log('users loaded ok')
    });
  }

  changeCurrentGroup(groupId: number) {
    this.groupSelected = this.groupsList.find(g => g.id == groupId);

    if (groupId == -1) {
      this.loadUsers();
      return;
    }

    this.usersService.getGroup(groupId, this.groupTypeId).subscribe({
      next: (data: any[]) => {
        if (data != null) {
          this.usersList = data;
        }
      },
      error: (err) => console.log(err),
      complete: () => console.log('user groups change ok')
    });
  }

  new(event: any) {
    event.stopPropagation();

    if (this.auth.isAuthenticated()) {

      var group: Group = new Group();
      group.groupTypeId = this.groupTypeId;

      const dialogGroups = this.dialog.open(DialogGroupEditComponent, {
        width: '90%',
        data: {
          group: group,
          groupsList: this.groupsList
        }
      });

      dialogGroups.afterClosed().subscribe(data => {
        if (data != 'cancel') {
          this.loadGroups(data);
        }

      });
    }
  }

  edit(event: any) {
    event.stopPropagation();
    
    if (this.auth.isAuthenticated()) {
      const dialogGroups = this.dialog.open(DialogGroupEditComponent, {
        width: '90%',
        data: {
          group: this.groupSelected,
          groupsList: this.groupsList
        }
      });

      dialogGroups.afterClosed().subscribe(data => {
        if (data == undefined) return;
        if (data != 'cancel') {
          this.loadGroups(data);
        }

      });
    }
  }

  showUserGroups(event: any, user: User) {
    event.stopPropagation();

    if (this.auth.isAuthenticated()) {
      const dialogUserGroups = this.dialog.open(DialogUsersGroupsComponent, {
        width: '40%',
        data: {
          user: user
        }
      });

      dialogUserGroups.afterClosed().subscribe(deletedGroups => {
        if (deletedGroups == undefined) return;
        if (deletedGroups.find(deletedGroupId => deletedGroupId == this.groupSelected.id) != undefined) this.changeCurrentGroup(this.groupSelected.id);
      });
    }
  }

  searchUser(event: any) {
    event.stopPropagation();

    var list = this.usersListAll;
    if (this.groupSelected.id > -1) list = this.usersList;

    this.usersList = list.filter(t => t.name.toLowerCase().includes(this.searchUserValue.toLowerCase()));
  }

  searchGroup(event: any) {
    event.stopPropagation();
    this.groupsList = this.groupsListAll.filter(g => g.name.toLowerCase().includes(this.searchGroupValue.toLowerCase()));
  }

  cleanSearchBoxUser(event: any) {
    event.stopPropagation();
    
    this.searchUserValue = "";

    if (this.groupSelected.id > -1) this.changeCurrentGroup(this.groupSelected.id);
    else this.usersList = this.usersListAll;
  }

  cleanSearchBoxGroup(event: any) {
    event.stopPropagation();

    this.searchGroupValue = "";
    this.groupsList = this.groupsListAll;
  }
}
