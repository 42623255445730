import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslatePipe } from '../pipes/translate.pipe';


export class MatPaginatorIntlCustom extends MatPaginatorIntl {

  constructor(private translateService: TranslatePipe) {
    super();
    this.itemsPerPageLabel = this.translateService.transform("items per page")
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    var conectorStr = this.translateService.transform("page of")

    if (length === 0 || pageSize === 0) {
      return '0 ' + conectorStr + ' ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize
    return startIndex + 1 + ' - ' + endIndex + ' ' + conectorStr + ' ' + length;
  };
} 
