import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Constants } from '../Constants/Constants';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {

  constructor(public auth: AuthService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    if (!this.auth.isAuthenticated()) this.router.navigate(['/'],{ queryParamsHandling: "merge" })

    if (request.method == "POST") {
      var token = localStorage.getItem(Constants.Token);

      if (token) {
        request = request.clone({
          setHeaders: { 'token': token }
        });
      }
    }

    return next.handle(request).pipe(
      catchError((err, caught) => {
        if (err.status === 401) {
          this.router.navigate(['/'],{ queryParamsHandling: "merge" })
        }
        return throwError(() => err);
        //return next.handle(request);
        //return  new Observable<HttpEvent<any>>();
      }));

  }
}
