import { Component, Injectable, Input } from '@angular/core';


@Component({
  selector: 'loading-component',
  styleUrls: ['loading.component.scss'],
  templateUrl:'./loading.component.html'
})
@Injectable()
export class LoadingComponent {

  @Input() loading: boolean;

  constructor() {
  }


}
