import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatPaginatorIntlCustom } from '../../common/MatPaginatorCustom';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { AuthService } from '../../services/auth.service';
import { FilterService } from '../../services/filter.service';
import { TranslateService } from '../../services/translate.service';
import { WorkFlowReportService } from '../../services/workflowreport.service';
import { AlertComponent } from '../alert/alert.component';
import { LoadingComponent } from '../loader/loading.component';
import { DialogImageComponent } from './dialog-image/dialog-image.component';
import { formatDate } from '@angular/common';


@Component({
  selector: 'wdm-list-workflowreport-table',
  styleUrls: ['list.workflowreport.component.scss'],
  templateUrl: './list.workflowreport.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [TranslatePipe]
})

export class ListWorkFlowReportComponent {

  token: string;
  expandedElement: any;
  lastReportId = 0;
  images: any[];
  videos: any[];
  audios: any[];
  loading = false;

  displayedColumns = ['technicianName', 'workflowName', 'tags', 'startDate', 'duration', 'actions', 'expand'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('alertComponent', { static: true }) private alertComponent: AlertComponent;
  @Output()
  dateChange: EventEmitter<MatDatepickerInputEvent<any>> = new EventEmitter();

  filterForm = new FormGroup({
    startDate_startDate: new FormControl(),
    endDate_startDate: new FormControl()
  });

  constructor(private workFlowReportService: WorkFlowReportService, public dialog: MatDialog, private auth: AuthService, private router: Router, public loader: LoadingComponent, private translateService: TranslateService,
    private translatePipe: TranslatePipe, private filterService: FilterService){

    this.loadReports();

    translateService.LanguageChanged.subscribe((data: string) => {
      this.setPaginator();
    });
  }

  private loadReports() {
    this.workFlowReportService.getWorkflowReportList().subscribe({
      next: (data: any[]) => {
        if (data != null) {

          this.dataSource = new MatTableDataSource(data);

          // force order by
          this.sort.direction = 'desc';
          this.sort.active = 'startDate';
          this.dataSource.sort = this.sort;

          // order by date
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'startDate': return new Date(item.startDate);
              default: return item[property];
            }
          };

          this.dataSource.filterPredicate = this.filterService.createFilter(this.dataSource.filterPredicate);
          this.filterService.resetFilter();
          this.setPaginator();
        }
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => console.log('workflowreportlist loaded ok')
    });
  }

  private setPaginator() {
    this.paginator._intl = new MatPaginatorIntlCustom(this.translatePipe);
    this.paginator.pageSize = 10;

    if (this.dataSource != undefined) this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = this.filterService.applyFilter(filterValue);
    this.setPage();
  }

  applyDateFilter(field: string) {
    this.dataSource.filter = this.filterService.applyDateFilter(this.filterForm, field);
    this.setPage();
  }

  private setPage() {
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onDateChange(): void {
    this.dateChange.emit();
  }

  loadImages(reportId: number, multimediaSteps: number, event: any) {
    // load data in #imagesTemplate
    event.stopPropagation();

    console.log("loadImages " + reportId + " - " + multimediaSteps);

    if (multimediaSteps > 0 && (this.lastReportId !== reportId)) {
      this.images = null;
      this.videos = null;
      this.audios = null;

      this.workFlowReportService.getMultimediaFromReportId(reportId).subscribe(
        (data: any) => {
          if (data != null) {
            this.lastReportId = reportId;
            this.images = data.images;
            this.videos = data.videos;
            this.audios = data.audios;
          }
        },
        err => {
          console.log(err);
        },
        () => console.log('multimedia files from this report loaded ok: ' + multimediaSteps, this.images, this.videos, this.audios)
      );
    }

  }

  generateCsv(row: any, event: any) {
    event.stopPropagation();

    if (this.auth.isAuthenticated()) {
      this.loading = true;

      this.workFlowReportService.generateCsv(row.id, this.translateService.getLang()).subscribe({
        next: (fileData: any) => {
          if (fileData != null && fileData.type == 'text/csv') {
            var blob = new Blob([fileData], { type: fileData.type });
            var url = window.URL.createObjectURL(blob);
            var anchor = document.createElement("a");
            anchor.download = row.workflowName.replace(' ', '_') + '_' + row.technicianName.replace(' ', '_') + '_' + formatDate(Date.now(), "yyyyMMddHHmmss", "en-En") + ".csv";
            anchor.href = url;
            anchor.click();
          } else {
            this.alertComponent.showMessage('generate_report_pdf_error', 'error', 5000);
          }

          this.loading = false;

        },
        error: (err) => {
          console.log(err);
          this.loading = false;
          this.alertComponent.showMessage('generate_report_pdf_error', 'error', 5000);
        }
      });

    } else {
      this.router.navigate(['/'], { queryParamsHandling: "merge" });
    }
  }

  generateTranscriptionFile(row: any, event: any) {
    event.stopPropagation();

    if (this.auth.isAuthenticated()) {
      this.loading = true;

      this.workFlowReportService.generateTranscriptionFile(row.id).subscribe({
        next: (fileData: any) => {
          if(fileData != null && fileData.type == 'text/txt') {
            var blob = new Blob([fileData], { type: fileData.type });
            var url = window.URL.createObjectURL(blob);
            var anchor = document.createElement("a");
            anchor.download = row.workflowName.replace(' ', '_') + '_Comments_Transcriptions_' + formatDate(Date.now(), "yyyyMMddHHmmss", "en-En") + ".txt";
            anchor.href = url;
            anchor.click();
          } else {
            this.alertComponent.showMessage('generate_report_pdf_error', 'error', 5000);
          }

          this.loading = false;

        },
        error: (err) => {
          console.log(err);
          this.loading = false;
          this.alertComponent.showMessage('generate_report_pdf_error', 'error', 5000);
        }
      });

    } else {
      this.router.navigate(['/'], { queryParamsHandling: "merge" });
    }
  }

  openImage(imageName: string, reportId: number) {
    if (this.auth.isAuthenticated()) {
      this.workFlowReportService.getImage(reportId, imageName).subscribe({
        next: (data: any) => {
          if (data != null) {
            const dialogImage = this.dialog.open(DialogImageComponent, {
              width: '90%',
              data: {
                img: data.fileUrl,
              }
            });
          }
        },
        error: (err) => {
          console.log(err);
        }
      });

    } else {
      this.router.navigate(['/'], { queryParamsHandling: "merge" });
    }
  }

  openVideo(videoName: string, reportId: number) {
    if (this.auth.isAuthenticated()) {

      return this.workFlowReportService.getVideo(reportId, videoName).subscribe({
        next: (data: any) => {
          if (data != null) {
            var a = document.createElement('a');
            a.href = data.videoUrl;
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
          }
        },
        error: (err) => {
          console.log(err);
        }
      });

    } else {
      this.router.navigate(['/'], { queryParamsHandling: "merge" });
    }
  }

  openAudio(audioName: string, reportId: number) {
    if (this.auth.isAuthenticated()) {

      return this.workFlowReportService.getAudio(reportId, audioName).subscribe({
        next: (data: any) => {
          if (data != null) {
            var a = document.createElement('a');
            a.href = data.audioUrl;
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
          }
        },
        error: (err) => {
          console.log(err);
        }
      });

    } else {
      this.router.navigate(['/'], { queryParamsHandling: "merge" });
    }
  }

}

