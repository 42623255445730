import { Component, Input} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { WorkFlowReportService } from '../../../../services/workflowreport.service';
import { DialogImageComponent } from '../../dialog-image/dialog-image.component';

@Component({
  selector: 'wdm-comment',
  templateUrl: 'comments.component.html',
  styleUrls: ['comments.component.scss']
})

export class CommentComponent {

  @Input() stepComment: CommentDto;
  @Input() reportId: number;

  constructor(public workFlowReportService: WorkFlowReportService, public dialog: MatDialog, private auth: AuthService, private router: Router) {
  }

  openImage(imageURL: string) {

    const dialogImage = this.dialog.open(DialogImageComponent, {
      width: '90%',
      data: {
        img: imageURL,
      }
    });
  }

}



export class CommentDto {
  comment: string;
  mediaList: medialistDTO[];
}

export class medialistDTO {
  mediaType: number;
  fileName: string;
  fileURL: string;
}
