import { Component, Input } from '@angular/core';

@Component({
  selector: 'wdm-date-report',
  templateUrl: 'date-report.component.html',
  styleUrls: ['date-report.component.scss']
})

export class DateReportComponent {

  @Input() stepinfo: any;
  constructor() { }
  info: any;
  ngOnInit() {
    var result = JSON.parse(this.stepinfo.result);
    var dateTimeSpan = new Date(JSON.parse(result.response))
    this.info = dateTimeSpan.toLocaleDateString();
    console.log('info date question', this.info);
  }

}
