import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WorkFlowReportService } from '../../../../../services/workflowreport.service';
import { DialogImageComponent } from '../../../dialog-image/dialog-image.component';

@Component({
  selector: 'wdm-capture-report',
  templateUrl: 'capture-report.component.html',
  styleUrls: ['capture-report.component.scss']
})

export class CaptureReportComponent {

  @Input() stepinfo: any;

  constructor(public workFlowReportService: WorkFlowReportService, public dialog: MatDialog) { }

  openImage(imageURL: string) {

    const dialogImage = this.dialog.open(DialogImageComponent, {
      width: '90%',
      data: {
        img: imageURL,
      }
    });
  }

}
