import { Component, Input } from '@angular/core';


@Component({
  selector: 'wdm-question-multi-report',
  templateUrl: 'question-multi-report.component.html',
  styleUrls: ['question-multi-report.component.scss']
})

export class QuestionMultiComponent {


  @Input() stepinfo: any;

  constructor() { }
  info: any;

  ngOnInit() {
    this.info = JSON.parse(this.stepinfo.result);
    console.log('info multirespuesta', this.info);
  }
}

