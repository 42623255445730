<!--
  OPTIONS
  type: [INFO, QUESTION, ALERT]
  title: Free text
  message: Free text
  buttons: [YESNO, OK]
  >-->

<div>
  <h2 mat-dialog-title class="step-info" [class.alert]="this.data.type === 'ALERT'" [class.question]="this.data.type === 'QUESTION'">
    <i *ngIf="this.data.type === 'INFO'" class="fa fa-info-circle alert-icon info" aria-hidden="true"></i>
    <i *ngIf="this.data.type === 'QUESTION'" class="fa fa-question-circle alert-icon question" aria-hidden="true"></i>
    <i *ngIf="this.data.type === 'ALERT'" class="fa fa-exclamation-triangle alert-icon alert" aria-hidden="true"></i>
    {{this.data.title | translate}}
  </h2>
  <!--<div class="cancel-button">
    <button type="button" class="icon-btn deselected cancel-button" mat-button [mat-dialog-close]="'cancel'">
      <i class="fa fa-times"
         aria-hidden="true"></i>
    </button>
  </div>-->
</div>
<mat-dialog-content>
  <p>{{this.data.message | translate}} </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button *ngIf="this.data.buttons === 'YESNO'" [mat-dialog-close]="true" >{{ 'generic_text_yes' | translate }}</button>
  <button mat-button *ngIf="this.data.buttons === 'YESNO'"  (click)="onNoClick()"  cdkFocusInitial>{{ 'generic_text_no' | translate }}</button>
  <button mat-button *ngIf="this.data.buttons === 'Ok'" [mat-dialog-close]="true" (click)="onNoClick()" cdkFocusInitial>{{ 'generic_text_ok' | translate }}</button>
</mat-dialog-actions>

