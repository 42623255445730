<loading-component [loading]="!dataSource"></loading-component>
<wdm-alert #alertComponentWorkflows></wdm-alert>

  <div class="filters-and-table">

    <div class="wf-filters">
      <mat-form-field class="mat-form-field">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'common_placeholders_filter' | translate }}">
      </mat-form-field>

      <mat-form-field class="mat-form-field">
        <mat-label>{{ 'common_headers_creationDate' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="picker1" (click)="picker1.open()" [formGroup]="filterForm">
          <input matStartDate placeholder="{{ 'common_startDate' | translate }}" formControlName="startDate_creationDate" (dateChange)="applyDateFilter('creationDate')">
          <input matEndDate placeholder="{{ 'common_endDate' | translate }}" formControlName="endDate_creationDate" (dateChange)="applyDateFilter('creationDate')"> 
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-date-range-picker #picker1></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field class="mat-form-field">
        <mat-label>{{ 'common_headers_updateDate' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="picker2" (click)="picker2.open()" [formGroup]="filterForm">
          <input matStartDate placeholder="{{ 'common_startDate' | translate }}" formControlName="startDate_modifiedDate" (dateChange)="applyDateFilter('modifiedDate')">
          <input matEndDate placeholder="{{ 'common_endDate' | translate }}" formControlName="endDate_modifiedDate" (dateChange)="applyDateFilter('modifiedDate')">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-date-range-picker #picker2></mat-date-range-picker>
      </mat-form-field>

    </div>

    <div class="mat-elevation-z3 wf-table" >
      <table mat-table [dataSource]="dataSource" matSort>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="row.assigned ? editWorkflow(row.id, $event) : null" [ngClass]="row.assigned ? 'clickable hvr-grow-shadow' : ''"></tr>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'common_headers_name' | translate }} </th>
          <td mat-cell *matCellDef="let row"> {{row.workflowName}} </td>
        </ng-container>

        <!-- Desc Column -->
        <ng-container matColumnDef="desc">
          <th mat-header-cell *matHeaderCellDef> {{ 'common_headers_description' | translate }} </th>
          <td mat-cell *matCellDef="let row"> {{row.workflowDescription}} </td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="creationDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="date"> {{ 'common_headers_creationDate' | translate }} </th>
          <td mat-cell *matCellDef="let row"> {{row.creationDate | date:'dd/MM/yyyy HH:mm:ss a'}}</td>
        </ng-container>

        <!-- Creator Column -->
        <ng-container matColumnDef="designerName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'common_headers_creatorsName' | translate }} </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{'disabled' : row.designerIsActive == false}"> {{row.designerName}}</td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="modifiedDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="date"> {{ 'common_headers_updateDate' | translate }} </th>
          <td mat-cell *matCellDef="let row"> {{row.modifiedDate | date:'dd/MM/yyyy HH:mm:ss a'}}</td>
        </ng-container>

        <!-- Modifier Column -->
        <ng-container matColumnDef="modifierDesignerName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'common_headers_modifierName' | translate }} </th>
          <td mat-cell *matCellDef="let row" [ngClass]="{'disabled' : row.modifierDesignerIsActive == false}"> {{row.modifierDesignerName}}</td>
        </ng-container>

        <!-- QR code Column -->
        <ng-container matColumnDef="qrCode">
          <th mat-header-cell *matHeaderCellDef class="actions no-padding"> {{ 'common_headers_link' | translate }} </th>
          <td mat-cell *matCellDef="let row" class="no-padding">
            <button (click)="workflowService.openBottomSheet(row.id, $event)" type="button" class="icon-btn wf-icon-button inline hvr-grow" mat-button>
              <div class="row-icon qr-icon"></div>
            </button>
          </td>
        </ng-container>

        <!-- Allowed users Column -->
        <ng-container matColumnDef="allowedUsers">
          <th mat-header-cell *matHeaderCellDef class="actions no-padding"> {{ 'common_headers_users' | translate }} </th>
          <td mat-cell *matCellDef="let row" class="no-padding ">
            <button (click)="workflowService.openAllowedUsers(row.id, $event)" type="button" class="icon-btn wf-icon-button inline hvr-grow" mat-button>
              <div class="row-icon users-icon"></div>
            </button>
          </td>
        </ng-container>

        <!-- Clone Column -->
        <ng-container matColumnDef="clone">
          <th mat-header-cell *matHeaderCellDef class="actions no-padding"> {{ 'common_headers_clone' | translate }} </th>
          <td mat-cell *matCellDef="let row" class="no-padding">
            <button *ngIf="row.assigned; else elseBlock" (click)="row.assigned ? cloneWorkflow(row.id, $event) : null" type="button" class="icon-btn wf-icon-button inline hvr-grow" mat-button>
              <div class="row-icon clone-icon"></div>
            </button>
            <ng-template #elseBlock><div class="wf-icon-button inline mdc-button" style="cursor:default"><img src="/assets/img/workflow/clone-disable.svg" class="row-icon" /></div></ng-template>
          </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef class="actions no-padding"> {{ 'common_headers_delete' | translate }} </th>
          <td mat-cell *matCellDef="let row" class="no-padding">
            <button *ngIf="row.assigned; else elseBlock" (click)="row.assigned ? deleteWorkflow(row.id, $event) : null" type="button" class="icon-btn wf-icon-button inline hvr-grow" mat-button>
              <div class="row-icon del-icon"></div>
            </button>
            <ng-template #elseBlock><div class="wf-icon-button inline mdc-button" style="cursor:default"><img src="/assets/img/workflow/trash-disable.svg" class="row-icon" /></div></ng-template>
          </td>
        </ng-container>


      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 15, 25]"></mat-paginator>
    </div>
    </div>

<div>&nbsp;</div>


