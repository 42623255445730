import { Component, ViewChild, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertComponent } from '../../alert/alert.component';
import { GroupTypeEnum } from '../../../models/Group';
import { UsersService } from '../../../services/users.service';
import { SentenceItemDto } from '../../../models/SentenceItemDto';
import { CdkDragDrop, moveItemInArray, copyArrayItem, transferArrayItem } from '@angular/cdk/drag-drop';
import { SentenceGroup, SentenceRequest } from '../../../models/Requests/SentenceRequest';
import { TranslatePipe } from '../../../pipes/translate.pipe';


@Component({
  selector: 'app-allowed-users',
  templateUrl: './allowed-users.component.html',
  styleUrls: ['./allowed-users.component.scss'],
  providers: [TranslatePipe]
})

export class AllowedUsersComponent {

  @ViewChild('alertComponentUsers', { static: true }) private alertComponent: AlertComponent;
  
  ///groupsAvailable: Group[];
  //techniciansAvailable: User[];

  tipetsList: SentenceItemDto[] = [];
  filteredTipetsList: SentenceItemDto[] = [];
  deleteList: SentenceItemDto[] = [];
  operationList: SentenceItemDto[] = [];
  Error: string = ""
  results: string[] = [];

  sentenceEmpty = true;
  sentenceList: SentenceItemDto[] = [];

  searchValue: string;

  groupTypeEnum: typeof GroupTypeEnum = GroupTypeEnum;
  @Input() groupTypeId: GroupTypeEnum;
  @Input() workflowId: number;

  constructor(private usersService: UsersService, private translateService: TranslatePipe) {
  }

  ngOnInit() {
    this.operationList = [];
    this.tipetsList = [];
    this.filteredTipetsList = [];

    this.getGroups();
    this.getOperations();
    this.getCurrentSentence();
  }

  getGroups() {
    console.log("getList", this.groupTypeId);
    this.usersService.getGroupList(this.groupTypeId).subscribe({
      next: (result) => {
        if (result == null) {
          this.alertComponent.showMessage('newworkflow_error', 'error', 5000);
        } else {
          result.forEach(item => {
            var groupItem = new SentenceItemDto();
            groupItem.id = item.id;
            groupItem.name = item.name;
            groupItem.mode = item.mode;
            /* if (sentenceItem.id != this.userServices.ALL_GROUP_ID) { */
            this.tipetsList.push(groupItem);
            //}
            //else {
            //  var seeAll: SentenceItemDto[] = [];
            //  sentenceItem.name = this.translateService.transform('All');
            //  seeAll.push(sentenceItem);
            //  this.tipetsList = seeAll.concat(this.tipetsList);
            //}
            for (let item of this.tipetsList) { if (item.id == this.usersService.ALL_GROUP_ID) { item.name = this.translateService.transform('All'); } }


          });
          console.log('Groups loaded.', this.tipetsList);
          this.filteredTipetsList = this.tipetsList;
          this.getUsers();
        }
      },
      error: (error) => {
        this.alertComponent.showMessage('newworkflow_error', 'error', 5000);
      }
    });
  }

  getUsers() {
    this.usersService.getUsersList(this.groupTypeId).subscribe(result => {
      if (result == null) {
        this.alertComponent.showMessage('newworkflow_error', 'error', 5000);
      } else {
        result.forEach(item => {
          var userItem = new SentenceItemDto();
          userItem.id = item.id;
          userItem.name = item.name;
          userItem.mode = item.mode;
          this.tipetsList.push(userItem);
        });
        console.log('User loaded.')
      }
    });
  }

  getOperations() {
    this.usersService.getOperationList().subscribe(result => {
      if (result == null) {
        this.alertComponent.showMessage('newworkflow_error', 'error', 5000);
      } else {
        this.operationList = [];
        result.forEach(item => {
          var sentenceItem = new SentenceItemDto();
          sentenceItem.id = item.id;
          sentenceItem.name = item.name;
          sentenceItem.mode = item.mode;
          this.operationList.push(sentenceItem);
        });
        console.log('User loaded.')
      }
    });
  }

  getCurrentSentence() {
    console.log("getCurrentSentence", this.workflowId);
    this.usersService.getCurrentSentence(this.workflowId, this.groupTypeId).subscribe({
      next: (result) => {
        if (result == null) {
          this.alertComponent.showMessage('newworkflow_error', 'error', 5000);
        }
        else {
          this.sentenceEmpty = false;
          this.sentenceList = result.sentence;
          if (this.sentenceList == null) this.sentenceList = [];
          for (let item of this.sentenceList) { if (item.id == this.usersService.ALL_GROUP_ID) { item.name = this.translateService.transform('All'); } }
          console.log('sentence loaded.', result.sentence);
        }
      },
      error: (error) => {
        this.Error = this.translateService.transform(error.error.errorCode);
      }
    });
  }

  checkSentenceEmpty(): void {
    if (this.sentenceList[0] != null) {
      this.sentenceEmpty = false;
    } else {
      this.sentenceEmpty = true;
    }
  }

  dropAndDelete(event: CdkDragDrop<SentenceItemDto[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    this.deleteList = []; // no es imprescindible, pero por no ir acumulando.
    this.checkSentenceEmpty();
  }

  drop(event: CdkDragDrop<SentenceItemDto[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      copyArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    this.checkSentenceEmpty();
  }

  deleteSentence() {
    this.sentenceList = [];
    this.sentenceEmpty = true;
  }

  getSentenceList() {
    return this.sentenceList;
  }

  getGroupType() {
    return this.groupTypeId;
  }

  searchTipets(event: any) {
    event.stopPropagation();

    var list = this.tipetsList;
    this.filteredTipetsList = list.filter(t => t.name.toLowerCase().includes(this.searchValue.toLowerCase()));
  }

  cleanSearchBox(event: any) {
    event.stopPropagation();

    this.searchValue = "";
    this.filteredTipetsList = this.tipetsList;
  }

  saveSentence(save: boolean) {
    this.results = [];
    this.Error = "";

    var sentence: SentenceRequest = new SentenceRequest();
    sentence.workflowId = this.workflowId;
    sentence.save = save;
    sentence.sentenceGroup.push(new SentenceGroup(this.sentenceList, this.groupTypeId));

    this.usersService.saveSentence(sentence).subscribe({
      next: (result) => {
        if (result[0].error != null) this.Error = this.translateService.transform(result[0].error);
        else this.results = result[0].users;
      },
      error: (error) => {
        this.Error = this.translateService.transform(error.error);
      }
    });

  }
}
