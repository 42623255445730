import { Component, Injectable, Inject, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../list-step/list.step.component';
import { StepDto } from '../../../models/StepDTO';
import { StepService } from '../../../services/step.service';
import { TextFormComponent } from './text-form/text-form.component';
import { NumericFormComponent } from './numeric-form/numeric-form.component';
import { ImageFormComponent } from './image-form/image-form.component';
import { CaptureFormComponent } from './capture-form/capture-form.component';
import { RecordVideoFormComponent } from './recordVideo-form/recordVideo-form.component';
import { MeterFormComponent } from './meter-form/meter-form.component';
import { QrCodeFormComponent } from './qrCode-form/qrCode-form.component';
import { ChecklistFormComponent } from './checklist-form/checklist-form.component';
import { QuestionFormComponent } from './question-form/question-form.component';
import { MultiAnswerFormComponent } from './multi-answer-form/multi-answer-form.component';
import { StepType } from '../../../models/StepTypes';
import { ThemePalette } from '@angular/material/core';
import { VideoFormComponent } from './video-form/video-form.component';
import { DateFormComponent } from './date-form/date-form.component';
import { PDFFormComponent } from './PDF-form/PDF-form.component';
import { TagFormComponent } from './tag-form/tag-form.component';


@Component({
  selector: 'wdm-step-form-component',
  styleUrls: ['../../../styles/components/steps.scss', '../../../styles/components/dialog.scss', './step-form.component.scss'],
  templateUrl: './step-form.component.html'
})
@Injectable({ providedIn: 'root' })


export class StepFormComponent implements OnInit {

  @ViewChild(TextFormComponent) textComponent: TextFormComponent;
  @ViewChild(ImageFormComponent) imageComponent: ImageFormComponent;
  @ViewChild(CaptureFormComponent) captureComponent: CaptureFormComponent;
  @ViewChild(NumericFormComponent) numericComponent: NumericFormComponent;
  @ViewChild(QrCodeFormComponent) qrComponent: QrCodeFormComponent;
  @ViewChild(MeterFormComponent) meterComponent: MeterFormComponent;
  @ViewChild(QuestionFormComponent) questionComponent: QuestionFormComponent;
  @ViewChild(MultiAnswerFormComponent) multianswerComponent: MultiAnswerFormComponent;
  @ViewChild(ChecklistFormComponent) checklistComponent: ChecklistFormComponent;
  @ViewChild(RecordVideoFormComponent) recordComponent: RecordVideoFormComponent;
  @ViewChild(VideoFormComponent) videoComponent: VideoFormComponent;
  @ViewChild(DateFormComponent) dateComponent: DateFormComponent;
  @ViewChild(PDFFormComponent) pdfFormComponent: PDFFormComponent;
  @ViewChild(TagFormComponent) tagFormComponent: TagFormComponent;

  @Output() deleteAnswer: EventEmitter<StepDto[]> = new EventEmitter<StepDto[]>();
  stepInfo: StepDto;
  stepType: typeof StepType = StepType;
  hasErrors = true;
  comboColor: ThemePalette = 'primary';

  constructor(
    public dialogRef: MatDialogRef<StepFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public stepService: StepService) {
    this.stepService.setWorkflowDto(data.workflowDTO);
  }


  ngOnInit() {
    this.copyStep();
  }


  validate() {
    if (this.stepInfo.stepTypeId == StepType.Text) this.textComponent.forceValidation();
    if (this.stepInfo.stepTypeId == StepType.Image) this.imageComponent.forceValidation();
    if (this.stepInfo.stepTypeId == StepType.Capture) this.captureComponent.forceValidation();
    if (this.stepInfo.stepTypeId == StepType.Numeric) this.numericComponent.forceValidation();
    if (this.stepInfo.stepTypeId == StepType.QrCode) this.qrComponent.forceValidation();
    if (this.stepInfo.stepTypeId == StepType.Meter) this.meterComponent.forceValidation();
    if (this.stepInfo.stepTypeId == StepType.FreeAnswer) this.questionComponent.forceValidation();
    if (this.stepInfo.stepTypeId == StepType.MultiAnswer || this.stepInfo.stepTypeId == StepType.BinaryQuestion) this.multianswerComponent.forceValidation();
    if (this.stepInfo.stepTypeId == StepType.Checklist) this.checklistComponent.forceValidation();
    if (this.stepInfo.stepTypeId == StepType.RecordVideo) this.recordComponent.forceValidation();
    if (this.stepInfo.stepTypeId == StepType.Video) this.videoComponent.forceValidation();
    if (this.stepInfo.stepTypeId == StepType.Date) this.dateComponent.forceValidation();
    if (this.stepInfo.stepTypeId == StepType.PDF) this.pdfFormComponent.forceValidation();
    if (this.stepInfo.stepTypeId == StepType.Tag) this.tagFormComponent.forceValidation();
  }

  copyStep(): void {
    this.stepInfo = {
      id: this.data.dialogStep.id,
      title: this.data.dialogStep.isNew && this.data.dialogStep.state == 'NEW' ? '' : this.data.dialogStep.title,
      state: this.data.dialogStep.state,
      description: this.data.dialogStep.description,
      content: this.data.dialogStep.content,
      fileList: this.data.dialogStep.fileList,
      stepTypeId: this.data.dialogStep.stepTypeId,
      parents: this.data.dialogStep.parents,
      links: this.data.dialogStep.links,
      stepId: this.data.dialogStep.stepId,
      isNew: this.data.dialogStep.isNew,
      skippable: this.data.dialogStep.skippable
    };
  }

  errors(hasErrors: boolean): void {
    this.hasErrors = hasErrors;
  }

  changeStepType() {
    this.stepInfo.state = 'CHANGE';
  }

  saveChanges(): void {
    if (this.stepInfo.stepTypeId == StepType.MultiAnswer || this.stepInfo.stepTypeId == StepType.BinaryQuestion) this.multianswerComponent.updateLinks(null);
    if (this.stepInfo.stepTypeId == StepType.Numeric) this.numericComponent.updateLinks(null);

    this.data.dialogStep.title = this.stepInfo.title;
    this.data.dialogStep.description = this.stepInfo.description;
    this.data.dialogStep.state = this.stepInfo.state;
    this.data.dialogStep.content = this.stepInfo.content;
    this.data.dialogStep.fileList = this.stepInfo.fileList;
    this.data.dialogStep.stepTypeId = this.stepInfo.stepTypeId;
    this.data.dialogStep.parents = this.stepInfo.parents;
    this.data.dialogStep.links = this.stepInfo.links;
    this.data.dialogStep.skippable = this.stepInfo.skippable;
    if (this.stepInfo.stepTypeId == StepType.MultiAnswer || this.stepInfo.stepTypeId == StepType.BinaryQuestion)
      this.data.dialogStep.skippable = false;

  }

  getShape(stepTypeId): string {
    return this.stepService.getStepShapeRoute(stepTypeId);
  }

  getName(stepTypeId): string {
    return this.stepService.getStepTypeName(stepTypeId);
  }

  isFirstStep() {
    var initStep = this.data.allSteps.find(s => s.stepTypeId == StepType.Init);
    return this.stepInfo.parents.includes(initStep.stepId);
  }

}
