import { Component, DoCheck, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core'
import { NumericContent, StepDto } from '../../../../models/StepDTO';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LinkFormComponent } from '../link-form/link-form.component';

@Component({
  selector: 'wdm-numeric-form',
  styleUrls: ['../../../../styles/components/steps.scss', 'numeric-form.component.scss'],
  templateUrl: 'numeric-form.component.html',
})

export class NumericFormComponent implements DoCheck {

  @Input() stepInfo: StepDto;
  @Input() allSteps: StepDto[];
  @Output() errors: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  content: NumericContent = new NumericContent();

  private sub: Subscription = new Subscription();
  @ViewChild('numericForm', { static: true }) private numericForm: NgForm;
  @ViewChild(LinkFormComponent) linkComponent: LinkFormComponent;

  constructor(private changeDetector: ChangeDetectorRef) { }


  ngOnInit() {
    if (this.stepInfo.content != null) {
      this.content = JSON.parse(this.stepInfo.content);
    }
  }
  
  ngDoCheck(): void {
    if (this.numericForm.status === 'INVALID' || (this.linkComponent != undefined && this.linkComponent.childs.status === 'INVALID')) {
      this.errors.emit(true);
    } else {
      this.errors.emit(false);
      this.stepInfo.state = "OK";
      if (this.stepInfo?.content !== null) {
        this.stepInfo.content = JSON.stringify(this.content);
      }
    }
  }

  ngAfterViewInit() {
    this.sub = this.linkComponent.answerInput.changes.subscribe(resp => {
      if (this.linkComponent.answerInput.length > 1) {
        this.linkComponent.answerInput.last.nativeElement.focus();
        this.changeDetector.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updateLinks(deletedStep: StepDto) {
    this.linkComponent.updateLinks(deletedStep);
  }

  forceValidation() {
    this.numericForm.controls.title.markAsTouched();
    this.numericForm.controls.description.markAsTouched();
    this.numericForm.controls.accuracy.markAsTouched();
    this.numericForm.controls.unitOfMeasure.markAsTouched();
    this.linkComponent.forceValidation();
  }
}
