<loading-component [loading]="!groupsList"></loading-component>
<loading-component [loading]="!usersList"></loading-component>

<!--<h3 class="groups-title">{{ 'groups_title' | translate }}</h3>-->

<div class="mat-elevation-z3">
  <div class="block-flat">
    <div class="groups">

      <div class="groups-area">

        <div class="group-template">

          <div class="input-group">
            <input type="text" class="form-control search-input" placeholder="{{ 'groups_search' | translate }}" [(ngModel)]="searchGroupValue" (keyup.enter)="searchGroup($event)">
            <button type="button" class="deletesearch cursor-pointer" title="{{ 'delete_search' | translate }}" (click)="cleanSearchBoxGroup($event)">
              <img src="/assets/img/delete-search.svg" class="delete-search-icon hvr-grow" alt="{{ 'delete_search' | translate }}">
            </button>
            <div class="input-group-append">
              <button type="button" class="btn btn-default" title="{{ 'groups_search' | translate }}" (click)="searchGroup($event)">
                <img src="/assets/img/search.svg" class="group-button hvr-grow" alt="{{ 'groups_search' | translate }}">
              </button>
            </div>
          </div>

          <button type="button" mat-flat-button color="primary" (click)='new($event)' class="new-group-button" [ngClass]="{tech : groupTypeId == groupTypeEnum.Technician, designer : groupTypeId == groupTypeEnum.Designer}"><i class="fa fa-users"></i> {{ 'groups_new' | translate }}</button>
          <p class="no-match" *ngIf="groupsList!=null && groupsList[0]==null">{{ 'no_match' | translate }}</p>
          <div class="group-item hvr-grow-shadow" *ngFor="let group of groupsList" (click)="changeCurrentGroup(group.id)" [ngClass]="{selected : groupSelected.id == group.id, tech : groupTypeId == groupTypeEnum.Technician, designer : groupTypeId == groupTypeEnum.Designer}">
            <h2> {{ group.name }} </h2>
          </div>

        </div>
      </div>

      <div class="user-list-area">

        <div class="groups-tools edit-mode" (click)='edit($event)' *ngIf="groupSelected.id != -1">
          <h3 [innerHTML]="groupSelected ? groupSelected.name : ''"></h3>
          <img src="/assets/img/navbar/edit-workflow.svg" class="group-button-edit hvr-grow" alt="{{ 'groups_edit' | translate }}" *ngIf="groupSelected.id != -1">
        </div>

        <div class="groups-tools" *ngIf="groupSelected.id == -1">
          <h3> {{ 'See_All' | translate }}</h3>
        </div>

        <div class="input-group">
          <input type="text" class="form-control search-input" placeholder="{{ (groupTypeId == groupTypeEnum.Technician ? 'technician_search' : 'designer_search') | translate }}" [(ngModel)]="searchUserValue" (keyup.enter)="searchUser($event)">
          <button type="button" class="deletesearch cursor-pointer" title="{{ 'delete_search' | translate }}" (click)="cleanSearchBoxUser($event)">
            <img src="/assets/img/delete-search.svg" class="delete-search-icon hvr-grow" alt="{{ 'delete_search' | translate }}">
          </button>
          <div class="input-group-append">
            <button type="button" class="btn btn-default" title="{{ (groupTypeId == groupTypeEnum.Technician ? 'technician_search' : 'designer_search') | translate }}" (click)="searchUser($event)">
              <img src="/assets/img/search.svg" class="group-button hvr-grow" alt="{{ (groupTypeId == groupTypeEnum.Technician ? 'technician_search' : 'designer_search') | translate }}">
            </button>
          </div>
        </div>

        <div class="users-placeholder">
          <p *ngIf="usersList != null && usersList[0]==null" class="no-match">{{ 'no_match' | translate }}</p>
          <div class="user-card-item" *ngFor="let user of usersList">

            <div class="user-card" (click)="showUserGroups($event, user)">
              <div *ngIf="groupTypeId == groupTypeEnum.Technician" class="avatar-img" style="background: url(../../../assets/img/groups/tech.svg) no-repeat "></div>
              <div *ngIf="groupTypeId == groupTypeEnum.Designer" class="avatar-img" style="background: url(../../../assets/img/groups/expert.svg) no-repeat"></div>
              <div class="name"> {{ user.name }} </div>

            </div>

          </div>

        </div>
      </div>

    </div>
  </div>
</div>

