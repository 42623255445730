import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })


export class ReviewerGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router) { }

  canActivate(): boolean {
    if (this.auth.isAuthenticated() && this.auth.IsReviewer())
      return true;

    this.router.navigate(['/'], { queryParamsHandling: "merge" });
    return false;
  }
}
