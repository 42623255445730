
<div class="with-close-button">
  <h2 mat-dialog-title>
    {{'image-zoom-info' | translate}}
  </h2>
  <div class="cancel-button">
    <button type="button" class="icon-btn deselected cancel-button" mat-button [mat-dialog-close]="'cancel'">
      <i class="fa fa-times"
         aria-hidden="true"></i>
    </button>
  </div>
</div>
<mat-dialog-content class="image">
  <div class="image-container"><img [src]="urlImage" alt="img" width="auto"  [attr.height]="imgHeight"></div>

</mat-dialog-content>

