export class FileDto {
  name: string;
  path: string;
  preview: string;
  file: File;

  constructor() {
    this.name = '';
    this.path = '';
    this.preview = '';
    this.file = null;
  }
}
