import { GroupTypeEnum } from "../Group";
import { SentenceItemDto } from "../SentenceItemDto";

export class SentenceResponse {
  sentence: SentenceItemDto[];
  workflowId: number;
}

export class SentenceRequest {
  sentenceGroup: SentenceGroup[] = [];
  workflowId: number;
  save: boolean;
}


export class SentenceGroup {
  sentence: SentenceItemDto[];
  groupTypeId: GroupTypeEnum;

  constructor(sentence: SentenceItemDto[], groupTypeId: GroupTypeEnum) {
    this.sentence = sentence;
    this.groupTypeId = groupTypeId;
  }
}
