import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';

import { LoginService } from '../../services/login.service';
import { AuthService } from '../../services/auth.service';
import { LoginResponse } from '../../models/Responses/LoginRespose';
import { Constants } from '../../Constants/Constants';

@Component({
  selector: 'login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']

})
export class LoginComponent {
  title = 'ProcessEye';
  hide = true;
  appName = "processEye";
  loading: boolean = false;
  logged: boolean = true;
  error: number = 0;
  parameters: any;

  constructor(private router: Router, private loginService: LoginService, private auth: AuthService, private routeAct: ActivatedRoute) {
    if (this.auth.isAuthenticated()) {
      if (this.auth.IsReviewer())
         this.router.navigate(['/workflowreport'], { queryParamsHandling: "preserve" });

      if (this.auth.IsCreator() || this.auth.IsTechManager() || this.auth.IsDesignerManager())
        this.router.navigate(['/dashboard'], { queryParamsHandling: "preserve" });
    }
  }

  ngOnInit() {
  }

  login = new FormControl('', [
    Validators.required,
  ]);

  password = new FormControl('', [
    Validators.required,
  ]);

  handleKeyUp(e) {
    if (e.keyCode === 13) {
      this.loginAccess()
    }
  }

  loginAccess() {
    if (this.login.valid && this.password.valid) {
      this.loading = true;
      this.loginService.getLogin(this.login.value, this.password.value).subscribe({
        next: (data: LoginResponse) => {
          this.error = data.result;

          if (data.result == 0) {

            console.log(data);
            localStorage.setItem(Constants.Token, data.token);
            localStorage.setItem(Constants.UserName, data.designerLoginName)
            localStorage.setItem(Constants.IsCreator, String(data.isCreator));
            localStorage.setItem(Constants.IsReviewer, String(data.isReviewer));
            localStorage.setItem(Constants.IsTechManager, String(data.isTechManager));
            localStorage.setItem(Constants.IsDesignerManager, String(data.isDesignerManager));
            this.auth.getLogExpirationDate();
            this.logged = true;
            if (this.auth.IsReviewer())
              this.router.navigate(['/workflowreport'], { queryParamsHandling: "preserve" });

            if (this.auth.IsCreator() || this.auth.IsTechManager() || this.auth.IsDesignerManager())
              this.router.navigate(['/dashboard'], { queryParamsHandling: "preserve" });
          } else {
            this.logged = false;
          }
          this.loading = false;
        },
        error: (err) => {
          console.log(err);
          this.loading = false;
        }
      });

    }
  }

}
