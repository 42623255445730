import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  
  constructor(private http: HttpClient) {
    console.log("base url: " + environment.apiBaseUrl);
  }
  getLogin(login: string, pass: string) {

    return this.http.post<any>(environment.apiBaseUrl + '/Account/Login',
      {
        'Login': login,
        'Password' : pass
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=UTF-8',
        })
      }
    )
  }
}
