import { Component, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Constants } from '../../Constants/Constants';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '../../services/translate.service';
import { ReportIssueComponent } from '../report-issue/report-issue.component';


@Component({
  selector: 'wdm-header-root',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
@Injectable()
export class HeaderComponent {
  appName = "ProcessEye";
  userName = "";
  isCreator: boolean;
  isReviewer: boolean;
  isTechManager: boolean;
  isDesignerManager: boolean;
  public dialogRef: MatDialogRef<ReportIssueComponent>;

  constructor(private router: Router, private translate: TranslateService, private auth: AuthService, public dialog: MatDialog) {

    this.userName = localStorage.getItem(Constants.UserName);
    this.isCreator = this.auth.IsCreator();
    this.isReviewer = this.auth.IsReviewer();
    this.isTechManager = this.auth.IsTechManager();
    this.isDesignerManager = this.auth.IsDesignerManager();
  }

  Logout() {
    localStorage.removeItem(Constants.Token);
    localStorage.removeItem(Constants.UserName);
    localStorage.removeItem(Constants.IsCreator);
    localStorage.removeItem(Constants.IsReviewer);
    localStorage.removeItem(Constants.IsTechManager);
    localStorage.removeItem(Constants.IsDesignerManager);
    this.router.navigate(['/'],{ queryParamsHandling: "merge" });
  }

  openReportIssue() {
    console.log('open report Issue');
    this.dialogRef = this.dialog.open(ReportIssueComponent, {
      width: 'auto',
    });
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }
}
