<form class="checklist-form" [formGroup]="checklistForm">
  <mat-form-field appearance="fill" class="step-form-input">
    <mat-label>{{ 'step_step_name' | translate }}</mat-label>
    <input matInput placeholder="{{ 'step_step_name' | translate }}" formControlName="title" required whitespace maxlength="55">
    <mat-error *ngIf="checklistForm.controls.title.invalid && checklistForm.controls.title.errors.required">{{ 'error_field_required' | translate }}</mat-error>
    <mat-error *ngIf="checklistForm.controls.title.invalid && checklistForm.controls.title.errors.maxlength">{{ 'error_max_55_chars' | translate }}</mat-error>
    <mat-error *ngIf="checklistForm.controls.title.invalid && checklistForm.controls.title.errors.whitespace && !checklistForm.controls.title.errors.required">{{ 'not_only_whitespaces' | translate }}</mat-error>
  </mat-form-field>

   <mat-form-field appearance="fill" class="step-form-input">
   <mat-label>{{ 'step_question' | translate }}</mat-label>
   <textarea matInput placeholder="{{ 'step_question' | translate }}" formControlName="description" name="description" required maxlength="180" whitespace style=" max-height: 70px"></textarea>
   <mat-error *ngIf="checklistForm.controls.description.invalid && checklistForm.controls.description.errors.required">{{ 'error_field_required' | translate }}</mat-error>
   <mat-error *ngIf="checklistForm.controls.description.invalid && checklistForm.controls.description.errors.maxlength">{{ 'error_max_180_chars' | translate }}</mat-error>
   <mat-error *ngIf="checklistForm.controls.description.invalid && checklistForm.controls.description.errors.whitespace && !checklistForm.controls.description.errors.required">{{ 'not_only_whitespaces' | translate }}</mat-error>
 </mat-form-field>

  <mat-label> {{ 'step_type_checklist' | translate }}: </mat-label>
  <button (click)="addItem(null)" type="button" class="icon-btn inline hvr-grow question-form-icon" title="{{ 'step_add_item' | translate }}" mat-button>
    <i class="fa fa-plus" aria-hidden="true"></i>
  </button>

  <p class="check-tip">{{ 'step_checklist_mandatory_tip' | translate }}</p>

  <div class="answers-scroll">
    <div *ngFor="let item of items.controls; let i = index;" class="answer-item" [formGroup]="items.controls[i]">
      <mat-form-field>
        <input matInput #listItem type="text" maxlength="150" formControlName="value" placeholder="{{ 'step_item' | translate }} {{i+1}}" />
        <mat-error *ngIf="items.controls[i].invalid && items.controls[i].get('value').hasError('maxlength')">{{ 'error_max_150_chars' | translate }}</mat-error>
        <mat-error *ngIf="items.controls[i].invalid && items.controls[i].get('value').hasError('whitespace')">{{ 'not_only_whitespaces' | translate }}</mat-error>
      </mat-form-field>

      <div class="checkbox-item">
        <mat-checkbox formControlName="isMandatory"></mat-checkbox>
      </div>

      <button *ngIf="items.controls.length > 1" (click)="removeItem(i)" type="button" class="question-form-icon icon-btn inline hvr-grow" title="{{ 'step_delete' | translate }}" mat-button>
        <i class="fa fa-close" aria-hidden="true"></i>
      </button>
    </div>

  </div>

</form>


