import { Component, Injectable } from '@angular/core';

@Component({
  selector: 'wdm-dashboard-component',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
@Injectable()
export class DashBoardComponent {

  viewAddWorkFlow = false;
  viewListWorkFlow = true;

  constructor() {
  }

  loadWorkFlow() {
    this.viewAddWorkFlow = true;
    this.viewListWorkFlow = false;
  }

  loadListWorkFlow() {
    this.viewAddWorkFlow = false;
    this.viewListWorkFlow = true;
  }

 

}
