
import { StepDto } from './StepDTO';

export class WorkflowDTO {
  id: number;
  name: string;
  description: string;
  stepItemsToDelete: Array<number>;
  stepItemsToEdit: Array<StepDto>;
  stepItemsToAdd: Array<StepDto>;
  company: string;
  location: string;
  plant: string;
  team: string;
  freeText: string;
  logo: string;
  file: string;
  fileInput: File;

  constructor() {
    this.id = 0;
    this.name = '';
    this.description = '';
    this.stepItemsToAdd = [];
    this.stepItemsToEdit = [];
    this.stepItemsToDelete = [];
    this.company = '';
    this.location = '';
    this.plant = '';
    this.team = '';
    this.freeText = '';
    this.logo = '';
    this.file = '';
    this.fileInput = null;
  }
}

export class StepOrder {
  id: number;
  order: number;
}
