import { Component, OnInit, DoCheck, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { StepDto } from '../../../../models/StepDTO';
import { NgForm } from '@angular/forms';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'wdm-date-form',
  styleUrls: ['../../../../styles/components/steps.scss', 'date-form.component.scss'],
  templateUrl: 'date-form.component.html',
})

export class DateFormComponent implements OnInit, DoCheck {

  @ViewChild('dateForm', { static: true }) private dateForm: NgForm;
  @Input() stepInfo: StepDto;
  @Output() errors: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  isRequired = false;
  isMaxSize = false;
  baseUrl = '';


  constructor() {
    if (!environment.production) {
      this.baseUrl = environment.apiBaseUrl;
    }
  }

  ngOnInit() {
  }

  ngDoCheck(): void {
    if (this.dateForm.status === 'INVALID') {
      this.errors.emit(true);
    } else {
      this.stepInfo.state = "OK";
      this.errors.emit(false);
    }
  }

  forceValidation() {
    this.dateForm.controls.title.markAsTouched();
    this.dateForm.controls.description.markAsTouched();
  }
   
}
