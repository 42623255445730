<div class="row diagram-area" style="margin: 0 15px;">

  <ejs-diagram #diagram
               id="diagram"
               width="100%"
               height="calc(100vh - 300px);"
               [layout]="layout"
               [snapSettings]="snapSettings"
               (click)="openStepDialogClick($event)">

  </ejs-diagram>
  <ejs-toolbar id='toolbar' (clicked)="zoomEvent($event)">
    <e-items>
      <e-item text="{{'zoom-in'|translate}}" id="zoomControlIn" tooltipText="{{ 'zoom-in' | translate}}" prefixIcon='fa fa-search-plus' class="mat-button"></e-item>
      <e-item type="Separator">
      </e-item>
      <e-item text="{{'zoom-out'|translate}}" id="zoomControlOut" tooltipText="{{ 'zoom-out' | translate}}" prefixIcon='fa fa-search-minus' class="mat-button"></e-item>
      <e-item type="Separator">
      </e-item>
      <e-item text="{{'zoom-reset' |translate}}" id='zoomControlReset' tooltipText="{{'reset' | translate}}" prefixIcon='fa fa-crosshairs' class="mat-button"></e-item>
      <e-item type="Separator">
      </e-item>
      <e-item text="{{'switch-orientation' |translate}}" id='switchOrientationControl' tooltipText="{{'switch-orientation' | translate}}" prefixIcon='fa fa-refresh' class="mat-button"></e-item>
      <e-item type="Separator">
      </e-item>
      <e-item *ngIf="!connectorColorMode" text="{{'show-connector-color' |translate}}" id='switchConnectorColorMode' prefixIcon='fa fa-long-arrow-right' class="mat-button"></e-item>
      <e-item *ngIf="connectorColorMode" text="{{'hide-connector-color' |translate}}" id='switchConnectorColorMode' prefixIcon='fa fa-long-arrow-right' class="mat-button connectorColor"></e-item>
      <e-item type="Separator">
      </e-item>
      <e-item text="{{'zoom-info' |translate}}" prefixIcon='fa fa-info'></e-item>
    </e-items>
  </ejs-toolbar>
</div>

<!--<div style="width:100%" *ngIf="!steps">
  <div style="height:10px">&nbsp;</div>
  <mat-spinner style="margin:0 auto;" diameter="75"></mat-spinner>
</div>-->
<!--[nodes]="nodes"
         [connectors]="connectors"-->
