<div class="modal-window">

  <h2 mat-dialog-title><i class="fa fa-envelope"></i> {{ 'ReportIssue' | translate }}</h2>

  <form class="image-form" #reportIssueForm="ngForm">

    <mat-dialog-content>

      <mat-form-field appearance="fill" class="step-form-input">
        <mat-label>{{ 'ReportIssue_Title' | translate }}</mat-label>
        <input matInput #title="ngModel" name="title" [(ngModel)]="issue.title" type="text" maxlength="50" placeholder="{{ 'ReportIssue_Summary' | translate }}" whitespace required>
        <mat-error *ngIf="title.invalid && title.errors.required">{{ 'error_field_required' | translate }}</mat-error>
        <mat-error *ngIf="title.invalid && title.errors.maxlength">{{ 'error_max_50_chars' | translate }}</mat-error>
        <mat-error *ngIf="title.invalid && title.errors.whitespace && !title.errors.required">{{ 'not_only_whitespaces' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="step-form-input">
        <mat-label>{{ 'ReportIssue_Email' | translate }}</mat-label>
        <input matInput #email="ngModel" name="email" type="email" [(ngModel)]="issue.email" placeholder="john.doe@mail.com" email whitespace required>
        <mat-error *ngIf="email.invalid && email.errors.required">{{ 'error_field_required' | translate }}</mat-error>
        <mat-error *ngIf="email.invalid && email.errors.email">{{'email_invalid' | translate}}</mat-error>
        <mat-error *ngIf="email.invalid && email.errors.whitespace && !email.errors.required">{{ 'not_only_whitespaces' | translate
          }}
        </mat-error>
      </mat-form-field>


      <mat-form-field appearance="fill" class="step-form-input">
        <mat-label>{{ 'ReportIssue_Description' | translate }}</mat-label>
        <textarea matInput placeholder="{{ 'ReportIssue_Detail' | translate }}"
                  #description="ngModel" name="description" [(ngModel)]="issue.description" maxlength="300" rows="3" required whitespace></textarea>
        <mat-error *ngIf="description.invalid && description.errors.required">{{ 'error_field_required' | translate }}</mat-error>
        <mat-error *ngIf="description.invalid && description.errors.maxlength">{{ 'error_max_300_chars' | translate }}</mat-error>
        <mat-error *ngIf="description.invalid && description.errors.whitespace && !description.errors.required">{{ 'not_only_whitespaces' | translate }}</mat-error>
      </mat-form-field>

      <div class="double">

        <mat-form-field appearance="fill" class="step-form-input">
          <mat-label>{{ 'ReportIssue_OS' | translate }}</mat-label>
          <input matInput placeholder="Windows 10, Windows 11, macOS 10.15, iPadOS 15.7, KaOS..."
                 #operatingSystem="ngModel" name="operatingSystem" [(ngModel)]="issue.operatingSystem" type="text" maxlength="50" whitespace>
          <mat-error *ngIf="operatingSystem.invalid && operatingSystem.errors.maxlength">{{ 'error_max_50_chars' | translate }}</mat-error>
          <mat-error *ngIf="operatingSystem.invalid && operatingSystem.errors.whitespace && !operatingSystem.errors.required">{{ 'not_only_whitespaces' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="step-form-input">
          <mat-label>{{ 'ReportIssue_Browser' | translate }}</mat-label>
          <input matInput placeholder="Chrome, Safari, Edge, Firefox..."
                 #browser="ngModel" name="browser" type="text" [(ngModel)]="issue.browser" whitespace>
          <mat-error *ngIf="browser.invalid && browser.errors.maxlength">{{ 'error_max_50_chars' | translate }}</mat-error>
          <mat-error *ngIf="browser.invalid && browser.errors.whitespace && !browser.errors.required">{{ 'not_only_whitespaces' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <wdm-file-input [multiple]="true" [inputFiles]="issuePreviewFiles"></wdm-file-input>

    </mat-dialog-content>

    <mat-dialog-content style="display: grid; grid-template-columns: 1fr auto; ">
      <div style="color: red; font-size: 14px; align-self:center">{{ sendError }}</div>
      <div>
        <button mat-button mat-dialog-close>{{'generic_text_cancel' | translate }}</button>
        <button mat-button (click)="onSubmit()" cdkFocusInitial>{{'generic_text_send' | translate }}</button>
      </div>
    </mat-dialog-content>
</form>
</div>
