<div class="row wd-header">
  <a class="logo" [routerLink]="['']">
    <!--<h1>{{appName}}</h1>-->
  </a>
  <nav class="navbar navbar-expand-sm navbar-light bg-light">
    <a *ngIf="userName" class="navbar-brand" href="#" style="cursor:default">{{ 'designer' | translate }}{{userName}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarItems" aria-controls="navbarItems" aria-expanded="true" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarItems">
      <ul class="navbar-nav mr-auto">
        <li *ngIf="isCreator" class="nav-item hvr-float" [routerLink]="['/dashboard']" [routerLinkActive]="['active']"
             title="{{ 'menu_workflows' | translate }}">
          <a class="nav-link">
            <img src="/assets/img/navbar/workflow-list.svg" class="workflow-list" />
          </a>
        </li>
        <li *ngIf="isReviewer" class="nav-item hvr-float" [routerLink]="['/workflowreport']" [routerLinkActive]="['active']"  title="{{ 'menu_reports' | translate }}">
          <a class="nav-link">
            <img src="/assets/img/navbar/report-list.svg" class="report-list" />
          </a>
        </li>
        <li *ngIf="isTechManager || isDesignerManager" class="nav-item hvr-float" [routerLink]="['/groups']" [routerLinkActive]="['active']" title="{{ 'menu_groups' | translate }}">
          <a class="nav-link">
            <img src="/assets/img/navbar/tech-group.svg" class="add-workflow" />
          </a>
        </li>
        <li *ngIf="isCreator" class="nav-item hvr-float" [routerLink]="['/dashboard/workflow']" [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{exact:true}" title="{{ 'menu_new' | translate }}">
          <a class="nav-link">
            <img src="/assets/img/navbar/add-workflow.svg" class="add-workflow" />
          </a>
        </li>
        <li class="nav-item hvr-float" title="{{ 'ReportIssue' | translate }}" (click)="openReportIssue()" >
          <a class="nav-link">
            <img src="/assets/img/navbar/send-error.svg" class="add-workflow" />
          </a>
        </li>
        <li class="nav-item hvr-float" title="{{ 'user_manual' | translate }}">
          <a class="nav-link" href="https://manual.processeye.wideum.com/" target="_blank">
            <img src="/assets/img/navbar/help.svg" class="add-workflow" />
          </a>
        </li>
        <li class="nav-item hvr-float">
          <a mat-button [matMenuTriggerFor]="configuration" class="config-button">
            <img src="/assets/img/navbar/config.svg" class="config-app" title="{{ 'menu_config' | translate }}" />
          </a>
          <mat-menu #configuration="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="languages">{{ 'menu_language' | translate }}</button>
          </mat-menu>
          <mat-menu #languages="matMenu">
            <button mat-menu-item (click)="setLang('en')">{{ 'menu_english' | translate }}</button>
            <button mat-menu-item (click)="setLang('es')">{{ 'menu_spanish' | translate }}</button>
            <button mat-menu-item (click)="setLang('fr')">{{ 'menu_french' | translate }}</button>
            <button mat-menu-item (click)="setLang('it')">{{ 'menu_italian' | translate }}</button>
            <button mat-menu-item (click)="setLang('pt')">{{ 'menu_portuguese' | translate }}</button>
            <button mat-menu-item (click)="setLang('cs')">{{ 'menu_czech' | translate }}</button>
          </mat-menu>
        </li>
      </ul>
    </div>
  </nav>
  <a class="nav-link log_out" (click)="Logout()">
    <img src="/assets/img/navbar/log-out.svg" class="log-out hvr-float " title="{{ 'menu_logout' | translate }}" />
  </a>
</div>
