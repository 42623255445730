import { Inject, Injectable, Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


//OPTIONS
//type: [INFO, QUESTION, ALERT]
//title: Free text
//message: Free text
//buttons: [YESNO, OK]


@Component({
  selector: 'alert-component',
  templateUrl: './alert.component.html',
  styleUrls: ['../../../styles/components/dialog.scss','./alert.component.scss']
})

@Injectable({ providedIn: 'root' })
export class AlertDialogComponent {


  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

  closeDialog() {
    this.dialogRef.close(false);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

}

export interface DialogData {
  title: string;
  type: string;
  message: string;
  buttons: string;
}
