import { Component, OnInit, DoCheck, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { StepDto } from '../../../../models/StepDTO';
import { NgForm} from '@angular/forms';
import { FileDto } from '../../../../models/FileDto';
import { FileInputComponent } from '../../../file-input/file-input.component';

@Component({
  selector: 'wdm-question-form',
  styleUrls: ['../../../../styles/components/steps.scss', 'question-form.component.scss'],
  templateUrl: 'question-form.component.html',
})

export class QuestionFormComponent implements OnInit, DoCheck {

  @ViewChild('questionForm', { static: true }) private questionForm: NgForm;
  @ViewChild('stepImage') img: ElementRef;
  @Input() stepInfo: StepDto;
  @Output() errors: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  imageFiles: FileDto[] = [];
  fileInputErrors: boolean;
  @ViewChild(FileInputComponent) fileInputComponent: FileInputComponent;
  isMaxSize = false;

  constructor() {
  }

  ngOnInit() {
    this.imageFiles = this.stepInfo.fileList;
  }

  ngDoCheck(): void {
    if (this.questionForm.status === 'INVALID' || this.fileInputErrors == true) {
      this.errors.emit(true);
    } else {
      this.stepInfo.state = "OK";
      this.errors.emit(false);
    }
  }

  forceValidation() {
    this.questionForm.controls.title.markAsTouched();
    this.questionForm.controls.description.markAsTouched();
  }

  onFileChanges(inputFiles) {
    this.stepInfo.fileList = this.imageFiles;
  }

  hasErrors(hasErrors: boolean): void {
    this.fileInputErrors = hasErrors;
  }
  
}
