import { Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { FileDto } from '../../models/FileDto';
import { StepDto } from '../../models/StepDTO';

@Component({
  selector: 'wdm-file-input',
  styleUrls: ['file-input.component.scss'],
  templateUrl: './file-input.component.html'
})

@Injectable()
export class FileInputComponent implements OnInit {

  @Input() multiple: boolean = false;
  @Input() required: boolean = false;
  @Input() fullSize: boolean = false;
  @Input() inputFiles: FileDto[] = [];
  @Input() stepInfo: StepDto[] = [];
  @Input() inputType: string = "image";
  @Output() errors: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() inputFilesChange = new EventEmitter<FileDto[]>;
  onDelete: boolean = false;
  completed = 0;
  buttonImage: string;
  files: any;

  defaultImageSrc = 'assets/img/IconStepType/image.svg';
  inputAccept: string = ".jpg, .jpeg, .png, .gif";
  rFilter = /^(?:image\/bmp|image\/cis\-cod|image\/gif|image\/ief|image\/jpeg|image\/jpeg|image\/jpeg|image\/pipeg|image\/png|image\/svg\+xml|image\/tiff|image\/x\-cmu\-raster|image\/x\-cmx|image\/x\-icon|image\/x\-portable\-anymap|image\/x\-portable\-bitmap|image\/x\-portable\-graymap|image\/x\-portable\-pixmap|image\/x\-rgb|image\/x\-xbitmap|image\/x\-xpixmap|image\/x\-xwindowdump)$/i;
  readonly maxImageSize = 2097152; /* 2MB */
  readonly maxFileSize = 104857600;  /* 100MB  No lo estoy usando OJO*/
  maxSize = this.maxFileSize;
  readonly maxFiles = 4;

  errorMessage = '';


  constructor() {

    //<wdm-file - input
    //    [multiple]=false / por defecto false
    //    [required] = false / por defecto false
    //    [fullSize] = false / por defecto false
    //    [inputType] = "pdf" / por defecto image, puede ser image - pdf - video
    //    [(inputFiles)] = "imageFiles"
    //    (inputFilesChange) = "onFileChanges($event)"
    //    (errors) = "hasErrors($event)" >
    //</wdm-file-input>

    //Si se usa en un paso hará falta iniciar la variable *files (En el ejemplo imageFiles) con los datos del step que tengamos:

    //ngOnInit() {
    //  this.fileInputService.setUpImageFilesInfo(this.stepInfo, this.imageFiles);
    //}

    // Y actualizar la información cuando se modifique:

    //onFileChanges(inputFiles) {
    //  this.fileInputService.updaFileInfo(this.stepInfo, this.imageFiles);
    //}

    //Para que no se pueda guardar el paso si el input tiene algún error añadiremos (|| this.fileInputErrors == true):

    //  ngDoCheck(): void {
    //    if( this.fileInputErrors == true || this.questionForm.status === 'INVALID' || (this.linkComponent != undefined && this.linkComponent.answersForm.status === 'INVALID'))
    // ...

    // para lo cual tendremos que crear la variable fileInputErrors y esta función:
    //hasErrors(hasErrors: boolean): void {
    //  this.fileInputErrors = hasErrors;
    //}

    //Por último si el input es de tipo REQUIRED además habrá que añadir esto al forceValidation:

    //if (this.stepInfo.fileList.length == 0) {
    //  this.fileInputComponent.showError();
    //}

  }

  ngOnInit() {
    if (this.inputType === "pdf") {
      this.inputAccept = ".pdf";
      this.rFilter = /^(?:application\/pdf)$/i;
    } else if (this.inputType === "video") {
      this.inputAccept = ".mp4";
      this.rFilter = /^(?:video\/mp4)$/i;
    } else {
      this.maxSize = this.maxImageSize;
    }

    this.buttonImage = 'assets/img/fileInput/add-' + this.inputType + '.svg';

    this.loadSavedImageFile(this.inputFiles);
  }

  ngAfterViewChecked() {
    if (this.inputType === "image") {
      document.getElementById('wdm-media-button').onclick = (args) => {
            var inputElement = this.selectCurrentInput();
            if (inputElement != null) {
              inputElement.click();
            }
          };
    } else {
      document.getElementById('wdm-file-button').onclick = (args) => {
        var inputElement = this.selectCurrentInput();
        if (inputElement != null) {
          inputElement.click();
        }
      };
    }
  }

  ngDoCheck(): void {
    if ((this.required && this.inputFiles.length <= 0) || (this.errorMessage != 'OK' && this.errorMessage != undefined && this.errorMessage != '' && this.inputFiles.length >= (this.maxFiles + 1))) {
      this.errors.emit(true);
    } else {
      this.errors.emit(false);
    }
  }

  imagePreview(files) {

    if (this.onDelete) {
      //En el guardado se ha vuelto a abrir la ventana y lanzar esta función
      //En este caso no queremos añadir archivos, queremos sustituirlos en caso de haber alguno seleccionado.
      this.onDelete = false;
      if (files.length != 0) {
        this.inputFiles = [];
      }
    }

    this.errorMessage = this.readImages(files);
  }

  /* //hacer en función del tipo y tamaño para que sirva para todo*/
  readImages(files: any): string {

    //validaciones
    if (files.length === 0) {
      return 'OK';
      //No hay imágenes, pero no es un error como tal, devuelvo ok
    }

    if (files.length + this.inputFiles.length >= (this.maxFiles + 1)) {
      return 'max_4_files';
    }

    for (let file of files) {
      var mimeType = file.type;
      if (mimeType.match(this.rFilter) == null) {
        return 'only_images';
      } else if (file.size >= this.maxSize) {
        return 'images_max_2MB';
      }
    }

    //leer imágenes
    var reader = new FileReader();
    if (!this.multiple) this.inputFiles = [];
    return this.recursiveReadFiles(this.inputFiles.length, 0, reader, files);  
  }

  recursiveReadFiles(previewIndex, fileIndex, reader, files): string {

    // fin de la recursión
    if (fileIndex >= files.length) {
      return "OK";
    }
    //leer imagen y guardarla
    var file = files[fileIndex];

    this.inputFiles[previewIndex] = new FileDto();
    this.inputFiles[previewIndex].name = file.name;
    this.inputFiles[previewIndex].file = file;

    reader.onloadstart = (event) => { this.completed = 0; console.log("onload start", this.completed); }
    reader.onloadend = (event) => { this.completed = -1; }
    reader.onprogress = (event) => { this.completed = (event.loaded / event.total) * 100; console.log("onload", this.completed); }  // EN teoría esto también lo puedo meter en las imágenes

    reader.onerror = (e) => {
      const error = reader.error;
      console.log("onerror", error.name, error.message);
      return 'reader error: ' + error;
    }

    var esto = this;
    reader.onload = function (e) {
      esto.inputFiles[previewIndex].preview = e.target.result;
      esto.inputFilesChange.emit(esto.inputFiles);
      esto.recursiveReadFiles((previewIndex + 1), (fileIndex + 1), reader, files);
    }

    reader.readAsDataURL(file);
  }

  async loadSavedImageFile(filesDto: FileDto[]): Promise<void> {
    filesDto.forEach(async fileDto => {
      console.log('LOAD IMAGES', fileDto);
      if (fileDto.file == null && fileDto.path != '') {
        var blob = await (await fetch(fileDto.path)).blob();
        var currentFile = new File([blob], fileDto.name);
        fileDto.file = currentFile;
      } 
    });

    this.inputFilesChange.emit(this.inputFiles);
  }

  selectCurrentInput(): HTMLElement {
    if (this.multiple) {
      return document.getElementById('wdm-multiple-input-file');
    } else {
      return document.getElementById('wdm-input-file');
    }
  }

  deleteFile(file: FileDto): void {
    if (this.inputFiles[1] == null && this.required) {
      this.onDelete = true;
      this.selectCurrentInput().click();
      return;
    }

    var indexnumber = this.inputFiles.indexOf(file);
    if (indexnumber != -1) {
      this.inputFiles.splice(indexnumber, 1);
      this.inputFilesChange.emit(this.inputFiles);
    }
  }

  public showError(): void {
    this.errorMessage = 'error_field_required';
  }

}



