import { Component, OnInit, DoCheck, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { StepDto } from '../../../../models/StepDTO';
import { NgForm } from '@angular/forms';
import { FileInputComponent } from '../../../file-input/file-input.component';
import { FileDto } from '../../../../models/FileDto';


@Component({
  selector: 'wdm-video-form',
  styleUrls: ['../../../../styles/components/steps.scss', 'video-form.component.scss'],
  templateUrl: 'video-form.component.html',
})

export class VideoFormComponent implements OnInit, DoCheck {

  @ViewChild('videoForm', { static: true }) private videoForm: NgForm;
  @Input() stepInfo: StepDto;
  @Output() errors: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  videoFiles: FileDto[] = [];
  fileInputErrors: boolean;
  @ViewChild(FileInputComponent) fileInputComponent: FileInputComponent;

  constructor() {
  }

  ngOnInit() { 
    this.videoFiles = this.stepInfo.fileList;
  }

  ngDoCheck(): void {
    if (this.videoForm.status === 'INVALID' || this.fileInputErrors == true) {
      this.errors.emit(true);
    } else {
      this.stepInfo.state = "OK";
      this.errors.emit(false);
    }
  }

  forceValidation() {
    this.videoForm.controls.title.markAsTouched();
    this.videoForm.controls.description.markAsTouched();
    if (this.stepInfo.fileList.length == 0) {
      this.fileInputComponent.showError();
    }
  }


  onFileChanges(inputFiles) {
    this.stepInfo.fileList = this.videoFiles;
  }

  hasErrors(hasErrors: boolean): void {
    this.fileInputErrors = hasErrors;
  }
  
}
