<div *ngIf="info.PhotoFileName != null" class="report-img-container">
  <img class="report-image" src='{{info.PhotoFileName}}' (click)="openImage()" />
</div>

<div class="text-info">
  <p *ngIf="info && info.ConditionList != null && info.ConditionList.length > 0">{{ 'validation_text' | translate }} {{info.ConditionList[0].Operation}} {{info.ConditionList[0].ValidationValue}}</p>
</div>
<hr />
<div class="text-info">
  <p *ngIf="info && info.ReadValue">
    <b>{{'report_reader_value' | translate}}: </b>{{info.ReadValue}}
    <img src="/assets/img/reports/validado.svg" *ngIf="info && info.ReadValueValidation == validationResult.VALID" class="validation-icon" alt="{{ 'validation_valid' | translate }}">
    <img src="/assets/img/reports/no_validado.svg" *ngIf="info && info.ReadValueValidation == validationResult.INVALID" class="validation-icon" alt="{{ 'validation_invalid' | translate }}">
  </p>
  <p *ngIf="info && info.UserReadValue">
    <b>{{'reader_manual_input' | translate}}: </b>{{info.UserReadValue}}
    <img src="/assets/img/reports/validado.svg" *ngIf="info && info.UserInputValueValidation == validationResult.VALID" class="validation-icon" alt="{{ 'validation_valid' | translate }}">
    <img src="/assets/img/reports/no_validado.svg" *ngIf="info && info.UserInputValueValidation == validationResult.INVALID" class="validation-icon" alt="{{ 'validation_invalid' | translate }}">
  </p>
</div>
