import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { JsonLoaderService } from './json-loader.service';


@Injectable({
  providedIn: 'root'
})
export class WhitelabelService {
  currentWhitelabel = '';
  jsonData: any = [];
  jsonSubscription: Subscription | null = null;
  currentWhitelabelAppName: string | undefined = 'Process eye';
  whitelabelList: Array<whitelabelDTO> = [];

  private currentWhitelabelSubject = new Subject<string>();
  currentWhitelabelObservable = this.currentWhitelabelSubject.asObservable();


  constructor(private jsonLoaderService: JsonLoaderService) { }

  initService(): void {
    const jsonUrl = '/assets/whitelabels/whitelabels.json';
    this.jsonSubscription = this.jsonLoaderService.getJsonData(jsonUrl)
      .subscribe({
        next: (data) => {
          this.jsonData = data;
          this.identifyWhitelabel();
        },
        error: (error) => {
          console.error('Error al leer el JSON:', error);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.jsonSubscription) {
      this.jsonSubscription.unsubscribe();
    }
  }

  identifyWhitelabel() {
    const url = this.getCurrentURL();
    let keys: string[] = url.split('.');
    let keysfake: string[] = 'cadena.separada.ratp.por.puntos.para.pruebas'.split('.');
    this.setVariablesValues(keys);
  }

  getCurrentURL() {
    return window.location.host;
  }

  getCurrentAppName() {
    return this.currentWhitelabelAppName;
  }

  getCurretWhitelabelName(): string {
    return this.currentWhitelabel;
  }

  changefavicon(name: string): void {
    let favIcon: HTMLLinkElement | null = document.querySelector('#favIcon');
    if (favIcon != null) favIcon.href = `assets/whitelabels/${name}/favicon.ico`;
  }

  setVariablesValues(keys: string[]): void {

    this.whitelabelList = <Array<whitelabelDTO>>this.jsonData;
    for (let i = 0; i < this.whitelabelList.length; i++) {
      if (keys.indexOf(this.whitelabelList[i].name) != -1) {
        this.currentWhitelabel = this.whitelabelList[i].name;
        if (this.whitelabelList[i].appName != null) { this.currentWhitelabelAppName = this.whitelabelList[i].appName };
        this.changefavicon(this.currentWhitelabel);
        this.whitelabelList[i].styles.forEach(data => {
          document.documentElement.style.setProperty(data.var, data.value);
        });

        this.currentWhitelabelSubject.next(this.currentWhitelabel);
        return;
      }
      else {
        console.log('El estilo no existe');
      }
    }
  }
}

export class styleDTO {
  var: string = '';
  value: string = '';
}

export class whitelabelDTO {
  name: string = '';
  appName?: string;
  styles: Array<styleDTO> = [];
}
