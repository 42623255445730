import { Component, DoCheck, Input, Output, EventEmitter, ViewChildren, QueryList, ElementRef, ChangeDetectorRef } from '@angular/core'
import { StepDto } from '../../../../models/StepDTO';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { whitespace } from '../../../../Validators/text.validator'
import { Subscription } from 'rxjs';

@Component({
  selector: 'wdm-checklist-form',
  styleUrls: ['../../../../styles/components/steps.scss', 'checklist-form.component.scss'],
  templateUrl: 'checklist-form.component.html',
})

export class ChecklistFormComponent implements DoCheck {

  @Input() stepInfo: StepDto;
  @Output() errors: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  checklistForm: FormGroup;
  items: FormArray;

  private sub: Subscription = new Subscription();
  @ViewChildren('listItem') listItem: QueryList<ElementRef>;

  constructor(private formBuilder: FormBuilder, private cd: ChangeDetectorRef) { }

  ngOnInit() {

    this.checklistForm = this.formBuilder.group({
      title: this.formBuilder.control(this.stepInfo.title, [Validators.required, Validators.maxLength(55), whitespace()]),
      description: this.formBuilder.control(this.stepInfo.description, [Validators.required, Validators.maxLength(180), whitespace()]),
      items: this.formBuilder.array([])
    });

    if (this.stepInfo.content != null && this.stepInfo.content.length > 0) {
      console.log(this.stepInfo.content);
      var checklist = JSON.parse(this.stepInfo.content);
      checklist.forEach(checkItem => {
        this.addItem(checkItem);
      });
    } else {     
      this.addItem(null);
    }
  }

  ngAfterViewInit() {
    this.sub = this.listItem.changes.subscribe(resp => {
      if (this.listItem.length > 1) {
        this.listItem.last.nativeElement.focus();
        this.cd.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  addItem(checkItem: CheckItem): void {
    if (checkItem == null) checkItem = { value: "", isMandatory: false };
    this.items = this.checklistForm.get('items') as FormArray;

    var text = this.formBuilder.control(checkItem.value, [Validators.required, Validators.maxLength(150), whitespace()]);
    var mandatory = this.formBuilder.control(checkItem.isMandatory);

    var formControl = this.formBuilder.group({ 'value': text, 'isMandatory': mandatory });
    this.items.push(formControl);
  }

  removeItem(index: number): void {
    if (this.items.length == 1) return;
    this.items.removeAt(index);
  }

  ngDoCheck(): void {

    if (this.items instanceof FormArray) {
      this.stepInfo.title = this.checklistForm.get("title").value;
      this.stepInfo.description = this.checklistForm.get("description").value;
      this.stepInfo.content = JSON.stringify(this.items.value);
    }

    if (this.checklistForm.status === 'INVALID') {
      this.errors.emit(true);
    } else {
      this.stepInfo.state = "OK";
      this.errors.emit(false);
    }
  }

  forceValidation() {
    this.checklistForm.controls.title.markAsTouched();
    this.checklistForm.controls.description.markAsTouched();
    this.items.controls.forEach(item => item.get('value').markAsTouched());
  }

}

interface CheckItem {
  value: string;
  isMandatory: boolean;
}
