import { Component, Input } from '@angular/core';

@Component({
  selector: 'wdm-video-report',
  templateUrl: 'video-report.component.html',
  styleUrls: ['video-report.component.scss']
})

export class VideoReportComponent {

  @Input() stepinfo: any;

  constructor() { }



}
