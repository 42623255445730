<ng-container class="answers-form" [formGroup]="answersForm">
  <mat-label *ngIf="this.stepInfo.stepTypeId == stepType.MultiAnswer || this.stepInfo.stepTypeId == stepType.BinaryQuestion">{{ 'step_answers' | translate }}: </mat-label>
  <button *ngIf="this.stepInfo.stepTypeId == stepType.MultiAnswer" (click)="addAnswerItem({ id: -2, text: '', stepTitle: '', order:0, isFinal :false });createSelectOptions();" type="button" class="icon-btn inline hvr-grow question-form-icon" title="{{'step_add_answer'| translate}}" mat-button>
    <i class="fa fa-plus" aria-hidden="true"></i>
  </button>

  <mat-label *ngIf="this.stepInfo.stepTypeId == stepType.Numeric">{{ 'step_range' | translate }} </mat-label>
  <button *ngIf="this.stepInfo.stepTypeId == stepType.Numeric" (click)="addRangeItem({ id: -2, text: null, stepTitle: '', order: 0, isFinal: false });createSelectOptions();" type="button" class="icon-btn inline hvr-grow question-form-icon" title="{{'step_add_answer'| translate}}" mat-button>
    <i class="fa fa-plus" aria-hidden="true"></i>
  </button>

  <div class="answers-scroll">
    <form *ngFor="let control of childs.controls; let i = index;" [formGroup]="childs.controls[i]">
      <div [ngClass]="this.stepInfo.stepTypeId == stepType.Numeric ? 'range-item' : 'answer-item'" [class.default-numeric-path]="this.stepInfo.stepTypeId == stepType.Numeric && control.get('sinceNumber') == undefined">
        <div *ngIf="this.stepInfo.stepTypeId == stepType.Numeric && control.get('sinceNumber') == undefined"><p>{{ 'default_path' | translate }}</p></div>
        <mat-form-field *ngIf="this.stepInfo.stepTypeId == stepType.MultiAnswer || this.stepInfo.stepTypeId == stepType.BinaryQuestion" appearance="fill" class="step-form-input">
          <mat-label>{{ 'step_answers' | translate }}</mat-label>
          <input matInput #answerInput formControlName="text" type="text" class="answer-text" maxlength="100" [readonly]="this.stepInfo.stepTypeId == stepType.BinaryQuestion" />
          <input matInput formControlName="order" type="text" class="answer-text" style="display:none" />
          <mat-error *ngIf="answersForm.invalid && control.hasError('required','text')">{{ 'error_field_required' | translate }}</mat-error>
          <mat-error *ngIf="answersForm.invalid && control.hasError('maxlength','text')">{{ 'error_max_100_chars' | translate }}</mat-error>
          <mat-error *ngIf="answersForm.invalid && control.hasError('whitespace','text')">{{ 'not_only_whitespaces' | translate }}</mat-error>
        </mat-form-field>

        <!-- number -->
        <mat-form-field *ngIf="this.stepInfo.stepTypeId == stepType.Numeric && control.get('operation') != undefined" appearance="fill" class="step-form-input">
          <mat-select formControlName="operation" hideSingleSelectionIndicator>
            <mat-option *ngFor="let numberOp of numberOperations" [value]="numberOp">{{numberOp}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="step-form-input" *ngIf="control.get('sinceNumber') != undefined">
          <mat-label *ngIf="control.get('operation').value != '-'">{{ 'step_value' | translate }}</mat-label>
          <mat-label *ngIf="control.get('operation').value == '-'">{{ 'step_since_value' | translate }}</mat-label>
          <input matInput #sinceNumber formControlName="sinceNumber" type="number" class="answer-text" />
          <input matInput formControlName="order" type="text" class="answer-text" style="display:none" />
          <mat-error *ngIf="answersForm.invalid && control.hasError('required','sinceNumber')">{{ 'error_field_required' | translate }}</mat-error>
          <mat-error *ngIf="answersForm.invalid && control.hasError('min', 'sinceNumber')">{{ 'min_value_error' | translate }}</mat-error>
          <mat-error *ngIf="answersForm.invalid && control.hasError('max', 'sinceNumber')">{{ 'max_value_error' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="step-form-input" *ngIf="control.get('forNumber') != undefined">
          <mat-label>{{ 'step_for_value' | translate }}</mat-label>
          <input matInput #forNumber formControlName="forNumber" type="number" class="answer-text" />
          <mat-error *ngIf="answersForm.invalid && control.hasError('required','forNumber')">{{ 'error_field_required' | translate }}</mat-error>
          <mat-error *ngIf="answersForm.invalid && control.hasError('min', 'forNumber')">{{ 'min_value_error' | translate }}</mat-error>
          <mat-error *ngIf="answersForm.invalid && control.hasError('max', 'forNumber')">{{ 'max_value_error' | translate }}</mat-error>
        </mat-form-field>
        <!-- number -->

        <div [class.inline-input]="this.stepInfo.stepTypeId == stepType.MultiAnswer || this.stepInfo.stepTypeId == stepType.BinaryQuestion  || this.stepInfo.stepTypeId == stepType.Numeric">
          <ejs-dropdownlist id='option'
                            #option
                            [dataSource]='childsOptions'
                            formControlName="option"
                            [fields]='fields'
                            [allowFiltering]='true'
                            [ignoreAccent]='true'
                            filterBarPlaceholder="{{ 'search' | translate }}"
                            placeholder="{{ 'child_target' | translate }}*"
                            (beforeOpen)="onMatSelectOpen(answersForm);"
                            (close)='onLinkChange($event, i)'>

            <ng-template *ngIf="childsOptions[0].stepTypeIconUrl != undefined" #itemTemplate let-data>
              <img [src]="data.stepTypeIconUrl" class="step-icon" /> - <span class='city'>{{data.childText}}</span>
            </ng-template>
            <ng-template *ngIf="childsOptions[0].stepTypeIconUrl != undefined" #valueTemplate let-data>
              <div>
                <img [src]="data.stepTypeIconUrl" class="step-icon" /> - <span class='city'>{{data.childText}}</span>
              </div>
            </ng-template>
          </ejs-dropdownlist>
        </div>

        <button *ngIf="(childs.controls.length > 1 && this.stepInfo.stepTypeId == stepType.MultiAnswer) || (this.stepInfo.stepTypeId == stepType.Numeric && control.get('sinceNumber') != undefined)" (click)="removeItem(i)" type="button" class="question-form-icon icon-btn inline hvr-grow " title="{{ 'step_delete' | translate }}" mat-button>
          <i class="fa fa-close" aria-hidden="true"></i>
        </button>
      </div>
    </form>
  </div>
</ng-container>
