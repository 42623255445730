import { Component } from '@angular/core';
import { WhitelabelService } from './services/whitelabel.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: []

})

export class AppComponent {
  appName = 'Process eye';

    constructor(private whitelabelService: WhitelabelService) {
    }
  
  ngOnInit() {
    this.whitelabelService.initService();
    // Suscribirse a los cambios en el servicio WhitelabelService
    this.whitelabelService.currentWhitelabelObservable.subscribe((whitelabel) => {
      if (whitelabel != '') {
        this.appName = this.whitelabelService.getCurrentAppName();
      }
    });
  }

}

