import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  data: any = {}
  selectedLang: string;
  @Output() public LanguageChanged: EventEmitter<string> = new EventEmitter();

  constructor(private http: HttpClient) { }

  use(lang: string): Promise<{}> {
    this.selectedLang = lang;
    localStorage.setItem('language', lang);
    return new Promise<{}>((resolve, reject) => {
      const langPath = `assets/i18n/${lang || 'en'}.json`;

      this.http.get<{}>(langPath).subscribe(
        translation => {
          this.data = Object.assign({}, translation || {});
          this.LanguageChanged.emit(this.selectedLang);
          resolve(this.data);
        },
        error => {
          this.data = {};
          resolve(this.data);
        }
      );
    });
  }

  getLang() {
    return this.selectedLang;
  }
}

