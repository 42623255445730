export enum StepType {
  New = -1,
  Init = 0,
  Text = 1,
  Image = 2,
  Capture = 3,
  Numeric = 4,
  QrCode = 6,
  Meter = 7,
  BinaryQuestion = 8,
  MultiAnswer = 9,
  FreeAnswer = 10,
  Checklist = 11,
  RecordVideo = 12,
  End = 13,
  Video = 14,
  Date = 15,
  PDF = 16,
  Tag = 17
}
