
<div class="modal-window">

  <h2 mat-dialog-title>
    <i class="fa fa-group"></i> {{'config_group' | translate }} {{data.group.name}} <span id="groupContactsError" class="contact-group-error">{{ error }}</span>

  </h2>

  <mat-dialog-content>
    <div>
      <!--<label></label>
      <input class="text-box single-line modal-group-name" placeholder="{{ group.name }}" [(ngModel)]="group.name" name="groupName" type="text">-->
      <mat-form-field class="group-name-input">
        <mat-label>{{'group_name' | translate }}</mat-label>
        <input matInput [(ngModel)]="group.name" name="groupName" type="text" >
      </mat-form-field>


    </div>
    <div class="group-widget">
      <div>
        <label class="group-widget-labels">{{'tech_list' | translate }}</label>
        <div class="list-component-container" id="listComponentPlaceholder">

          <details open *ngFor="let elem of allList">
            <summary>{{ elem.initial }}</summary>

            <div class="list_component-details" *ngFor="let item of elem.list">
              <span class="group-item tech-item hvr-grow-shadow" [ngClass]="{selected : item.selected }" (click)="toggleContact(item)">
                <i class="fa fa-group" *ngIf="item.mode != 'technician'"></i> {{ item.name }}
              </span>
            </div>
          </details>
        </div>
      </div>
      <div class="overflow_hidden">
        <label class="group-widget-labels">{{'group_members' | translate }}</label>
        <div class="group-to-edit-placeholder">
          <span *ngFor="let user of groupUsersList" class="group-item group-selected-item hvr-grow-shadow" (click)="toggleContact(user)">{{ user.name }}</span>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button type="button" mat-button [mat-dialog-close]="'cancel'"> {{ 'generic_text_cancel' | translate }}</button>
    <button type="button" mat-button id="deleteGroupButton" (click)="deleteGroup()">{{ 'generic_text_delete' | translate }}</button>
    <button type="button" mat-button (click)="saveGroup($event)"> {{ 'generic_text_save' | translate }}</button>
  </mat-dialog-actions>

</div>
