
<mat-dialog-content class="allowed-users-content">
  <p>
    {{'sentence-intro' | translate}}
  </p>
  <div class="container-wd sentencegrid">
    <div cdkDropList
         #SentenceList="cdkDropList"
         cdkDropListOrientation="horizontal"
         class="sentence-area sentence-list"
         [cdkDropListData]="sentenceList"
         [cdkDropListConnectedTo]="[DeleteList]"
         (cdkDropListDropped)="drop($event)">
      <p class="sentence-info" [class.displayInfo]="sentenceEmpty">
        {{'sentence_info' | translate}}
      </p>
      <div class="sentence-box {{ word.mode == 'user' ? (groupTypeEnum[this.groupTypeId] | lowercase) : word.mode }}"
           *ngFor="let word of sentenceList"
           cdkDrag>
        <p>{{word.name}}</p>
      </div>
    </div>

    <div class="delete-area" (click)="deleteSentence()">
      <div cdkDropList
           #DeleteList="cdkDropList"
           cdkDropListOrientation="horizontal"
           class="delete"
           [cdkDropListData]="deleteList"
           (cdkDropListDropped)="dropAndDelete($event)">
        <div class="trash"
             title="{{'delete_info' | translate }}"></div>
      </div>
    </div>

    <div class="data-area">
      <div cdkDropList
           #OperationList="cdkDropList"
           cdkDropListOrientation="horizontal"
           class="sentence-list"
           cdkDropListSortingDisabled
           [cdkDropListData]="operationList"
           [cdkDropListConnectedTo]="[SentenceList]"
           (cdkDropListDropped)="drop($event)">
        <div class="sentence-box operation-box {{ word.mode == 'user' ? (groupTypeEnum[this.groupTypeId] | lowercase) : word.mode }}"
             *ngFor="let word of operationList"
             cdkDrag>
          <p>{{word.name}}</p>
        </div>
      </div>
      <div class="search">
        <div class="input-group">
          <input type="text" class="form-control search-input" placeholder="{{ 'search' | translate }}" [(ngModel)]="searchValue" (keyup.enter)="searchTipets($event)">
          <button type="button" class="deletesearch cursor-pointer" title="{{ 'delete_search' | translate }}" (click)="cleanSearchBox($event)">
            <img src="/assets/img/delete-search.svg" class="delete-search-icon hvr-grow" alt="{{ 'delete_search' | translate }}">
          </button>
          <div class="input-group-append">
            <button type="button" class="btn btn-default" title="{{ 'groups_search' | translate }}" (click)="searchTipets($event)">
              <img src="/assets/img/search.svg" class="group-button hvr-grow" alt="{{ 'groups_search' | translate }}">
            </button>
          </div>
        </div>
      </div>
      <div class="groups-list">
        <div cdkDropList
             #TipetsList="cdkDropList"
             class="sentence-list users-list"
             [cdkDropListData]="filteredTipetsList"
             cdkDropListSortingDisabled
             [cdkDropListConnectedTo]="[SentenceList]"
             (cdkDropListDropped)="drop($event)">
          <div class="sentence-box operation-box {{ word.mode == 'user' ? (groupTypeEnum[this.groupTypeId] | lowercase) : word.mode }}"
               *ngFor="let word of filteredTipetsList"
               cdkDrag>
            <p>{{word.name}}</p>
          </div>
          <p *ngIf="filteredTipetsList[0]==null" class="no-match">{{ 'no_match' | translate }}</p>
        </div>
      </div>
    </div>

    <div class="results-area {{ groupTypeEnum[this.groupTypeId] | lowercase }}">
      <button mat-raised-button mat-flat-button color="primary" class="{{ groupTypeEnum[this.groupTypeId] | lowercase }}" cdkFocusInitial (click)="saveSentence(false)">{{'check_sentence' | translate }}</button>
      <div *ngIf="Error!=''" class="sentence-results error">{{Error}}</div>
      <ul *ngIf="results[0]!=null"><li class="sentence-results" *ngFor="let result of results">{{result}}</li></ul>
    </div>
  </div>
</mat-dialog-content>

<!--<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'cancel' | translate }}</button>
  <button mat-button cdkFocusInitial (click)="saveSentence(true)">{{'save' | translate }}</button>
</mat-dialog-actions>-->
