
<div class="file-input-with-message" [class.single]="!multiple">

  <div class="input-file-area"
       *ngIf="inputType === 'image'"
       [class.single]="!multiple"
       [class.fullSize]="fullSize">

    <div class="media-button" id="wdm-media-button"
         [class.error]="errorMessage != 'OK' && errorMessage != undefined && errorMessage != ''"
         [class.invisible]="!multiple && inputFiles && inputFiles.length != 0">
      <img [src]=buttonImage
           alt="{{'add-image' | translate}}"
           class="uploadPreview-img hvr-grow" />
      <p>{{'add-image' | translate}}</p>
    </div>

    <div *ngFor="let file of inputFiles" class="preview-images" title="{{file.name}}">
      <div class="uploadPreview-hover" (click)="deleteFile(file)">
        <i *ngIf="multiple || !required" class="fa fa-trash-o" aria-hidden="true"></i>
      </div>
      <img [src]="file.preview || defaultImageSrc" alt="image" class="uploadPreview-img" />
    </div>

  </div>

  <div class="media-button pdf"
       *ngIf="inputType !== 'image'"
       [class.error]="errorMessage != 'OK' && errorMessage != undefined && errorMessage != ''">
    <div class="preview-pdf">
      <div class="input-pdf" id="wdm-file-button">
        <img [src]=buttonImage
             alt="{{'add-image' | translate}}"
             class="icon-img" />
        <p *ngIf="inputFiles && inputFiles.length != 0">{{inputFiles[0].name}}</p>
        <p *ngIf="!inputFiles || inputFiles.length == 0">{{'add-image' | translate}}</p>
      </div>
      <!--<i *ngIf="inputFiles && inputFiles.length != 0" class="fa fa-times hvr-grow" aria-hidden="true" title="change file" (click)="deleteFile(inputFiles[0])"></i>-->
    </div>
    <div *ngIf="completed > 0" style="margin-bottom: 15px">
      <mat-progress-bar mode="determinate" [value]="completed"></mat-progress-bar>
    </div>
  </div>


  <input *ngIf="multiple"
         #multipleimagefiles
         type="file"
         id="wdm-multiple-input-file"
         class="input-file"
         accept="image/*"
         (change)="imagePreview(multipleimagefiles.files)"
         multiple />

  <input *ngIf="!multiple"
         #imagefiles
         type="file"
         id="wdm-input-file"
         class="input-file"
         [accept]=inputAccept
         (change)="imagePreview(imagefiles.files)" />

</div>
  



<p class="file-error" *ngIf="errorMessage != 'OK'" [class.single]="!multiple && !fullSize">{{ errorMessage | translate }}</p>
<!--if mandatory no se muestra la parte de borrar-->
