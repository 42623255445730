<div class="modal-window">
  <h2 mat-dialog-title>{{ 'newworkflow_additional_info' | translate }}</h2>
  <mat-dialog-content class="metadata-content">
    <div class="container-wd">
      <form class="text-form metadata-form" #metadataForm="ngForm">
        <div class="metadata-content-texts">
          <mat-form-field appearance="fill" class="step-form-input double">
            <mat-label>{{ 'newworkflow_additional_info_company' | translate }}</mat-label>
            <input matInput #company="ngModel" name="company" [(ngModel)]="workflowCopy.company" maxlength="150" whitespace>
            <mat-error *ngIf="company.invalid && company.errors.maxlength">{{ 'error_max_150_chars' | translate }}</mat-error>
            <mat-error *ngIf="company.invalid && company.errors.whitespace">{{ 'not_only_whitespaces' | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="step-form-input double">
            <mat-label>{{ 'newworkflow_additional_info_location' | translate }}</mat-label>
            <input matInput #location="ngModel" name="location" [(ngModel)]="workflowCopy.location" maxlength="150" whitespace>
            <mat-error *ngIf="location.invalid && location.errors.maxlength">{{ 'error_max_150_chars' | translate }}</mat-error>
            <mat-error *ngIf="location.invalid && location.errors.whitespace">{{ 'not_only_whitespaces' | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="step-form-input">
            <mat-label>{{ 'newworkflow_additional_info_plant' | translate }}</mat-label>
            <input matInput #plant="ngModel" name="plant" [(ngModel)]="workflowCopy.plant" maxlength="150" whitespace>
            <mat-error *ngIf="plant.invalid && plant.errors.maxlength">{{ 'error_max_150_chars' | translate }}</mat-error>
            <mat-error *ngIf="plant.invalid && plant.errors.whitespace">{{ 'not_only_whitespaces' | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="step-form-input">
            <mat-label>{{ 'newworkflow_additional_info_team' | translate }}</mat-label>
            <input matInput #team="ngModel" name="team" [(ngModel)]="workflowCopy.team" maxlength="150" whitespace>
            <mat-error *ngIf="team.invalid && team.errors.maxlength">{{ 'error_max_150_chars' | translate }}</mat-error>
            <mat-error *ngIf="team.invalid && team.errors.whitespace">{{ 'not_only_whitespaces' | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="step-form-input double">
            <mat-label>{{ 'newworkflow_additional_info_free_text' | translate }}</mat-label>
            <input matInput #freeText="ngModel" name="freeText" [(ngModel)]="workflowCopy.freeText" maxlength="150" whitespace>
            <mat-error *ngIf="freeText.invalid && freeText.errors.maxlength">{{ 'error_max_150_chars' | translate }}</mat-error>
            <mat-error *ngIf="freeText.invalid && freeText.errors.whitespace">{{ 'not_only_whitespaces' | translate }}</mat-error>
          </mat-form-field>
        </div>

        <div class="form-column-preview">
          <wdm-file-input [(inputFiles)]="imageFiles" (inputFilesChange)="onFileChanges($event)"></wdm-file-input>
        </div>
      </form>
 </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="'cancel'">{{'generic_text_cancel' | translate}}</button>
    <button mat-button [mat-dialog-close]="true" (click)="onSubmit()" cdkFocusInitial>{{'generic_text_ok' | translate}}</button>
  </mat-dialog-actions>
</div>
