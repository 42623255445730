import { Component, OnInit, Injectable, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from '../../../services/users.service';
import { Group } from '../../../models/Group';
import { User } from '../../../models/User';
import { TranslatePipe } from '../../../pipes/translate.pipe';

@Injectable({ providedIn: 'root' })
@Component({
  selector: 'wdm-dialog-group-edit',
  templateUrl: 'dialog-edit.component.html',
  styleUrls: ['dialog-edit.component.scss', '../../../styles/components/dialog.scss'],
  providers: [TranslatePipe]
})

export class DialogGroupEditComponent implements OnInit {

  allList: any[];
  groupUsersList: User[];
  group: Group;
  itemsList: any[];
  error: string;

  constructor(public dialogRef: MatDialogRef<DialogGroupEditComponent>, @Inject(MAT_DIALOG_DATA) public data: { group: Group, groupsList: Group[] }, private userService: UsersService,
    private translateService: TranslatePipe) {
    this.group = {
      id: this.data.group.id,
      name: this.data.group.name,
      active: this.data.group.active,
      groupTypeId: this.data.group.groupTypeId
    };

    this.itemsList = [];
    this.data.groupsList.forEach(gr => this.itemsList.push(gr));
  }

  ngOnInit() {
    console.log('data', this.group);
    this.groupUsersList = [];
    if (this.group.id > 0) this.loadGroup();
    else this.loadList();
  }

  loadGroup(): void {
    this.userService.getGroup(this.group.id, this.group.groupTypeId).subscribe({
      next: (data: any) => {
        if (data != null) {
          this.groupUsersList = data;
          this.loadList();
        }
      },
      error: (err) => console.log(err)
    });

  }

  loadList(): void {
    this.allList = [];

    this.userService.getUsersList(this.group.groupTypeId).subscribe({
      next: (data: any) => {
        if (data != null) {
          data.forEach(user => this.itemsList.push(user));

          var sortedList = this.sortList(this.itemsList);

          var initial = '';
          var index = -1;
          for (var i = 0; i < sortedList.length; i++) {
            if (sortedList[i].id == this.group.id) continue;

            var currentInitial = sortedList[i].name.charAt(0).toUpperCase();
            if (currentInitial !== initial) {
              index++;
              initial = currentInitial;
              this.allList.push({ 'initial': initial, 'list': [] });
            }

            if (sortedList[i].mode == 'user') sortedList[i].selected = this.isUserInGroup(sortedList[i].id);

            this.allList[index].list.push(sortedList[i]);
          }
          console.log("**", this.allList);
        }
      },
      error: (err) => console.log(err)
    });
  }

  sortList(toOrder) {
    var sortedList = toOrder.sort(function (a, b) {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    return sortedList;
  }

  isUserInGroup(userId) {
    return this.groupUsersList.find(t => t.id == userId) != undefined;
  }

  toggleContact(itemSelected) {
    if (itemSelected.mode != undefined && itemSelected.mode.includes("group")) {
      this.toggleGroup(itemSelected);
      return;
    }

    var index = this.groupUsersList.findIndex(t => t.id == itemSelected.id);
    if (index > -1) this.groupUsersList.splice(index, 1);
    else this.groupUsersList.push(itemSelected);

    this.allList.find(item => {
      var user = item.list.find(u => u.id == itemSelected.id && u.mode == 'user'); 
      if (user != undefined) user.selected = !user.selected;
    });
  }

  toggleGroup(groupSelected) {
    this.userService.getGroup(groupSelected.id, this.group.groupTypeId).subscribe({
      next: (data: any) => {
        if (data != null) {
          for (var user of data) {
            var index = this.groupUsersList.findIndex(t => t.id == user.id);
            if (index == -1) this.groupUsersList.push(user);

            this.allList.find(item => {
              var userInList = item.list.find(u => u.id == user.id && u.mode == 'user'); 
              if (userInList != undefined && !userInList.selected) userInList.selected = !userInList.selected;
            });
          }

        }
      },
     error: (err) => console.log(err)
    });
  }

  saveGroup(event:any) {
    event.stopPropagation();

    if (this.group.name == undefined || this.group.name.trim().length == 0) {
      this.error = this.translateService.transform('group_name_required');
      return;
    }

    var userIds = [];
    this.groupUsersList.forEach(function (element) {
      userIds.push(element.id);
    });

    console.log("Save Group", this.group);

    this.userService.saveGroup(this.group, userIds).subscribe({
      next: (data: any) => {
        console.log("data", data);
        this.dialogRef.close(data);
      },
      error: (err) => {
        console.log(err);
        var errorText = err.error; 
        if (err.error.groupId != undefined) {
          this.group.id = err.error.groupId;
          errorText = err.error.message;
        }
        this.error = this.translateService.transform(errorText);
      }
    });
  }

  deleteGroup() {
    this.userService.deleteGroup(this.group.id).subscribe({
      next: (data: any) => {
        console.log("data", data);
        this.dialogRef.close(-1);
      },
      error: (err) => {
        console.log(err);
        this.error = this.translateService.transform(err.error);
      }
    });
  }
}

