<div class="modal-window">

  <h2 mat-dialog-title><i class="fa fa-group"></i>{{ 'groups_of' | translate }} {{ user.name }}</h2>

  <mat-dialog-content>
      <div class="group-to-edit-placeholder">
        <mat-chip-listbox aria-label="Groups">
          <mat-chip *ngFor="let group of userGroupsList" (removed)="remove(group)" class="mat-chip-color">
            <i class="fa fa-group"></i> {{group.groupName}} 
            <button matChipRemove [attr.aria-label]="'remove ' + group"  title="{{'remove_of' | translate }}  {{group.groupName}}">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-listbox>
      </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{'cancel' | translate }}</button>
    <button mat-button [mat-dialog-close]="true" (click)="saveUserGroups()" cdkFocusInitial>{{'save' | translate }}</button>
  </mat-dialog-actions>
</div>
