/*modules*/
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MaterialDesignModule } from './material/material-design.module';
import { MatChipsModule } from '@angular/material/chips';

//import { DragDropModule } from '@angular/cdk/drag-drop';

import { JwtHelperService } from '@auth0/angular-jwt';

/*services*/
import { WorkFlowService } from '../app/services/workflow.service';
import { StepService } from '../app/services/step.service';
import { AuthService } from '../app/services/auth.service';
import { JwtInterceptor } from '../app/services/jwt.interceptor';
import { AuthGuardService as AuthGuard } from '../app/services/auth-guard.service';
import { TranslateService } from '../app/services/translate.service';
import { WorkFlowReportService } from '../app/services/workflowreport.service';
import { ColorService } from '../app/services/color.service';
import { FilterService } from '../app/services/filter.service';
import { WhitelabelService } from '../app/services/whitelabel.service';
import { ReportIssueService } from '../app/services/reportIssue.service';

/*components*/
import { HeaderComponent } from '../app/components/header/header.component';
import { LoginComponent } from '../app/components/login/login.component';
import { DashBoardComponent } from '../app/components/dashboard/dashboard.component';
import { FormWorkFlowComponent } from './components/workflow/form-workflow/form-workflow.component';
import { ListWorkFlowComponent } from './components/workflow/list-workflow/list.workflow.component';
import { ListWorkFlowReportComponent } from '../app/components/workflowreport/list.workflowreport.component';
import { DialogImageComponent } from './components/workflowreport/dialog-image/dialog-image.component';
import { StepFormComponent } from './components/step/step-form/step-form.component';
import { ChangeStepTypeFormComponent } from './components/step/step-form/changeStepType-form/changeStepType-form.component';
import { CaptureFormComponent } from './components/step/step-form/capture-form/capture-form.component';
import { ImageFormComponent } from './components/step/step-form/image-form/image-form.component';
import { TextFormComponent } from './components/step/step-form/text-form/text-form.component';
import { NumericFormComponent } from './components/step/step-form/numeric-form/numeric-form.component';
import { QrCodeFormComponent } from './components/step/step-form/qrCode-form/qrCode-form.component';
import { MeterFormComponent } from './components/step/step-form/meter-form/meter-form.component';
import { QuestionFormComponent } from './components/step/step-form/question-form/question-form.component';
import { MultiAnswerFormComponent } from './components/step/step-form/multi-answer-form/multi-answer-form.component';
import { ChecklistFormComponent } from './components/step/step-form/checklist-form/checklist-form.component';
import { RecordVideoFormComponent } from './components/step/step-form/recordVideo-form/recordVideo-form.component';
import { VideoFormComponent } from './components/step/step-form/video-form/video-form.component';
import { LinkFormComponent } from './components/step/step-form/link-form/link-form.component';
import { ListStepComponent } from './components/step/list-step/list.step.component';
import { DateFormComponent } from './components/step/step-form/date-form/date-form.component';
import { PDFFormComponent } from './components/step/step-form/PDF-form/PDF-form.component';
import { TagFormComponent } from './components/step/step-form/tag-form/tag-form.component';


import { Languages } from './common/languages';
import { TranslatePipe } from '../app/pipes/translate.pipe';
import { SafePipe } from '../app/pipes/safe.pipe';
import { BottomSheetQR } from './components/workflow/bottom-sheet-qr/bottom-sheet-qr.component';
import { AlertDialogComponent } from '../app/components/dialogs/alert/alert.component';
import { LoadingComponent } from '../app/components/loader/loading.component';
import { AlertComponent } from '../app/components/alert/alert.component';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { DiagramModule } from '@syncfusion/ej2-angular-diagrams';
import { DiagramAllModule } from '@syncfusion/ej2-angular-diagrams';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { WhiteSpaceValidator } from './Validators/text.validator';
import { MetadataComponent } from './components/workflow/metadata/metadata.component';
import { DatePipe } from '@angular/common';
import { MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { MatTableDataSource } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AssignmentsComponent } from './components/workflow/assignments/assignments.component';
import { AllowedUsersComponent } from './components/workflow/allowed-users/allowed-users.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GroupsComponent } from './components/groups/groups.component';
import { DialogGroupEditComponent } from './components/groups/dialog-edit/dialog-edit.component';
import { DialogUsersGroupsComponent } from './components/groups/dialog-user-groups/dialog-user-groups.component';
import { MatIconModule } from '@angular/material/icon';

//report
import { ReportHTMLComponent } from './components/workflowreport/report-html/report-html.component';
import { CommentComponent } from './components/workflowreport/report-html/comments/comments.component';
import { CaptureReportComponent } from './components/workflowreport/report-html/steps/capture-report/capture-report.component';
import { QuestionMultiComponent } from './components/workflowreport/report-html/steps/question-multi-report/question-multi-report.component';
import { VideoReportComponent } from './components/workflowreport/report-html/steps/video-report/video-report.component';
import { QuestionFreeReportComponent } from './components/workflowreport/report-html/steps/question-free-report/question-free-report.component';
import { IAReportComponent } from './components/workflowreport/report-html/steps/ia-report/ia-report.component';
import { ChecklistReportComponent } from './components/workflowreport/report-html/steps/checklist-report/checklist-report.component';
import { QuestionYesNoReportComponent } from './components/workflowreport/report-html/steps/question-yesno-report/question-yesno-report.component';
import { DateReportComponent } from './components/workflowreport/report-html/steps/date-report/date-report.component';
import { TagReportComponent } from './components/workflowreport/report-html/steps/tag-report/tag-report.component';

import { ReportIssueComponent } from './components/report-issue/report-issue.component';
import { FileInputComponent } from './components/file-input/file-input.component'; 
import { PdfViewerModule } from 'ng2-pdf-viewer';
//groups
import { UserGroupsComponent } from './components/groups/users/user-groups.component';


export function setupTranslateFactory(service: TranslateService): Function {
  return () => {
    let language = localStorage.getItem('language');
    if (language != null)
      service.use(language);
    else
      service.use(Languages.EN);
  };
}

export const DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    DashBoardComponent, FileInputComponent,
    FormWorkFlowComponent, ListWorkFlowComponent, ListWorkFlowReportComponent,
    StepFormComponent, CaptureFormComponent, ChangeStepTypeFormComponent, ImageFormComponent, TextFormComponent, NumericFormComponent,
    QrCodeFormComponent, MeterFormComponent, QuestionFormComponent, MultiAnswerFormComponent, ChecklistFormComponent, RecordVideoFormComponent, VideoFormComponent,
    ListStepComponent, AlertDialogComponent, LoadingComponent, TranslatePipe, SafePipe, BottomSheetQR, AlertComponent, ReportHTMLComponent,
    DialogImageComponent, WhiteSpaceValidator, AssignmentsComponent, AllowedUsersComponent, MetadataComponent, LinkFormComponent, GroupsComponent, ReportIssueComponent,
    DialogGroupEditComponent, DialogUsersGroupsComponent, CommentComponent, CaptureReportComponent, QuestionMultiComponent,
    VideoReportComponent, QuestionFreeReportComponent, IAReportComponent, ChecklistReportComponent, QuestionYesNoReportComponent, DateFormComponent, DateReportComponent,
    TagReportComponent, PDFFormComponent, TagFormComponent, UserGroupsComponent
  ],
  imports: [
    HttpClientModule, BrowserModule, AppRoutingModule, BrowserAnimationsModule, FormsModule, ReactiveFormsModule, MatNativeDateModule, MaterialDesignModule, MatBottomSheetModule,
    DiagramModule, DiagramAllModule, DropDownListModule, ToolbarModule,
    MatDatepickerModule, MomentDateModule, MatFormFieldModule, DragDropModule, MatChipsModule, MatIconModule, PdfViewerModule
  ],

  providers: [WorkFlowService, WorkFlowReportService, StepService, JwtHelperService, AuthService, ColorService, AuthGuard,
    StepFormComponent, CaptureFormComponent, ChangeStepTypeFormComponent, ImageFormComponent, TextFormComponent, NumericFormComponent, ReportIssueComponent,
    QrCodeFormComponent, MeterFormComponent, QuestionFormComponent, MultiAnswerFormComponent, ChecklistFormComponent, RecordVideoFormComponent, VideoFormComponent,
    ListStepComponent, AlertDialogComponent, LoadingComponent, TranslateService, AlertComponent, ReportHTMLComponent, DialogImageComponent, DatePipe,
    FilterService, MatTableDataSource, LinkFormComponent, GroupsComponent, DialogGroupEditComponent, DialogUsersGroupsComponent, CommentComponent,
    CaptureReportComponent, QuestionMultiComponent, VideoReportComponent, QuestionFreeReportComponent, IAReportComponent, ChecklistReportComponent, WhitelabelService,
    QuestionYesNoReportComponent, DateFormComponent, DateReportComponent, TagReportComponent, FileInputComponent, PDFFormComponent, TagFormComponent, ReportIssueService, UserGroupsComponent,

    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
  ],
  bootstrap: [AppComponent]

})
export class AppModule { }
