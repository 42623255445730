import { Component, Injectable, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'wdm-alert',
    styleUrls: ['alert.component.scss'],
    templateUrl: './alert.component.html'
})

@Injectable()
export class AlertComponent implements OnInit {

    open = true;
    time = 5000;
    message = 'no pone nada';
    type = 'success';
    staticAlertClosed: boolean;

    private success = new Subject<string>();

    successMessage: string;

    ngOnInit(): void {
        this.success.subscribe((message) => this.successMessage = message);
        this.success.pipe(
            debounceTime(this.time)
        ).subscribe(() => this.successMessage = null);
    }

    public showMessage(message: string, type: string, time: number) {
        this.open = true;
        this.message = message;
        this.type = type;
        this.time = time;
        console.log('showMessage:', message, type, time);
        this.success.next(`${new Date()} - Message successfully changed.`);
    }

    public activateTimer(message: string, type: string, time: number) {
        this.staticAlertClosed = false;
        this.open = true;
        this.message = message;
        this.type = type;
        this.time = time;
        setTimeout(() => this.staticAlertClosed = true, 20000);
    }

    hideAlert() {
        this.open = false;
    }

}

