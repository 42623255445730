import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileDto } from '../../../models/FileDto';
import { WorkflowDTO } from '../../../models/WorkflowDTO';
import { FileInputComponent } from '../../file-input/file-input.component';


@Component({
  selector: 'app-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss']
})
export class MetadataComponent implements OnInit {

  @ViewChild('metadataForm', { static: true }) private metadataForm: NgForm;
  @ViewChild('logo') img: ElementRef;
  workflowCopy: WorkflowDTO = new WorkflowDTO();
  maxSizeError = false;
  imageFiles: FileDto[] = [];
  fileInputErrors: boolean;
  @ViewChild(FileInputComponent) fileInputComponent: FileInputComponent;

  constructor(public dialogRef: MatDialogRef<MetadataComponent>, @Inject(MAT_DIALOG_DATA) public data: { workflowDto: WorkflowDTO }) {

    this.workflowCopy = data.workflowDto;
  }

  ngOnInit(): void {
   
    console.log('inicia', this.workflowCopy);

    if (this.workflowCopy.logo !== null) {
      this.imageFiles[0] = new FileDto();
      this.imageFiles[0].name = this.workflowCopy.logo;
      if (!this.workflowCopy.file || this.workflowCopy.file == '') {
        this.imageFiles[0].preview = this.workflowCopy.logo;
      } else if (this.workflowCopy.file && this.workflowCopy.file != '') {
        this.imageFiles[0].preview = this.workflowCopy.file;
      }
      if (this.workflowCopy.fileInput) {
        this.imageFiles[0].file = this.workflowCopy.fileInput;
      }
    }

  }

  onSubmit() {
    if (this.metadataForm.valid) {
      this.dialogRef.close(this.workflowCopy);
    } else {
      // validate all form fields
    }
  }

  onFileChanges(inputFiles) {
    if (this.imageFiles[0] && this.imageFiles[0].name != null && this.imageFiles[0].preview != null) {
      this.workflowCopy.file = this.imageFiles[0].preview;
      this.workflowCopy.logo = this.imageFiles[0].name;
      this.workflowCopy.fileInput = this.imageFiles[0].file;
    } else {
      this.workflowCopy.file = null;
      this.workflowCopy.logo = null;
      this.workflowCopy.fileInput = null;
    }
  }

}
