import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ValidationResult } from '../../../../../models/ValidationResult';
import { WorkFlowReportService } from '../../../../../services/workflowreport.service';
import { DialogImageComponent } from '../../../dialog-image/dialog-image.component';

@Component({
  selector: 'wdm-ia-report',
  templateUrl: 'ia-report.component.html',
  styleUrls: ['ia-report.component.scss']
})

export class IAReportComponent {

  @Input() stepinfo: any;
  @Input() reportId: number;

  validationResult: typeof ValidationResult = ValidationResult;

  constructor(public workFlowReportService: WorkFlowReportService, public dialog: MatDialog) { }

  info: any;

  ngOnInit() {
    if (this.stepinfo.result != '') {
      this.info = JSON.parse(this.stepinfo.result);
      console.log(this.info);
    }
  }

  openImage() {
    const dialogImage = this.dialog.open(DialogImageComponent, {
      width: '90%',
      data: {
        img: this.info.PhotoFileName,
      }
    });
  }

}
