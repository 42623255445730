<form class="capture-form" #captureForm="ngForm">

  <mat-form-field appearance="fill" class="step-form-input">
    <mat-label>{{ 'step_step_name' | translate }}</mat-label>
    <input matInput placeholder="{{ 'step_step_name' | translate }}" #title="ngModel" name="title"
           [(ngModel)]="stepInfo.title" required maxlength="55" whitespace>
    <mat-error *ngIf="title.invalid && title.errors.required">{{ 'error_field_required' | translate }}</mat-error>
    <mat-error *ngIf="title.invalid && title.errors.maxlength">{{ 'error_max_55_chars' | translate }}</mat-error>
    <mat-error *ngIf="title.invalid && title.errors.whitespace && !title.errors.required">{{ 'not_only_whitespaces' | translate }}</mat-error>
  </mat-form-field>


  <mat-form-field appearance="fill" class="step-form-input">
    <mat-label>{{ 'step_text' | translate }}</mat-label>
    <textarea matInput placeholder="{{ 'step_text' | translate }}"
              #description="ngModel" name="description" [(ngModel)]="stepInfo.description" maxlength="5000"></textarea>
    <mat-error *ngIf="description.invalid && description.errors.maxlength">{{ 'error_max_5000_chars' | translate }}</mat-error>

  </mat-form-field>

  <!-- <button *ngIf="name.invalid" class="icon-btn btn-primary" mat-button><i class="fa fa-check" aria-hidden="true"></i></button>
    <button *ngIf="name.valid" class="icon-btn btn-primary" mat-button (click)='saveChanges(stepInfo)' [mat-dialog-close]="true"><i class="fa fa-check" aria-hidden="true"></i></button>
  -->
</form>
