

<div *ngIf="stepComment.comment!=null ||  stepComment.mediaList[0] != null" class="comments-area">
  <p  class="comment">
    <b>{{'report_step_comment' | translate}}:</b> {{stepComment.comment}}
  </p>
  <div *ngIf="stepComment.mediaList[0] != null" class="report-container comment">
    <div *ngFor="let media of stepComment.mediaList" class="comment-item">
      <img *ngIf="media.mediaType == 0" class="report-image comment-image" src='{{media.fileURL}}' (click)="openImage(media.fileURL)" />
      <div *ngIf="media.mediaType == 1" class="audio-comment">
        <audio controls>
          <source src='{{media.fileURL}}' type="audio/wav">
          {{'report_multimedia_not_available' | translate}}
        </audio>
        <p class="audio-title"><b>Audio: </b>{{media.fileName}}</p>
      </div>
    </div>
  </div>
</div>








