<form class="tag-form" #tagForm="ngForm" id="tag-form.component">
  <div class="form-columns">
    <div class="form-column-data">
      <mat-form-field appearance="fill" class="step-form-input">
        <mat-label>{{ 'step_step_name' | translate }}</mat-label>
        <input matInput placeholder="{{ 'step_step_name' | translate }}"
               #title="ngModel" name="title" [(ngModel)]="stepInfo.title" required maxlength="55" whitespace>
        <mat-error *ngIf="title.invalid && title.errors.required">{{ 'error_field_required' | translate }}</mat-error>
        <mat-error *ngIf="title.invalid && title.errors.maxlength">{{ 'error_max_55_chars' | translate }}</mat-error>
        <mat-error *ngIf="title.invalid && title.errors.whitespace && !title.errors.required">{{ 'not_only_whitespaces' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="step-form-input">
        <mat-label>{{ 'step_text' | translate }}</mat-label>
        <textarea matInput placeholder="{{ 'step_text' | translate }}" #description="ngModel" name="description"
                  [(ngModel)]="stepInfo.description" maxlength="100" style="max-height: 70px" whitespace></textarea>
        <mat-error *ngIf="description.invalid && description.errors.maxlength">{{ 'error_max_100_chars' | translate }}</mat-error>
        <mat-error *ngIf="description.invalid && description.errors.whitespace && !description.errors.required">{{ 'not_only_whitespaces' | translate }}</mat-error>
      </mat-form-field>

    </div>
    <div class="form-column-preview">
      <wdm-file-input [(inputFiles)]="imageFiles" (inputFilesChange)="onFileChanges($event)" (errors)="hasErrors($event)"></wdm-file-input>
    </div>
  </div>
</form>




