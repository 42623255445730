<form class="numeric-form" #numericForm="ngForm" id="numeric-form.component">

  <mat-form-field appearance="fill" class="step-form-input">
    <mat-label>{{ 'step_step_name' | translate }}</mat-label>
    <input matInput placeholder="{{ 'step_step_name' | translate }}"
           #title="ngModel" name="title" [(ngModel)]="stepInfo.title" required maxlength="55" whitespace>
    <mat-error *ngIf="title.invalid && title.errors.required">{{ 'error_field_required' | translate }}</mat-error>
    <mat-error *ngIf="title.invalid && title.errors.maxlength">{{ 'error_max_55_chars' | translate }}</mat-error>
    <mat-error *ngIf="title.invalid && title.errors.whitespace && !title.errors.required">{{ 'not_only_whitespaces' | translate }}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill" class="step-form-input">
    <mat-label>{{ 'step_question' | translate }}</mat-label>
    <textarea matInput placeholder="{{ 'step_question' | translate }}" #description="ngModel" name="description"
              [(ngModel)]="stepInfo.description" required maxlength="180" whitespace style="max-height: 70px"></textarea>
    <mat-error *ngIf="description.invalid && description.errors.required">{{ 'error_field_required' | translate }}</mat-error>
    <mat-error *ngIf="description.invalid && description.errors.maxlength">{{ 'error_max_100_chars' | translate }}</mat-error>
    <mat-error *ngIf="description.invalid && description.errors.whitespace && !description.errors.required">{{ 'not_only_whitespaces' | translate }}</mat-error>
  </mat-form-field>

  <div class="numberFields">

    <mat-form-field appearance="fill" class="step-form-input">
      <mat-label>{{ 'step_accuracy' | translate }}</mat-label>
      <input matInput type="number" #accuracy="ngModel" name="accuracy" [(ngModel)]="content.accuracy">
    </mat-form-field>

    <mat-form-field appearance="fill" class="step-form-input">
      <mat-label>{{ 'step_unit_of_measure' | translate }}</mat-label>
      <input matInput type="text" #unitOfMeasure="ngModel" name="unitOfMeasure" [(ngModel)]="content.unitOfMeasure">
    </mat-form-field>

  </div>

</form>

<wdm-link-form [stepInfo]='stepInfo' [allSteps]="allSteps"></wdm-link-form>
