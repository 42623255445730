import { Injectable, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Constants } from '../Constants/Constants';
import { AlertComponent } from '../components/alert/alert.component';
import { SentenceRequest, SentenceResponse } from '../models/Requests/SentenceRequest';
import { SaveSentenceResponse } from '../models/Responses/SaveSentenceResponse';
import { Group, GroupTypeEnum } from '../models/Group';


@Injectable({
  providedIn: 'root'
})

export class UsersService {

  baseUrl: string = '';
  public ALL_GROUP_ID = -1;
  @ViewChild('alertComponentUsers', { static: true }) private alertComponent: AlertComponent;

  constructor(private http: HttpClient, private bottomSheet: MatBottomSheet, public dialog: MatDialog) {
    if (!environment.production) {
      this.baseUrl = environment.apiBaseUrl;
    }
  }

  public getToken(): string {
    return localStorage.getItem(Constants.Token);
  }

  getGroupList(groupTypeId: GroupTypeEnum) {
    let headers = new HttpHeaders();
    headers.append('Content-Type' , 'application/json');

    const httpOptions = {
      headers: headers
    };
    return this.http.post<any[]>(environment.apiBaseUrl + '/Group/GetList', groupTypeId, httpOptions);
  }

  getGroup(groupId: number, groupTypeId: GroupTypeEnum) {
    var request = { 'token': this.getToken(), 'id': groupId, 'groupTypeId': groupTypeId };

    let headers = new HttpHeaders();
    headers.append('Content-Type' , 'application/json');

    const httpOptions = {
      headers: headers
    };
    return this.http.post<any[]>(environment.apiBaseUrl + '/Group/UsersByGroup', request, httpOptions);
  }

  getUsersList(groupTypeId: GroupTypeEnum) {
    //var request = { 'token': this.getToken() };

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    const httpOptions = {
      headers: headers
    };

    var url = '/Technician/GetList';
    if (groupTypeId == GroupTypeEnum.Designer) url = '/Designer/GetList';

    return this.http.post<any[]>(environment.apiBaseUrl + url, null, httpOptions);
  }

  getUserGroups(userId: number, groupTypeId: GroupTypeEnum) {
    var request = { 'id': userId, 'groupTypeId': groupTypeId };

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    const httpOptions = {
      headers: headers
    };
    return this.http.post<any[]>(environment.apiBaseUrl + '/Group/ByUser', request, httpOptions);
  }

  saveGroup(group: Group, userIds: number[]) {
    var request = { 'id': group.id, 'name': group.name, 'userIds': userIds, 'groupTypeId': group.groupTypeId };

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    const httpOptions = {
      headers: headers
    };
    return this.http.post<any>(environment.apiBaseUrl + '/Group/Save', request, httpOptions);
  }

  deleteGroup(groupId: number) {
    var request = { 'id': groupId };

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    const httpOptions = {
      headers: headers
    };
    return this.http.post<any>(environment.apiBaseUrl + '/Group/Remove', request, httpOptions);
  }

  deleteUserGroups(userId: number, groupTypeId: GroupTypeEnum, groups: number[]) {
    var request = { 'userId': userId, 'groupTypeId': groupTypeId, 'groupIds': groups };

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    const httpOptions = {
      headers: headers
    };
    return this.http.post<any>(environment.apiBaseUrl + '/Group/RemoveUserFromGroups', request, httpOptions);
  }


  getOperationList() {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    const httpOptions = {
      headers: headers
    };
    return this.http.post<any[]>(environment.apiBaseUrl + '/Group/GetOperationList', httpOptions);
  }

  getCurrentSentence(workflowId: number, groupTypeId: GroupTypeEnum) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    var request = { 'workflowId': workflowId, 'groupTypeId': groupTypeId};

    const httpOptions = {
      headers: headers
    };
    return this.http.post<SentenceResponse>(environment.apiBaseUrl + '/Assignments/GetSentence', request, httpOptions);
  }

  saveSentence(sentence:SentenceRequest) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    const httpOptions = {
      headers: headers
    };
    return this.http.post<SaveSentenceResponse[]>(environment.apiBaseUrl + '/Assignments/SaveSentence', sentence, httpOptions);
  }
}
