import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatTableDataSource } from '@angular/material/table';

@Injectable({
  providedIn: 'root'
})

export class FilterService {

  filterValues: Map<string, string>;
  filterDates: Map<string, string>;

  constructor(private dataSource: MatTableDataSource<any>, private datePipe: DatePipe) {
    this.filterValues = new Map<string, string>();
  }

  public resetFilter() {
    this.filterValues = new Map<string, string>();
  }

  public applyFilter(filterValue: string): string {
    if (filterValue.trim() == '') this.filterValues.delete('');
    else this.filterValues.set('', filterValue.trim().toLowerCase());

    return JSON.stringify(this.mapToJson(this.filterValues));
  }

  public applyDateFilter(filterValue: FormGroup, field: string): string {

    this.filterDates = new Map<string, string>();

    var start = filterValue.get('startDate_' + field).value;
    var end = filterValue.get('endDate_' + field).value;

    if (start == null && end == null) this.filterValues.delete(field);
    else {
      this.filterDates.set('start', this.datePipe.transform(start, 'yyyy-MM-dd'));
      this.filterDates.set('end', this.datePipe.transform(end, 'yyyy-MM-dd'));

      this.filterValues.set(field, JSON.stringify(this.mapToJson(this.filterDates)));
    }

    return JSON.stringify(this.mapToJson(this.filterValues));
  }

  public createFilter(filterPredicate: any): (data: any, filter: string) => boolean {
    const defaultFilterPredicate = filterPredicate;
    var self = this;
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      var keys = Object.keys(searchTerms);

      if (keys.length == 0) return defaultFilterPredicate(data, '');

      var result = [];
      keys.forEach(key => {
        if (key == '') result.push(defaultFilterPredicate(data, searchTerms[key]));
        else if (data[key] != undefined) 
        {
          var range = JSON.parse(searchTerms[key]);
          var date = self.datePipe.transform(data[key], 'yyyy-MM-dd');
          result.push(date >= range.start && date <= range.end);
        }
      });

      return result.every(r => r == true);
    }

    return filterFunction;
  }

  private mapToJson(filterValues: Map<string, string>) {
    let jsonObject = {};
    filterValues.forEach((value, key) => {
      jsonObject[key] = value;
    });
    return jsonObject;
  }

}
