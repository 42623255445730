<!-- <header-root></header-root> -->
<div class="green-corner">
  <svg
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 456.84 259.17">
	<defs>
		<linearGradient id="a" x1="489.6" y1="435.41" x2="299.29" y2="767.12" gradientUnits="userSpaceOnUse">
			<stop offset="0" class="gradient__primary-color"/>
			<stop offset="1" class="gradient__primary-color-dark"/>
		</linearGradient>
		<linearGradient id="b" x1="339.93" y1="468.23" x2="700.8" y2="468.23" gradientUnits="userSpaceOnUse">
			<stop offset="0.02" stop-color="#3b919d"/>
			<stop offset="0.55" stop-color="#49d4c1"/>
			<stop offset="0.87" stop-color="#aaf16f"/>
			<stop offset="0.89" stop-color="#b8f25c"/>
			<stop offset="0.94" stop-color="#d4f438"/>
			<stop offset="0.98" stop-color="#e6f621"/>
			<stop offset="1" stop-color="#ecf619"/>
		</linearGradient>
	</defs>
	<path class="a" d="M293.9,547H450.35l-54.28-68.91,65.44-51.55c29-21.38,67.41-18.51,86.79,6.09,19.84,24.23,16.48,66.75-10.64,89.62L506.24,547H673.76c26.33-61,20.41-131.62-23.41-187.24-64-81.22-182.64-95.31-267.35-33.84l-65.44,51.55-69.62,54.85-4.18,3.29c-13,10.24-14.8,29-4.19,42.42Z" transform="translate(-232.67 -287.83)"/>
	<path class="b" d="M548.76,432.27c19.38,24.59,16,67.11-11.1,90L506.24,547H672.71c12.2-28.42,14.6-52.15,14.9-61.65.75-13.37-10.43-36.16-19.57-47.76-49.36-62.66-142.48-61.51-205.6-11.78l-.47.36C491,404.8,529.38,407.67,548.76,432.27Z" transform="translate(-232.67 -287.83)"/>
</svg>

</div>
<div class="logo-corner"></div>


<div class="login-background">
  <div class="container-box">

    <div id="login-view" class="form-section">
      <b>{{ 'login_welcome' | translate }}</b>
      <!--<input [formControl]="login" class="login-name" ng-model="login" placeholder="{{ 'login_login_placeholder' | translate }}">-->

      <mat-form-field>
        <mat-label>{{ 'login_login_placeholder' | translate }}</mat-label>
        <input matInput [formControl]="login" class="login-name" ng-model="login" placeholder="{{ 'login_login_placeholder' | translate }}">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'login_password_placeholder' | translate }}</mat-label>
        <input matInput [formControl]="password" ng-model="password" class="login-password"
               [type]="hide ? 'password' : 'text'" (keyup)="handleKeyUp($event)" placeholder="{{ 'login_password_placeholder' | translate }}">
        <button *ngIf="password" matSuffix mat-icon-button (click)="hide = !hide">
          <mat-icon>visibility-password</mat-icon>
        </button>
      </mat-form-field>


      <button mat-flat-button class="login-button btn-primary" (click)="loginAccess()">
        <!--[disabled]="login.invalid || password.invalid" -->
        <span *ngIf="!loading"> {{ 'login_button' | translate }}</span>
        <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        <span *ngIf="loading"> {{ 'loading_button' | translate }}</span>
      </button>

      <div *ngIf="password.invalid || login.invalid || !logged" class="validation-summary-errors">
        <ul>
          <!-- <li *ngIf="password.invalid"> <mat-error>{{ 'login_password_placeholder' | translate }}: {{ 'login_password_error' | translate }}</mat-error></li>
      <li *ngIf="login.invalid"> <mat-error>{{ 'login_login_placeholder' | translate }}: {{ 'login_login_error' | translate }}</mat-error></li> -->
          <li *ngIf="!logged && error == 1"><mat-error>  {{ 'error_user_not_exist' | translate }}</mat-error></li>
          <li *ngIf="!logged && error == 2"><mat-error>  {{ 'error_user_not_active' | translate }}</mat-error></li>
          <li *ngIf="!logged && error == 3"><mat-error>  {{ 'error_client_not_active' | translate }}</mat-error></li>
          <li *ngIf="!logged && error == 4"><mat-error>  {{ 'error_no_licenses' | translate }}</mat-error></li>
          <li *ngIf="!logged && error == 5"><mat-error>  {{ 'error_too_many_designers' | translate }}</mat-error></li>
          <li *ngIf="!logged && error == 6"><mat-error>  {{ 'error_too_many_technicians' | translate }}</mat-error></li>
          <li *ngIf="!logged && error == 7"><mat-error>  {{ 'error_pwd' | translate }}</mat-error></li>
        </ul>
      </div>
    </div>
    <div class="logo-section">
      <div class="login-logo"></div>
    </div>

  </div>
</div>
