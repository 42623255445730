import { Component } from '@angular/core';
import { GroupTypeEnum } from '../../models/Group';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'groups-component',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent {

  groupTypeEnum: typeof GroupTypeEnum = GroupTypeEnum;
  isTechManager: boolean;
  isDesignerManager: boolean;

  constructor(private auth: AuthService) {
    this.isTechManager = this.auth.IsTechManager();
    this.isDesignerManager = this.auth.IsDesignerManager();
  }


}
