import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { WorkflowDTO } from '../models/WorkflowDTO';
import { Observable, throwError } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { BottomSheetQR } from '../components/workflow/bottom-sheet-qr/bottom-sheet-qr.component';
import { Constants } from '../Constants/Constants';
import { AssignmentsComponent } from '../components/workflow/assignments/assignments.component';


@Injectable({
  providedIn: 'root'
})

export class WorkFlowService {

  baseUrl: string = '';

  constructor(private http: HttpClient, private bottomSheet: MatBottomSheet, public dialog: MatDialog) {
    if (!environment.production) {
      this.baseUrl = environment.apiBaseUrl;
    }
  }

  public getToken(): string {
    return localStorage.getItem(Constants.Token);
  }

  getWorkFlowList() {
    return this.http.get<any>(environment.apiBaseUrl + '/WorkFlow/GetList?token=' + this.getToken(), {
    });
  }

  getWorkFlow(workflowId: number) {
    return this.http.get<any>(environment.apiBaseUrl + '/WorkFlow/Get/' + workflowId + '?token=' + this.getToken(), {
    });
  }

  saveWorkflow(workflowDTO: WorkflowDTO): Observable<any> {
    var formData = new FormData();

    Object.keys(workflowDTO).forEach(key => {
      var value = workflowDTO[key];
      if (key == "stepItemsToDelete" || key == "stepItemsToEdit" || key == "stepItemsToAdd") {
        Object.entries(value).forEach(([k, v]) => {

          if (key == "stepItemsToDelete") {
            formData.append(key + '[' + k + ']', v.toString());
          }

          Object.entries(v).forEach(([k1, v1]) => {
            
            if (v1 != null && v1 != "preview") { //file = img/video en base64

              if (k1 == "links" || k1 == "parents" || k1 == 'fileList') {
                Object.entries(v1).forEach(([k2, v2]) => {
                  if (k1 == "parents") {
                    console.log(key + '[' + k + '][' + k1 + ']');
                    formData.append(key + '[' + k + '][' + k1 + ']', v2.toString());
                  }

                  Object.entries(v2).forEach(([k3, v3]) => {
                    if (v3 != null) {
                      if (k1 == 'fileList') {
                        if (k3 == 'file') formData.append(key + '[' + k + '].fileInput', v3 as File);
                        if (k3 == 'name') formData.append(key + '[' + k + '][' + k1 + ']', v3);
                      } else formData.append(key + '[' + k + '][' + k1 + '][' + k2 + '].' + k3, v3);

                      
                    }
                  });
                });

              } else {
                if (typeof (v1) == "object" && k1 == "content") v1 = JSON.stringify(v1); //checklist
                formData.append(key + '[' + k + '].' + k1, v1);
              }
            } 
          });

        });

      } else {
        if (value != null && key != "preview") { 
          if (key == "fileInput") value = value as File; 
          formData.append(key, value);
        }
      }
    });

    return this.http.post<number>(environment.apiBaseUrl + '/WorkFlow/Save', formData);
  }

  openBottomSheet(workflowId: number, event: Event): void {
    event.stopPropagation();
    this.bottomSheet.open(BottomSheetQR, {
      data: {
        baseUrl: this.baseUrl,
        workflowId: workflowId,
        imgToken: this.getToken()
      }
    }
    );
  }

  openAllowedUsers(workflowId: number, event: Event): void {
    event.stopPropagation();
    this.dialog.open(AssignmentsComponent, {
      width: '80vw',
      height: '500vm',
         data: {
           baseUrl: this.baseUrl,
           workflowId: workflowId
         }
       });
  }

  deleteWorkflow(workflowId: number): Observable<any> {
     const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    var deleteWorkflowRequest = {
      workflowId: workflowId,
      token: this.getToken()
    };
   return this.http.post<number>(environment.apiBaseUrl + '/WorkFlow/Delete', deleteWorkflowRequest, httpOptions);

  }

  cloneWorkflow(workflowId: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    var copyWorkflowRequest = {
      workflowId: workflowId,
    };
    return this.http.post<number>(environment.apiBaseUrl + '/WorkFlow/Clone', copyWorkflowRequest, httpOptions);

  }


  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }

}


