export class Constants {
  public static UserName: string = "name";
  public static Token: string = "token";
  public static IsCreator: string = "isCreator";
  public static IsReviewer: string = "isRewiewer";
  public static IsTechManager: string = "isTechManager";
  public static IsDesignerManager: string = "isDesignerManager";
  public static True: string = "true";
  public static False: string = "false";

  public static SentenceItemGroup: string = "group";
  public static SentenceItemTechnician: string = "user";
}
