import { Component, Input } from '@angular/core';

@Component({
  selector: 'wdm-tag-report',
  templateUrl: 'tag-report.component.html',
  styleUrls: ['tag-report.component.scss']
})

export class TagReportComponent {

  @Input() stepinfo: any;

  constructor() { }

  info: any;

  ngOnInit() {
    this.info = JSON.parse(this.stepinfo.result);
    console.log('info tag', this.info);
  }

}
