
<h2 mat-dialog-title>{{ 'permission_title' | translate }}</h2>


<mat-dialog-content class="allowed-users-content">

  <div class="groups-tabs">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="onTabChanged($event);">
      <mat-tab label="{{ 'tech_groups_title' | translate }}" *ngIf="isTechManager"> <app-allowed-users [groupTypeId]="this.groupTypeEnum.Technician" [workflowId]="this.workflowId"></app-allowed-users></mat-tab>
      <mat-tab label="{{ 'designer_groups_title' | translate }}" *ngIf="isDesignerManager"> <app-allowed-users [groupTypeId]="this.groupTypeEnum.Designer" [workflowId]="this.workflowId"></app-allowed-users></mat-tab>
    </mat-tab-group>
  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <div *ngIf="Error!=''" class="error">{{Error}}</div>
  <button mat-button mat-dialog-close>{{ 'cancel' | translate }}</button>
  <button mat-button cdkFocusInitial (click)="saveSentence(true)">{{ 'save' | translate }}</button>
</mat-dialog-actions>


