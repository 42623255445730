import { Component, Input } from '@angular/core';

@Component({
  selector: 'wdm-question-free-report',
  templateUrl: 'question-free-report.component.html',
  styleUrls: ['question-free-report.component.scss']
})

export class QuestionFreeReportComponent {

  @Input() stepinfo: any;

  constructor() { }

  info: any;

  ngOnInit() {
    this.info = JSON.parse(this.stepinfo.result);
    console.log('info free question', this.info);
  }

}
