import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ReportIssue } from '../components/report-issue/report-issue.component';
import { FileDto } from '../models/FileDto';


@Injectable({
  providedIn: 'root'
})

export class ReportIssueService {

  baseUrl: string = '';

  constructor(private http: HttpClient) {
    if (!environment.production) {
      this.baseUrl = environment.apiBaseUrl;
    }
  }

  sendForm(issue: ReportIssue, issuePreviewFiles: FileDto[]): Observable<any> {
    var formData = new FormData();
    for (var key in issue) {
      formData.append(key, issue[key]);
    }

    for (var index in issuePreviewFiles) {
      formData.append('images', issuePreviewFiles[index].file);
    }

    return this.http.post<any>(environment.apiBaseUrl + '/Support/SendForm', formData);
  }

}


