import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { StepDto } from '../models/StepDTO';
import { StepType } from '../models/StepTypes';
import { Constants } from '../Constants/Constants';
import { WorkflowDTO } from '../models/WorkflowDTO';

@Injectable({
  providedIn: 'root'
})

export class StepService {

  @Output() deleteAnswer: EventEmitter<StepDto[]> = new EventEmitter<StepDto[]>();

  stepTypesInfo = [
    { id: StepType.Init, name: 'step_type_init', image: 'init.svg', description: 'step_type_init_description', visual: false },
    { id: StepType.Text, name: 'step_type_text', image: 'text.svg', description: 'step_type_text_description', visual: true },
    { id: StepType.Image, name: 'step_type_image', image: 'image.svg', description: 'step_type_image_description', visual: true },
    { id: StepType.Video, name: 'step_type_video', image: 'video.svg', description: 'step_type_video_description', visual: true },
    { id: StepType.PDF, name: 'step_type_pdf', image: 'pdf.svg', description: 'step_type_pdf_description', visual: true },
    { id: StepType.Numeric, name: 'step_type_numeric', image: 'numeric.svg', description: 'step_type_numeric_description', visual: true },
    { id: StepType.Capture, name: 'step_type_capture', image: 'capture.svg', description: 'step_type_capture_description', visual: true },
    { id: StepType.RecordVideo, name: 'step_type_record_video', image: 'record-video.svg', description: 'step_type_record_video_description', visual: true },
    { id: StepType.QrCode, name: 'step_type_qr_Code', image: 'qr-code.svg', description: 'step_type_qr_Code_description', visual: true },
    { id: StepType.Meter, name: 'step_type_meter', image: 'electric-meter.svg', description: 'step_type_meter_description', visual: true },
    { id: StepType.Date, name: 'step_type_date', image: 'date.svg', description: 'step_type_date_description', visual: true },
    { id: StepType.BinaryQuestion, name: 'step_type_question_yes/no', image: 'question-yes-no.svg', description: 'step_type_question_yes/no_description', visual: true },
    { id: StepType.MultiAnswer, name: 'step_type_question_multianswer', image: 'multiple-answer.svg', description: 'step_type_question_multianswer_description', visual: true },
    { id: StepType.FreeAnswer, name: 'step_type_question_open', image: 'question.svg', description: 'step_type_question_open_description', visual: true },
    { id: StepType.Checklist, name: 'step_type_checklist', image: 'checklist.svg', description: 'step_type_checklist_description', visual: true },
    { id: StepType.Tag, name: 'step_type_tag', image: 'tag.svg', description: 'step_type_tag_description', visual: true },
    { id: StepType.End, name: 'step_type_end', image: 'endstep.svg', description: 'step_type_end_description', visual: false }
   
  ];

  iconRoute = "/assets/img/IconStepType/";
  parentsChecked = new Map();
  workflowDTO: WorkflowDTO;

  constructor(private http: HttpClient) {
  }

  getStepList(workflowId: number) {
    return this.http.get<StepDto[]>(environment.apiBaseUrl + '/Step/GetList/' + workflowId + '?token=' + localStorage.getItem(Constants.Token), {
    });
  }

  getStepShape(stepTypeId): string {
    if (stepTypeId == StepType.New) {
      return 'new-step.svg';
    }
    const stepType = this.stepTypesInfo.map(function (e: stepTypeInfo) { return e.id; }).indexOf(stepTypeId);
    if (stepType > -1) {
      return this.stepTypesInfo[stepType].image;
    }
  }

  getStepShapeRoute(stepTypeId): string {
    return this.iconRoute + this.getStepShape(stepTypeId);
  }

  getStepTypeName(stepTypeId): string {
    if (stepTypeId == StepType.New) {
      return 'menu_new';
    }
    const stepType = this.stepTypesInfo.map(function (e: stepTypeInfo) { return e.id; }).indexOf(stepTypeId);
    if (stepType > -1) {
      return this.stepTypesInfo[stepType].name;
    }
  }

  getStepTypes() {
    return this.stepTypesInfo;
  }

  getVisualStepTypes() {
    return this.stepTypesInfo.filter(o=>o.visual);
  }

  emitDeleteAnswer(step: StepDto[]): void {
    this.deleteAnswer.emit(step);
  }

  isMultipathStep(step: StepDto): boolean {
    return step.stepTypeId == StepType.MultiAnswer || step.stepTypeId == StepType.BinaryQuestion || step.stepTypeId == StepType.Numeric;
  }

  isOrphan(step: StepDto, allSteps: StepDto[]): boolean {
    var initStep = allSteps.find(s => s.stepTypeId == StepType.Init);
    var result = true;
    for (let p of step.parents) {
      
      if (p == initStep.stepId) return false;
      if (p == step.stepId) continue; //loop
      if (this.parentsChecked.has(p)) {
        if (this.parentsChecked.get(p)) continue;
        return false;  //checked 
      }

      var parentStep = allSteps.find(s => s.stepId == p);
      if (parentStep != undefined) {
        this.parentsChecked.set(p, result);
        result = this.isOrphan(parentStep, allSteps);

        if (!result) {
          this.parentsChecked.set(p, result);
          return result;
        }
      }
    };

    return result;
  }

  setWorkflowDto(workflowDTO: WorkflowDTO) {
    this.workflowDTO = workflowDTO;
  }

  modifyStep(step: StepDto): void {
    if (!step.isNew) {
      this.editStep(step);
    } else {
      this.addStep(step);
    }
    console.log('listas tras modificación', this.workflowDTO);
  }

  addStep(step: StepDto): void {
    // we also look for it in the list of added ones, may have been edited before and has changes
    const pos = this.workflowDTO.stepItemsToAdd.map(function (e: StepDto) { return e.id; }).indexOf(step.id);
    if (pos > -1) this.workflowDTO.stepItemsToAdd[pos] = step;
    else this.workflowDTO.stepItemsToAdd.push(step);
  }

  editStep(step: StepDto): void {
    // we also look for it in the list of added ones, may have been edited before and has changes
    const pos = this.workflowDTO.stepItemsToEdit.map(function (e: StepDto) { return e.id; }).indexOf(step.id);
    if (pos > -1) this.workflowDTO.stepItemsToEdit[pos] = step;
    else this.workflowDTO.stepItemsToEdit.push(step);
  }

  undoEdit(step: StepDto): void {
    var index = this.workflowDTO.stepItemsToEdit.findIndex(s => s.stepId == step.stepId);
    if (index == -1) return;
    this.workflowDTO.stepItemsToEdit.splice(index, 1);
  }

  changeFatherIdOfAllStepChildren(steps: StepDto[], stepDto: StepDto, fatherId: number): void {
    for (let link of stepDto.links) {
      if (link.isFinal && stepDto.links.length > 1) continue;

      var step = steps.find(o => o.stepId == link.id);
      if (step != undefined) {
        var lastParentIndex = step.parents.indexOf(stepDto.stepId);
        if (lastParentIndex != -1) step.parents.splice(lastParentIndex, 1);
        step.parents.push(fatherId);
        this.modifyStep(step);
      }
    }
  }

}

export interface stepTypeInfo {
  id: number,
  name: string,
  description: string,
  image: string,
  visual:boolean
}
