import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { WorkflowDTO } from '../../../models/WorkflowDTO';
import { StepDto, Link } from '../../../models/StepDTO';
import { StepType } from '../../../models/StepTypes';
import { StepService } from '../../../services/step.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../services/auth.service';
import { ColorService } from '../../../services/color.service';
import { StepFormComponent } from '../step-form/step-form.component';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { AlertDialogComponent } from '../../dialogs/alert/alert.component';
import { DiagramComponent, PathAnnotationModel, TextStyleModel, DecoratorModel } from '@syncfusion/ej2-angular-diagrams';
import { cloneDeep } from 'lodash-es';

import {
  NodeModel,
  SnapConstraints,
  DiagramTools,
  IClickEventArgs,
  Diagram,
  SnapSettingsModel,
  ConnectorModel,
  ConnectionPointOrigin,
  NodeConstraints,
  ConnectorConstraints,
  ZoomOptions,
  LayoutModel
} from '@syncfusion/ej2-diagrams';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { FileDto } from '../../../models/FileDto';

export interface DialogData {
  dialogStep: StepDto;
  showDeleteButton: boolean;
  reasonNotToDelete: string;
  allSteps: StepDto[];
  workflowDTO: WorkflowDTO;
}

@Component({
  selector: 'wdm-list-step-table',
  styleUrls: ['list.step.component.scss'],
  templateUrl: './list.step.component.html',
  providers: [TranslatePipe]
})

export class ListStepComponent {
  @Input() workflowDTO: WorkflowDTO;
  @Input() wFlowId: number;
  @Output() okWF: EventEmitter<string> = new EventEmitter();
  @Output() unfinishWF: EventEmitter<string> = new EventEmitter();
  newSteps: StepDto[];
  stepsToDelete: StepDto[];
  steps: StepDto[] = [];
  copyAllSteps: StepDto[];
  stepsChecked = [];
  loading = false;
  newsItemsId = 1;
  connectorColorMode = false;

  public node: NodeModel;
  public nodeConstraints: NodeConstraints;
  public connectorConstraints: ConnectorConstraints;

  @ViewChild('diagram')
  public diagram: DiagramComponent;
  public connectors: ConnectorModel[] = [];
  public nodes: NodeModel[] = [];

  public tool: DiagramTools = DiagramTools.None;

  public layout: LayoutModel;

  public dialogRef: MatDialogRef<StepFormComponent>;

  constructor(public dialog: MatDialog, private auth: AuthService, private stepService: StepService, private translateService: TranslatePipe, private colors: ColorService) {
  }

  ngOnInit(): void {
    this.nodeConstraints = NodeConstraints.Default & ~NodeConstraints.Select;
    this.connectorConstraints = (ConnectorConstraints.Default | (ConnectorConstraints.Bridging | ConnectorConstraints.LineRouting)) & ~ConnectorConstraints.Select;
    this.getStepDataList(this.wFlowId);
    this.stepsToDelete = [];

    console.time("setWorkflowDto");
    this.stepService.setWorkflowDto(this.workflowDTO);
    console.timeEnd("setWorkflowDto");

  }

  public snapSettings: SnapSettingsModel = {
    constraints: SnapConstraints.ShowLines,
    horizontalGridlines: {
      lineColor: 'rgba(158, 158, 158, 0.12)',
    },
    verticalGridlines: {
      lineColor: 'rgba(158, 158, 158, 0.12)',
    },
  };

  public layoutModel: LayoutModel = {
    type: 'ComplexHierarchicalTree',
    horizontalAlignment: 'Left',
    verticalAlignment: 'Top',
    orientation: 'LeftToRight',
    /*    enableRouting: true,*/
    horizontalSpacing: 50,
    verticalSpacing: 50,
    connectionPointOrigin: ConnectionPointOrigin.DifferentPoint,
  };

  public getStepDataList(workflowId: number) {
    this.loading = true;
    this.stepService.getStepList(workflowId).subscribe({
      next: (data: any[]) => {
        if (data.length == 0) {
          this.layout = this.layoutModel;
          this.newWorkflow();
          return;
        }

        if (data != null) {
          /* console.log('datos', data);*/

          if (data.length > 0) {
            this.newsItemsId = Math.max(...data.map(o => o.stepId)) + 1;
          }

          this.steps = data.map((step) => {
            const files = step.fileList.map((str) => {
              var dto = new FileDto();
              const splittedPath = str.split('/');
              dto.name = splittedPath[splittedPath.length - 1];
              dto.path = str;
              dto.preview = str;
              dto.file = null;
              return dto;
            });

            step.fileList = files;
            return step;
          });

          //create final step if does not exists
          var finalStep = this.getFinalStep();

          for (let step of this.steps) { //Al cargar los datos inicializo a OK todos los estados, dato no existente en bda
            step.state = 'OK';
            if (step.isNew == undefined) step.isNew = false;

            if (step.links.length > 0) {
              step.links.forEach(link => {
                link.isFinal = false;
                if (this.steps.find(s => s.stepId == link.id && s.stepTypeId == StepType.End)) link.isFinal = true;
              });
            } else {
              if (step.stepTypeId == StepType.End) continue;

              this.addFinalStepLink(finalStep, step);
            }
          }

          var initStep = this.getInitStep();
          var firstStep = this.steps.find(s => s.parents.includes(0) && s.stepTypeId != StepType.Init);
          if (firstStep != undefined) {
            firstStep.parents.splice(0, 1); //remove parent 0
            this.addInitStepLink(initStep, firstStep);
          }

          this.loading = false;
          console.log('resultados del guardado', this.steps);
        }
      },
      error: (err) => {
        console.log(err);
        this.loading = false;
      },
      complete: () => { //complete
        this.layout = this.layoutModel;
        console.log("Completed");
        this.refreshData();
      }
    });
  }

  public openStepDialogClick(args: IClickEventArgs): void {
    if (args && args.actualObject) {
      let propName = (args.actualObject as MyDiagram).propName;
      let nodeId = (args.actualObject as MyDiagram).id;
      if (propName === 'nodes') {
        var step = this.steps.find(s => 'step' + s.stepId == nodeId);
        if (step.stepTypeId != StepType.Init) this.openEditStepDialog(step, this.steps);
      }
    }
  }

  public checkAllStepsOk(save: boolean): void {
    var result = '{{ }}';

    if (this.steps) {
      var result = "";
      for (let step of this.steps) {
        if (step.stepId == 0) continue;
        if (step.state != "OK") {
          result = "workflow_cant_save_undefined_steps";
          break;
        }
      }
    } else { result = "workflow_cant_save_no_steps"; }

    if (!this.steps.some(s => s.stepTypeId == StepType.End)) result = "workflow_cant_save_no_finish_steps";

    if (result.length == 0 && save) {
      console.log("Emit ok wf");
      this.okWF.emit(result);
    } else {
      this.unfinishWF.emit(result);
    }

  }

  refreshData() {
    this.connectors = [];
    this.nodes = [];

    let firstStep: StepDto = this.steps.find(s => s.stepTypeId == StepType.Init);
    let visualSteps: StepDto[] = this.steps.filter(s => s.stepTypeId != StepType.End);

    var index = visualSteps.indexOf(firstStep);
    visualSteps.splice(index, 1);
    visualSteps.unshift(firstStep);

    // console.log('visualSteps', visualSteps);

    for (let step of visualSteps) {

      let name = step.title ? step.stepId + ". " + step.title : this.translateService.transform("new_step_name");
      var annotationStyle: TextStyleModel = { color: '#888', fill: '#ffffff66', textOverflow: 'Wrap', textWrapping: 'WrapWithOverflow' };
      var tooltip = null;
      var nodeContraint = this.nodeConstraints;

      if (name.length > 25) {
        nodeContraint = (NodeConstraints.Default | NodeConstraints.Tooltip) & ~NodeConstraints.Select;
        annotationStyle = { color: '#888', fill: '#ffffff66', textOverflow: 'Ellipsis', textWrapping: 'NoWrap' };
        tooltip = {
          content: name,
          position: 'BottomCenter', //Sets the position of the tooltip
          relativeMode: 'Object' //Sets the tooltip position relative to the node
        };
      }

      let paso: NodeModel = {
        id: 'step' + step.stepId,
        width: 95,
        height: 80,
        shape: {
          type: 'HTML',
          content: step.stepTypeId == StepType.Init ? '<img src="' + this.stepService.getStepShapeRoute(step.stepTypeId) + '" width="55px" style="margin-left:20px;" class="step-item"/>' : '<img src="' + this.stepService.getStepShapeRoute(step.stepTypeId) + '" width="55px" style="margin-left:20px; cursor:pointer" class="step-item"/>',
        },
        annotations: [
          {
            id: 'nota-paso' + step.stepId,
            content: name,
            style: annotationStyle,
            offset: { x: 0.5, y: 0.72 },
            horizontalAlignment: 'Center',
            verticalAlignment: 'Top',
          },
        ],
        margin: { left: 290, top: 20 },
        constraints: nodeContraint,
        tooltip: tooltip,
      };

      this.nodes.push(paso);

      if (step.links.length != 0) {
        let i = 0;
        for (let child of step.links) {
          if (!visualSteps.some(s => s.stepId == child.id))
            continue;

          let arrow: ConnectorModel;
          let existedArrow = this.connectors.find(con => con.targetID == 'step' + child.id && con.sourceID == 'step' + step.stepId);
          let addArrow = true;

          let connectorColor = '#333';
          if (this.connectorColorMode) {
            let color = this.colors.getRandomColor();
            connectorColor = this.colors.darken(color, 20);
          }

          let arrowDecorator: DecoratorModel = {
            shape: 'Arrow', style: { fill: connectorColor, strokeColor: connectorColor }
          };
          if (existedArrow == undefined) {

            arrow = {
              id: 'arrow' + step.stepId + '-' + i,
              sourceID: 'step' + step.stepId,
              targetID: 'step' + child.id,
              type: 'Orthogonal',
              style: { strokeColor: connectorColor },
              constraints: this.connectorConstraints,
              cornerRadius: 2,
              annotations: [],
              targetDecorator: arrowDecorator
            };
          } else {
            arrow = existedArrow;
            addArrow = false;
          }

          var text = child.text;
          if (step.stepTypeId == StepType.Numeric && text != null) {
            var json = JSON.parse(text);
            text = Object.keys(json).length == 0 ? null : (json.forNumber != null ? json.sinceNumber + json.operation + json.forNumber : json.operation + json.sinceNumber);
          }

          if (text != null) {
            if (arrow.annotations[0] != undefined) {
              arrow.annotations[0].content += "/" + text;
            } else {
              let annotation: PathAnnotationModel = {
                id: 'nota' + step.stepId + '-' + i,
                content: text,
                offset: 0.35,
                width: 50,
                style: { fill: 'white', color: connectorColor, textOverflow: 'Ellipsis', textWrapping: 'NoWrap', fontSize: 10 },
              };

              arrow.annotations.push(annotation);
            }
          }

          i++;
          if (addArrow) this.connectors.push(arrow);
        }
      }
    }

    console.log("nodes:", this.nodes.length);
    this.diagram.nodes = this.nodes;
    console.log("conectors:", this.connectors.length);
    this.diagram.connectors = this.connectors;

    document.getElementById("diagram").hidden = true;
    document.getElementById("toolbar").hidden = true;

    setTimeout(() => {

      if (firstStep != undefined) this.layoutModel.root = 'step' + firstStep.stepId;
      this.layout = this.layoutModel;
      console.time("timeout");
      let doLayoutCompleted = this.diagram.doLayout();
      console.log("Dolayout completed", doLayoutCompleted);
      console.timeEnd("timeout");
      this.diagram.fitToPage();
 
      document.getElementById("diagram").hidden = false;
      document.getElementById("toolbar").hidden = false;
    }, 0);

  }


  getNumberOfUniqueChildren(step: StepDto): number {
    var listIds = step.links.filter(link => !link.isFinal).map(function (e: Link) { return e.id; });
    const uniqueArray = Array.from(new Set(listIds));
    return uniqueArray.length;
  }

  checkShowDeleteButton(step: StepDto): boolean {
    const uniqueArray = this.getNumberOfUniqueChildren(step);
    return (uniqueArray <= 1 || step.stepTypeId == StepType.New);
  }

  openEditStepDialog(step: StepDto, allSteps: StepDto[]): void {
    this.copyAllSteps = cloneDeep(allSteps);

    if (step.links.length > 1 || step.stepTypeId === StepType.New || step.state === 'CHANGE') {

      this.dialogRef = this.dialog.open(StepFormComponent, {
        width: 'auto',
        data: {
          dialogStep: step,
          showDeleteButton: this.checkShowDeleteButton(step),
          reasonNotToDelete: 'delete_multiple',
          allSteps: allSteps,
          workflowDTO: this.workflowDTO
        }
      });
    }
    else {
      if (step.links.length === 0) {
        let parents = this.steps.filter(o => step.parents.find(p => p == o.stepId));

        if (parents.some(p => p.links.length > 1)) {
          this.dialogRef = this.dialog.open(StepFormComponent, {
            width: 'auto',
            data: {
              dialogStep: step,
              showDeleteButton: false,
              allSteps: allSteps,
              reasonNotToDelete: 'delete_answer',
              workflowDTO: this.workflowDTO
            }
          });
        } else {
          this.dialogRef = this.dialog.open(StepFormComponent, {
            width: 'auto',
            data: {
              dialogStep: step,
              showDeleteButton: true,
              allSteps: allSteps,
              workflowDTO: this.workflowDTO
            }
          });
        }
      } else {
        this.dialogRef = this.dialog.open(StepFormComponent, {
          width: 'auto',
          data: {
            dialogStep: step,
            showDeleteButton: true,
            allSteps: allSteps,
            workflowDTO: this.workflowDTO
          }
        });
      }
    }

    let dialogSubmitSubscription;
    this.dialogRef.afterClosed().subscribe(result => {
      this.saveByResult(result, step);
      if (dialogSubmitSubscription != undefined) dialogSubmitSubscription.unsubscribe();
    });

    dialogSubmitSubscription = this.stepService.deleteAnswer.subscribe(data => {
      this.updateStepsToDelete(data);
    });
  }

  saveByResult(result: string, step: StepDto): void {
    var save = true;
    switch (result) {
      case 'saveAndEnd': {
        this.newAnswerStep(step);
        this.stepService.modifyStep(step);
        break;
      }
      case 'saveAndPrev': {
        console.log("save and PREV");
        this.newAnswerStep(step);
        var initStep = this.getInitStep();

        let fatherStep: StepDto = this.newEmptyStep();
        let stepLink: Link = new Link(step.stepId);
        fatherStep.links.push(stepLink);
        fatherStep.parents.push(initStep.stepId);

        var index = step.parents.indexOf(initStep.stepId);
        step.parents.splice(index, 1);
        step.parents.push(fatherStep.stepId);

        this.steps.push(fatherStep);
        this.stepService.modifyStep(step);

        initStep.links.splice(0, 1);
        initStep.links.push(new Link(fatherStep.stepId));
        this.stepService.modifyStep(initStep);

        break;
      }
      case 'saveAndNew': {
        console.log("save and NEW");
        let childStep: StepDto = this.newEmptyStep();
        let childLink: Link = new Link(childStep.stepId);
        step.links.push(childLink);
        childStep.parents.push(step.stepId);
        this.steps.push(childStep);
        this.stepService.modifyStep(step);
        break;
      }
      case 'saveAndBetween': {
        console.log("save and BETWEEN");
        let newchildStep: StepDto = this.newEmptyStep();
        this.stepService.changeFatherIdOfAllStepChildren(this.steps, step, newchildStep.id);
        let childLink: Link = new Link(step.links[0].id);
        let childLinkParent: Link = new Link(newchildStep.stepId, step.links[0].text);
        childLink.isFinal = step.links[0].isFinal;
        step.links = [];
        step.links.push(childLinkParent);
        newchildStep.links.push(childLink);
        newchildStep.parents.push(step.stepId);
        this.steps.push(newchildStep);
        this.stepService.modifyStep(step);
        break;
      }
      case 'delete': {
        if (step.stepTypeId == StepType.New && (step.links.length > 1 || step.parents.length > 0)) {
          console.log("undo changes from Delete step");
          this.undoChanges(step);
        }
        console.log("Delete step");
        this.questionDeleteStep(step);
        break;
      }
      default: {
        console.log('ha salido de otra forma');
        this.undoChanges(step);
        save = false;
        break;
      }
    }

    if (this.stepsToDelete.length > 0) {
      console.log("steps to delete");
      this.stepsToDelete.forEach(s => this.questionDeleteAnswer(s));
      this.stepsToDelete = [];
    }

    if (result != "delete") {
      this.refreshData();
      this.checkAllStepsOk(save);

    }

    console.log('resultados del guardado', this.steps);
  }


  undoChanges(step: StepDto) {
    this.steps = [];
    this.copyAllSteps.forEach(oldStepValue => {
      this.steps.push(oldStepValue);

      if (step.id == oldStepValue.id) {

        if (this.stepService.workflowDTO.stepItemsToEdit.find(s => s.id == oldStepValue.id)) {
          this.stepService.editStep(oldStepValue);
        }

        //undo links too
        var diffLinks = oldStepValue.links.filter(link => step.links.findIndex(l => l.id == link.id) == -1);
        step.links.filter(link => oldStepValue.links.findIndex(l => l.id == link.id) == -1).forEach(step => diffLinks.push(step));

        diffLinks.forEach(link => {
          var linkStep = this.copyAllSteps.find(s => s.stepId == link.id);

          if (linkStep != undefined && this.stepService.workflowDTO.stepItemsToEdit.find(s => s.stepId == linkStep.stepId)) {
            this.stepService.editStep(linkStep);
          }
        });

      }
    });

    this.stepsToDelete = [];
  }

  newWorkflow() {
    console.log('start new workflow');

    let firstStep = this.newEmptyStep();
    this.steps.push(firstStep);

    var initStep = this.getInitStep();
    this.addInitStepLink(initStep, firstStep);

    var finalStep = this.getFinalStep();
    var index = finalStep.parents.findIndex(p => p == initStep.stepId);
    if (index > -1) finalStep.parents.splice(index, 1);
    this.addFinalStepLink(finalStep, firstStep);
    this.refreshData();
  }

  newEmptyStep(): StepDto {
    let newEmptyStep = new StepDto();
    newEmptyStep.id = this.newsItemsId;
    newEmptyStep.stepId = this.newsItemsId;
    var title = this.translateService.transform("new_step_name");

    var nameId = 1;
    var newStepsArray = this.steps.map(s => s.title.includes(title) && parseInt(s.title.substring(title.length)));
    if (newStepsArray.length > 0) nameId = Math.max(...newStepsArray) + 1;

    newEmptyStep.title = title + " " + nameId;

    this.newsItemsId++;
    return newEmptyStep;
  }

  newAnswerStep(step: StepDto): void {
    var hasFinalChildren = false;

    for (let child of step.links) {
      if (child.id < 0) {
        let childStep: StepDto;
        var finalStep = this.getFinalStep();

        if (child.isFinal) {
          childStep = finalStep;
          hasFinalChildren = true;
        } else {
          childStep = this.newEmptyStep();
          childStep.stepId = childStep.id;
          childStep.title = child.stepTitle != '' ? child.stepTitle : this.translateService.transform("new_step_name");

          this.addFinalStepLink(finalStep, childStep);
          this.steps.push(childStep);
        }

        childStep.parents.push(step.stepId);
        var sameChild = step.links.filter(l => l.id == child.id);
        if (sameChild.length > 0) sameChild.forEach(c => c.id = childStep.id);

      } else {
        var existingChildStep = this.steps.find(s => s.stepId == child.id);
        if (existingChildStep.stepTypeId == StepType.End) hasFinalChildren = true;

        if (existingChildStep.parents.filter(p => p == step.stepId).length == step.links.filter(l => l.id == existingChildStep.stepId).length) continue;
        existingChildStep.parents.push(step.stepId);
        this.stepService.modifyStep(existingChildStep);
      }
    }


    if (!hasFinalChildren) {
      var finalStep = this.steps.find(s => s.stepTypeId == StepType.End);
      if (finalStep == undefined) return;

      var index = finalStep.parents.findIndex(p => p == step.stepId);
      if (index > -1) {
        finalStep.parents.splice(index, 1);
        this.stepService.modifyStep(finalStep);
      }
    }
  }

  questionDeleteStep(step: StepDto): void {
    if (this.auth.isAuthenticated()) {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: 'auto',
        data: {
          type: 'QUESTION',
          title: 'AreYouSure',
          message: 'dialog_delete_step_header',
          buttons: 'YESNO'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.changeLinksOnDeleteStep(step);
          this.deleteStep(step);
          if (this.steps.length === 2 && this.steps.find(s => s.stepTypeId == StepType.Init) != undefined && this.steps.find(s => s.stepTypeId == StepType.End) != undefined) { this.newWorkflow(); } //para que nunca se quede sin un nodo desde el que poder trabajar
          else {
            this.refreshData();
          }
          this.checkAllStepsOk(true);
        }
      });
    }
  }

  updateStepsToDelete(stepsToDelete: StepDto[]): void {
    this.stepsToDelete = stepsToDelete;
    var stepToCheck = this.dialogRef.componentInstance.data.dialogStep;

    this.dialogRef.componentInstance.data.showDeleteButton = this.checkShowDeleteButton(stepToCheck);
  }

  questionDeleteAnswer(stepToDelete: StepDto): void {
    this.stepService.parentsChecked = new Map();
    this.stepsChecked = [];

    if (!this.stepService.isOrphan(stepToDelete, this.steps)) return;

    const parents = this.steps.filter(o => stepToDelete.parents.find(p => p == o.stepId));
    if (parents.length == 0) this.deleteStepAndChildren(stepToDelete, null);

    parents.forEach((parent, index) => {
      const childPos = parent.links.findIndex(l => l.id == stepToDelete.stepId);
      if (childPos > -1) {
        parent.links.splice(childPos, 1);
      }

      this.deleteStepAndChildren(stepToDelete, parent);
    });
  }

  deleteStep(step: StepDto): void {
    if (!step.isNew) { // el paso ya existía
      const posToEdit = this.workflowDTO.stepItemsToEdit.map(function (e: StepDto) { return e.id; }).indexOf(step.id);
      if (posToEdit > -1) {
        this.workflowDTO.stepItemsToEdit.splice(posToEdit, 1);
      }
      const AlrreadyAddToDelete = this.workflowDTO.stepItemsToDelete.includes(step.id);
      if (!AlrreadyAddToDelete) {
        this.workflowDTO.stepItemsToDelete.push(step.id);
      }
    } else { //es un paso nuevo
      const posToAdd = this.workflowDTO.stepItemsToAdd.map(function (e: StepDto) { return e.id; }).indexOf(step.id);
      if (posToAdd > -1) {
        this.workflowDTO.stepItemsToAdd.splice(posToAdd, 1);
      }
    }
    const pos = this.steps.map(function (e: StepDto) { return e.id; }).indexOf(step.id);
    if (pos > -1) {
      this.steps.splice(pos, 1);
    }

    console.log('listas tras borrado', this.workflowDTO);
  }

  changeLinksOnDeleteStep(deletedStep: StepDto) {
    let linkFromDeletedStep: Link = null;
    // Sólo se pueden borrar pasos que tengan un sólo hijo, pero puede tener hijos finales
    var linksList = deletedStep.links.filter(l => l.id != deletedStep.stepId); //do not include own loops
    if (linksList.length > 0) {
      console.log("LINKS", linksList.length);
      linksList.forEach(link => {
        if (link.isFinal) {
          let endStep = this.steps.find(s => s.stepId == link.id);
          var index = endStep.parents.findIndex(p => p == deletedStep.stepId);
          endStep.parents.splice(index, 1);
          this.stepService.modifyStep(endStep);
        } else {
          linkFromDeletedStep = link;
        }
      });
    }

    console.log("Change links INI");

    //buscamos al padre (puede no tener si es el primer nodo)
    if (!deletedStep.parents.includes(0)) {
      const parentsFromDeletedStep = this.steps.filter(s => deletedStep.parents.find(p => p == s.stepId && p != deletedStep.stepId));  //do not include own loops
      if (parentsFromDeletedStep == undefined) return;

      parentsFromDeletedStep.forEach((parent, index) => {
        parent.links.forEach((link, stepPos) => {
          if (link.id != deletedStep.stepId) return;

          if (linkFromDeletedStep != null) {

            link.id = linkFromDeletedStep.id;
            link.isFinal = linkFromDeletedStep.isFinal;
            this.stepService.modifyStep(parent);

            //Encontrar el nodo hijo y cambiarle el padre al del paso a borrar
            this.stepService.changeFatherIdOfAllStepChildren(this.steps, deletedStep, parent.stepId);

          } else { // si no tenía hijos se elimina el link

            var finalStep = this.getFinalStep();

            link.id = finalStep.stepId;
            link.isFinal = true;
            if (link.id == -1) this.newAnswerStep(parent);
            else finalStep.parents.push(parent.stepId);

            this.stepService.modifyStep(parent);
          }
        });
      });


    } else { //No tiene padre, le borramos el padre al hijo si tiene hijos
      if (linkFromDeletedStep != null) {
        const nextStep = this.steps.find(s => s.stepId == linkFromDeletedStep.id);
        if (nextStep != undefined) {
          var index = nextStep.parents.indexOf(deletedStep.stepId);
          nextStep.parents.splice(index, 1);
          nextStep.parents.push(0);
        }
      }
    }

    console.log("Change links FIN");
  }

  deleteStepAndChildren(step: StepDto, parent: StepDto): void {
    var index = -1;

    //delete links if the step has more than one parent
    if (parent == null) {
      var parents = step.parents.filter(p => p != step.stepId).length;
    } else {
      var parents = step.parents.filter(p => p != step.stepId && p != parent.stepId).length;
    }

    if (parents >= 1 && parent != null) {
      index = step.parents.indexOf(parent.stepId);
      var isOrphan = this.stepService.isOrphan(step, this.steps);
      if (!isOrphan) {
        if (index > -1) {
          step.parents.splice(index, 1);
          if (step.stepTypeId == StepType.End) this.stepService.modifyStep(step);
        }
        return;
      }
    }

    //delete loops
    var loops = step.parents.filter(p => p == step.stepId).length;
    if (loops > 0) {
      for (var i = 0; i < loops; i++) {
        index = step.parents.indexOf(step.stepId);
        if (index > -1) step.parents.splice(index, 1);

        index = step.links.findIndex(s => s.id == step.stepId);
        if (index > -1) step.links.splice(index, 1);
      }
    }

    //delete children
    for (let link of step.links) {

      if (this.stepsChecked.includes(link.id)) {
        continue;
      }

      let childToDelete = this.steps.find(s => s.stepId == link.id);
      if (childToDelete != undefined) {
        this.stepsChecked.push(step.stepId);
        this.deleteStepAndChildren(childToDelete, step);
      }
    }

    console.log("deleteStepAndChildren", step);
    this.deleteStep(step);
  }

  public zoomEvent(args: ClickEventArgs): void {
    switch (args.item.id) {
      case 'zoomControlIn':
        let zoomin: ZoomOptions = { type: 'ZoomIn', zoomFactor: 0.2 };
        this.diagram.zoomTo(zoomin);
        this.diagram.dataBind();
        break;
      case 'zoomControlOut':
        let zoomout: ZoomOptions = { type: 'ZoomOut', zoomFactor: 0.2 };
        this.diagram.zoomTo(zoomout);
        this.diagram.dataBind();
        break;
      case 'switchOrientationControl':
        this.switchOrientation();
        break;
      case 'switchConnectorColorMode':
        this.switchConnectorColorMode();
        break;
      case 'zoomControlReset':
        this.diagram.reset();
        this.diagram.fitToPage();
        break;
    }
  }

  deleteLinksByStepId(stepId: number) {
    this.steps.forEach(step => {
      step.links = step.links.filter(function (obj) {
        return obj.id != stepId;
      });
    });
  }

  getFinalStep(): StepDto {
    var finalStep = this.steps.find(s => s.stepTypeId == StepType.End);
    if (finalStep == undefined) {
      finalStep = this.newEmptyStep();
      finalStep.title = this.translateService.transform("end_step_name");
      finalStep.stepTypeId = StepType.End;
      finalStep.isNew = true;
      finalStep.state = "OK";
      this.steps.push(finalStep);
      this.stepService.modifyStep(finalStep);
    }
    return finalStep;
  }

  addFinalStepLink(finalStep: StepDto, step: StepDto) {
    var link = new Link(finalStep.stepId);
    link.isFinal = true;
    step.links.push(link);
    this.stepService.modifyStep(step);

    finalStep.parents.push(step.stepId);
    this.stepService.modifyStep(finalStep);
  }

  getInitStep(): StepDto {
    var initStep = this.steps.find(s => s.stepTypeId == StepType.Init);
    if (initStep == undefined) {
      initStep = this.newEmptyStep();
      initStep.title = this.translateService.transform("start_step_name");
      initStep.state = "OK";
      initStep.stepTypeId = StepType.Init;
      this.steps.push(initStep);
      this.stepService.modifyStep(initStep);
    }
    return initStep;
  }

  addInitStepLink(initStep: StepDto, step: StepDto) {
    var link = new Link(step.stepId);
    initStep.links = [];
    initStep.links.push(link);
    this.stepService.modifyStep(initStep);

    step.parents.push(initStep.stepId);
    this.stepService.modifyStep(step);
  }

  switchOrientation() {
    if (this.diagram.layout.orientation == 'TopToBottom') {
      this.diagram.layout.orientation = 'LeftToRight';

    } else {
      this.diagram.layout.orientation = 'TopToBottom';
    }
    /* console.log("orientation ha cambiado a: ", this.diagram.layout.orientation);*/
    this.diagram.doLayout();
    this.diagram.reset();
    this.diagram.fitToPage();
  }

  switchConnectorColorMode() {
    this.connectorColorMode = !this.connectorColorMode;
    this.refreshData();
  }
}

interface MyDiagram extends Diagram {
  id: string;
  propName: string;
}
export interface DataInfo {
  [key: string]: string;
}
