import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashBoardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';

import { FormWorkFlowComponent } from './components/workflow/form-workflow/form-workflow.component'
import { StepFormComponent } from './components/step/step-form/step-form.component'

import { ListWorkFlowReportComponent } from './components/workflowreport/list.workflowreport.component';
import { CreatorGuardService } from './services/auth-guard-creator.service';
import { ReviewerGuardService } from './services/auth-guard-reviewer.service';
import { GroupsComponent } from './components/groups/groups.component';
import { ReportHTMLComponent } from './components/workflowreport/report-html/report-html.component';
import { ManagerCreatorGuardService } from './services/auth-guard-manager-creator.service';
import { ManagerGuardService } from './services/auth-guard-manager.service';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'workflowreport', component: ListWorkFlowReportComponent, canActivate: [ReviewerGuardService] },
  { path: 'workflowreport/report/:id', component: ReportHTMLComponent, canActivate: [ReviewerGuardService] },
  { path: 'dashboard', component: DashBoardComponent, canActivate: [ManagerCreatorGuardService] },
  { path: 'dashboard/workflow', component: FormWorkFlowComponent, canActivate: [CreatorGuardService] },
  { path: 'dashboard/workflow/:id', component: FormWorkFlowComponent, canActivate: [CreatorGuardService] },
  { path: 'dashboard/workflow/:id/step', component: StepFormComponent, canActivate: [CreatorGuardService] },
  { path: 'dashboard/workflow/:id/step/:sid', component: StepFormComponent, canActivate: [CreatorGuardService] },
  { path: 'groups', component: GroupsComponent, canActivate: [ManagerGuardService] },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  // , { relativeLinkResolution: 'corrected' }
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {

  constructor() {

  }

}
